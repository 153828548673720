/* eslint-disable no-self-assign */
/* eslint-disable no-prototype-builtins */
import { AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { LoggerService } from 'src/app/services/logger.service';
import { e_event_emitter_enum } from 'src/app/_models/enum';
import { DataExchangeService } from 'src/app/_services/data-exchange.service';
import { abstimmErgebnisse_vorlage, GlobalsService, stimmgeber_key } from 'src/app/_services/globals.service';
import {EventEmitterService} from '../../../services/event-emitter.service';

@Component({
  selector: 'app-doppelte-qualifizierung',
  templateUrl: './doppelte-qualifizierung.component.html',
  styleUrls: ['./doppelte-qualifizierung.component.scss']
})

export class DoppelteQualifizierungComponent implements OnInit , OnDestroy , OnChanges , AfterViewInit{
  @Input() selectedVersammlung: any = { text: ''};
  @Input() selectedTagesordnungspunkt: any = {abstimmung_erforderlich: 0, qualifizierungstyp: {stimmgeber: {s1:{key: "", schwellwert : ""}}}};
  @Input() displayOptions : any = {
    fertigAbgestimmt: true,
    anwesendNichtAbgestimmtZuEnthaltung: false
  }
  @Input()  abstimmErgebnisse : any = abstimmErgebnisse_vorlage;
  @Input()
  set changes(abstimmErgebnisse_ : any) {
      this.abstimmErgebnisse = abstimmErgebnisse_;
      this.parseData();
  }

  private abstimmErgebnisseSrc : BehaviorSubject<any>;
  public currentAbstimmErgebnisse : Observable<any>;
  public abstimmErgebnisse_s1 : any = abstimmErgebnisse_vorlage;
  public abstimmErgebnisse_s2 : any = abstimmErgebnisse_vorlage;
  public eventEmitterSubscription : Subscription;


  constructor(
    public cdr : ChangeDetectorRef,
    public logger: LoggerService,
    private dex: DataExchangeService,
    public globals: GlobalsService,
    private eventEmitterService: EventEmitterService
  )
  {
    // this.displayOptions.disableUnneccesary = true;
    this.abstimmErgebnisseSrc = new BehaviorSubject<any>(this.abstimmErgebnisse);
    this.currentAbstimmErgebnisse = this.abstimmErgebnisseSrc.asObservable();
  }

  ngOnInit(): void {
    // this.parseData();
    this.initListener();
  }

  ngAfterViewInit(): void {
    // this.parseData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log("on change ",changes)
    // this.parseData();
  }
  ngOnDestroy(){
    this.eventEmitterSubscription.unsubscribe();
  }

  initListener()
  {
    this.currentAbstimmErgebnisse.subscribe((abstimmErgebnisse__ : any) => {

      this.parseData();
    });
    // Event Emitter
    // For Anmeldungen über Barcode-Scanner
    this.eventEmitterSubscription = this.eventEmitterService.eventEmitter.subscribe(msg => {
    try
    {
      if(msg.hasOwnProperty("cmd"))
      {
        const cmd = msg.cmd;
        if(cmd === e_event_emitter_enum.getErgebnissComponentData)
        {
          // console.log(`>>> EM ErgebnissComponentData ! ${msg.data?.submitted?.data?.notice} `,msg)
          if(msg.data.response.abstimmErgebnisse)
          {
            // this.abstimmErgebnisse = msg.data.response.abstimmErgebnisse;
            this.abstimmErgebnisse = Object.assign(this.abstimmErgebnisse, msg.data.response.abstimmErgebnisse);
            if(msg.data.response.abstimmErgebnisse.hasOwnProperty("verkuendungsErgebnis"))
            {
              this.selectedTagesordnungspunkt.verkuendungsErgebnis = msg.data.response.abstimmErgebnisse.verkuendungsErgebnis;
            }
            this.parseData();
            // this.cdr.markForCheck();
            // this.cdr.detectChanges();
          }
        }
      }
    }catch(e){this.logger.error(e);}
  });
  }

  saveAbstimmErgebnisse(abstimmErgebnisse: any = {} )
  {
    this.abstimmErgebnisseSrc.next(abstimmErgebnisse);
  }

  parseData()
  {
    try
    {
      // this.abstimmErgebnisse_s1 = this.abstimmErgebnisse;
      // this.abstimmErgebnisse_s2 = this.abstimmErgebnisse;
      // console.log("hier1.5 ",this.selectedTagesordnungspunkt, " abstimmErgebnisse_s2 ", this.abstimmErgebnisse_s2)
      // this.abstimmErgebnisse_s1 = Object.create(this.abstimmErgebnisse );
      // this.abstimmErgebnisse_s2 = Object.create(this.abstimmErgebnisse );

      // Für Ergebnis und Verkündungsansicht
      // if(this.abstimmErgebnisse.enthaltung && this.displayOptions.anwesendNichtAbgestimmtZuEnthaltung == true)
      // {
      //   this.abstimmErgebnisse.enthaltung.kopf_stimmen = this.abstimmErgebnisse.enthaltung.kopf_stimmen + this.abstimmErgebnisse.anwesend.kopf_stimmen
      //   this.abstimmErgebnisse.enthaltung.mea = this.abstimmErgebnisse.enthaltung.mea + this.abstimmErgebnisse.anwesend.mea
      //   this.abstimmErgebnisse.enthaltung.einheiten = this.abstimmErgebnisse.enthaltung.einheiten + this.abstimmErgebnisse.anwesend.einheiten
      // }

      this.abstimmErgebnisse = JSON.parse(JSON.stringify(this.abstimmErgebnisse));
      this.abstimmErgebnisse_s1 = JSON.parse(JSON.stringify(this.abstimmErgebnisse));
      this.abstimmErgebnisse_s2 = JSON.parse(JSON.stringify(this.abstimmErgebnisse));


      if( this.selectedTagesordnungspunkt.hasOwnProperty("qualifizierungstyp") &&
          this.selectedTagesordnungspunkt.qualifizierungstyp.hasOwnProperty("stimmgeber") &&
          this.selectedTagesordnungspunkt.qualifizierungstyp.stimmgeber.hasOwnProperty("s1") &&
          this.selectedTagesordnungspunkt.qualifizierungstyp.stimmgeber.hasOwnProperty("s2")
      )
      {

        let s1_stimmgeber_bezeichnung = "";
        let s2_stimmgeber_bezeichnung = "";
        const s1_key = this.selectedTagesordnungspunkt.qualifizierungstyp.stimmgeber.s1.key;
        const s2_key = this.selectedTagesordnungspunkt.qualifizierungstyp.stimmgeber.s2.key;
        const relation_s1 = this.selectedTagesordnungspunkt.qualifizierungstyp.stimmgeber.s1.relation;
        const relation_s2 = this.selectedTagesordnungspunkt.qualifizierungstyp.stimmgeber.s2.relation;
        const s1_teilnehmer_haben_abgestimmt_ = relation_s1 === 0? this.abstimmErgebnisse.teilnehmer_haben_abgestimmt : this.abstimmErgebnisse.teilnehmer_haben_abgestimmt2;
        const s1_mea_abgestimmt_ = relation_s1 === 0 ? this.abstimmErgebnisse.meaAbgestimmt : this.abstimmErgebnisse.meaAbgestimmt2;
        const s1_einheiten_abgestimmt_ = relation_s1 === 0 ? this.abstimmErgebnisse.abgestimmt_einheiten : this.abstimmErgebnisse.abgestimmt_einheiten2;
        const s2_teilnehmer_haben_abgestimmt_ = relation_s2 === 0? this.abstimmErgebnisse.teilnehmer_haben_abgestimmt : this.abstimmErgebnisse.teilnehmer_haben_abgestimmt2;
        const s2_mea_abgestimmt_ = relation_s2 === 0 ? this.abstimmErgebnisse.meaAbgestimmt : this.abstimmErgebnisse.meaAbgestimmt2;
        const s2_einheiten_abgestimmt_ = relation_s2 === 0 ? this.abstimmErgebnisse.abgestimmt_einheiten : this.abstimmErgebnisse.abgestimmt_einheiten2;

        switch(s1_key)
        {
          case stimmgeber_key.stimmgeber_tn:       
            s1_stimmgeber_bezeichnung = "Köpfe";     
            this.abstimmErgebnisse_s1.ja.mea = this.abstimmErgebnisse.ja.kopf_stimmen;
            this.abstimmErgebnisse_s1.meaStimmenGesamt = this.abstimmErgebnisse.berechtigte_teilnehmer;
            this.abstimmErgebnisse_s1.ja.geteilt = this.abstimmErgebnisse.ja.kopf_stimmen > 0 ? this.globals.germanDecimal2((this.abstimmErgebnisse.ja.kopf_stimmen / this.abstimmErgebnisse.berechtigte_teilnehmer) / 0.01) +"%" : 0+"%";
            this.abstimmErgebnisse_s1.nein.mea = this.abstimmErgebnisse.nein.kopf_stimmen;
            this.abstimmErgebnisse_s1.nein.geteilt = this.abstimmErgebnisse.nein.kopf_stimmen > 0 ? this.globals.germanDecimal2((this.abstimmErgebnisse.nein.kopf_stimmen / this.abstimmErgebnisse.berechtigte_teilnehmer) / 0.01) +"%" : 0+"%";
            this.abstimmErgebnisse_s1.enthaltung.mea = this.abstimmErgebnisse.enthaltung.kopf_stimmen;
            this.abstimmErgebnisse_s1.enthaltung.geteilt = this.abstimmErgebnisse.enthaltung.kopf_stimmen > 0 ? this.globals.germanDecimal2((this.abstimmErgebnisse.enthaltung.kopf_stimmen / this.abstimmErgebnisse.berechtigte_teilnehmer) / 0.01) +"%" : 0+"%";
            this.abstimmErgebnisse_s1.anwesend.mea = this.abstimmErgebnisse.anwesend.kopf_stimmen;
            this.abstimmErgebnisse_s1.anwesend.geteilt = this.abstimmErgebnisse.anwesend.kopf_stimmen > 0 ? this.globals.germanDecimal2((this.abstimmErgebnisse.anwesend.kopf_stimmen / this.abstimmErgebnisse.berechtigte_teilnehmer) / 0.01) +"%" : 0+"%";
            this.abstimmErgebnisse_s1.abwesend.mea = this.abstimmErgebnisse.abwesend.kopf_stimmen;
            this.abstimmErgebnisse_s1.abwesend.geteilt = this.abstimmErgebnisse.abwesend.kopf_stimmen > 0 ? this.globals.germanDecimal2((this.abstimmErgebnisse.abwesend.kopf_stimmen / this.abstimmErgebnisse.berechtigte_teilnehmer) / 0.01) +"%" : 0+"%";
            this.abstimmErgebnisse_s1.legend_top = `Köpfe abgestimmt <b>${this.abstimmErgebnisse.teilnehmer_haben_abgestimmt2} / ${this.abstimmErgebnisse.berechtigte_teilnehmer}</b>`;
            this.abstimmErgebnisse_s1.legend_bottom = `Offene Abstimmungen: <b>${this.abstimmErgebnisse.offeneAbstimmungen.kopf_stimmen} ${s1_stimmgeber_bezeichnung}.</b> Enthaltungen: ${this.abstimmErgebnisse.enthaltung.kopf_stimmen} ${s1_stimmgeber_bezeichnung}`;
            if(relation_s1 === 0) // nur Abstimmungen
            {
              this.abstimmErgebnisse_s1.ja.geteilt = this.abstimmErgebnisse.ja.kopf_stimmen > 0 ? this.globals.germanDecimal2((this.abstimmErgebnisse.ja.kopf_stimmen / s1_teilnehmer_haben_abgestimmt_) / 0.01) +"%" : 0+"%";
              this.abstimmErgebnisse_s1.nein.geteilt = this.abstimmErgebnisse.nein.kopf_stimmen > 0 ? this.globals.germanDecimal2((this.abstimmErgebnisse.nein.kopf_stimmen / s1_teilnehmer_haben_abgestimmt_) / 0.01) +"%" : 0+"%";
              this.abstimmErgebnisse_s1.enthaltung.geteilt = this.abstimmErgebnisse.enthaltung.kopf_stimmen > 0 ? this.globals.germanDecimal2((this.abstimmErgebnisse.enthaltung.kopf_stimmen / s1_teilnehmer_haben_abgestimmt_) / 0.01) +"%" : 0+"%";
              this.abstimmErgebnisse_s1.anwesend.geteilt = this.abstimmErgebnisse.anwesend.kopf_stimmen > 0 ? this.globals.germanDecimal2((this.abstimmErgebnisse.anwesend.kopf_stimmen / s1_teilnehmer_haben_abgestimmt_) / 0.01) +"%" : 0+"%";
              this.abstimmErgebnisse_s1.abwesend.geteilt = this.abstimmErgebnisse.abwesend.kopf_stimmen > 0 ? this.globals.germanDecimal2((this.abstimmErgebnisse.abwesend.kopf_stimmen / s1_teilnehmer_haben_abgestimmt_) / 0.01) +"%" : 0+"%";
              this.abstimmErgebnisse_s1.meaStimmenGesamt = s1_teilnehmer_haben_abgestimmt_;
            }            
          break;

          case stimmgeber_key.stimmgeber_mea:
            s1_stimmgeber_bezeichnung = "MEA";   
            this.abstimmErgebnisse_s1.ja.mea = this.abstimmErgebnisse.ja.mea;
            this.abstimmErgebnisse_s1.meaStimmenGesamt = this.abstimmErgebnisse.meaStimmenGesamt;
            this.abstimmErgebnisse_s1.ja.geteilt = this.abstimmErgebnisse.ja.kopf_stimmen > 0 ? this.globals.germanDecimal2(( this.abstimmErgebnisse.ja.mea / this.abstimmErgebnisse.meaStimmenGesamt) / 0.01) +"%" : 0+"%";
            this.abstimmErgebnisse_s1.nein.mea = this.abstimmErgebnisse.nein.mea;
            this.abstimmErgebnisse_s1.nein.geteilt = this.abstimmErgebnisse.nein.mea > 0 ? this.globals.germanDecimal2(( this.abstimmErgebnisse.nein.mea / this.abstimmErgebnisse.meaStimmenGesamt) / 0.01) +"%" : 0+"%";
            this.abstimmErgebnisse_s1.enthaltung.mea = this.abstimmErgebnisse.enthaltung.mea;
            this.abstimmErgebnisse_s1.enthaltung.geteilt = this.abstimmErgebnisse.enthaltung.mea > 0 ? this.globals.germanDecimal2((this.abstimmErgebnisse.enthaltung.mea / this.abstimmErgebnisse.meaStimmenGesamt) / 0.01) +"%" : 0+"%";
            this.abstimmErgebnisse_s1.anwesend.mea = this.abstimmErgebnisse.anwesend.mea;
            this.abstimmErgebnisse_s1.anwesend.geteilt = this.abstimmErgebnisse.anwesend.mea > 0 ? this.globals.germanDecimal2(( this.abstimmErgebnisse.anwesend.mea / this.abstimmErgebnisse.meaStimmenGesamt) / 0.01) +"%" : 0+"%";
            this.abstimmErgebnisse_s1.abwesend.mea = this.abstimmErgebnisse.abwesend.mea;
            this.abstimmErgebnisse_s1.abwesend.geteilt = this.abstimmErgebnisse.abwesend.mea > 0 ? this.globals.germanDecimal2(( this.abstimmErgebnisse.abwesend.mea / this.abstimmErgebnisse.meaStimmenGesamt) / 0.01) +"%" : 0+"%";
            this.abstimmErgebnisse_s1.legend_top = `MEA abgestimmt <b>${this.globals.germanDecimal2(this.abstimmErgebnisse.meaAbgestimmt2)} / ${this.globals.germanDecimal2(this.abstimmErgebnisse.meaStimmenGesamt)}</b>`;
            this.abstimmErgebnisse_s1.legend_bottom = `Offene Abstimmungen: <b>${this.globals.germanDecimal2(this.abstimmErgebnisse.offeneAbstimmungen.mea)} ${s1_stimmgeber_bezeichnung}.</b> Enthaltungen: ${this.globals.germanDecimal2(this.abstimmErgebnisse.enthaltung.mea)} ${s1_stimmgeber_bezeichnung}`;
            if(relation_s1 === 0)
            {
              this.abstimmErgebnisse_s1.ja.geteilt = this.abstimmErgebnisse.ja.mea > 0 ? this.globals.germanDecimal2(( this.abstimmErgebnisse.ja.mea / s1_mea_abgestimmt_) / 0.01) +"%" : 0+"%";
              this.abstimmErgebnisse_s1.nein.geteilt = this.abstimmErgebnisse.nein.mea > 0 ? this.globals.germanDecimal2(( this.abstimmErgebnisse.nein.mea / s1_mea_abgestimmt_) / 0.01) +"%" : 0+"%";
              this.abstimmErgebnisse_s1.enthaltung.geteilt = this.abstimmErgebnisse.enthaltung.mea > 0 ? this.globals.germanDecimal2((this.abstimmErgebnisse.enthaltung.mea / s1_mea_abgestimmt_) / 0.01) +"%" : 0+"%";
              this.abstimmErgebnisse_s1.anwesend.geteilt = this.abstimmErgebnisse.anwesend.mea > 0 ? this.globals.germanDecimal2(( this.abstimmErgebnisse.anwesend.mea / s1_mea_abgestimmt_) / 0.01) +"%" : 0+"%";
              this.abstimmErgebnisse_s1.abwesend.geteilt = this.abstimmErgebnisse.abwesend.mea > 0 ? this.globals.germanDecimal2(( this.abstimmErgebnisse.abwesend.mea / s1_mea_abgestimmt_) / 0.01) +"%" : 0+"%";
              this.abstimmErgebnisse_s1.meaStimmenGesamt = s1_mea_abgestimmt_;
            }            
          break;

          case stimmgeber_key.stimmgeber_eh:
            s1_stimmgeber_bezeichnung = this.abstimmErgebnisse.enthaltung.einheiten == 1 ? "Einheit" : "Einheiten";   
            this.abstimmErgebnisse_s1.ja.mea = this.abstimmErgebnisse.ja.einheiten;
            this.abstimmErgebnisse_s1.meaStimmenGesamt = this.abstimmErgebnisse.einheitenGesamt;
            this.abstimmErgebnisse_s1.ja.geteilt = this.abstimmErgebnisse.ja.einheiten > 0 ? this.globals.germanDecimal2(( this.abstimmErgebnisse.ja.einheiten / this.abstimmErgebnisse.einheitenGesamt) / 0.01) +"%" : 0+"%";
            this.abstimmErgebnisse_s1.nein.mea = this.abstimmErgebnisse.nein.einheiten;
            this.abstimmErgebnisse_s1.nein.geteilt = this.abstimmErgebnisse.nein.einheiten > 0 ? this.globals.germanDecimal2(( this.abstimmErgebnisse.nein.einheiten / this.abstimmErgebnisse.einheitenGesamt) / 0.01) +"%" : 0+"%";
            this.abstimmErgebnisse_s1.enthaltung.mea = this.abstimmErgebnisse.enthaltung.einheiten;
            this.abstimmErgebnisse_s1.enthaltung.geteilt = this.abstimmErgebnisse.enthaltung.einheiten > 0 ? this.globals.germanDecimal2(( this.abstimmErgebnisse.enthaltung.einheiten / this.abstimmErgebnisse.einheitenGesamt) / 0.01) +"%" : 0+"%";
            this.abstimmErgebnisse_s1.anwesend.mea = this.abstimmErgebnisse.anwesend.einheiten;
            this.abstimmErgebnisse_s1.anwesend.geteilt = this.abstimmErgebnisse.anwesend.einheiten > 0 ? this.globals.germanDecimal2(( this.abstimmErgebnisse.anwesend.einheiten / this.abstimmErgebnisse.einheitenGesamt) / 0.01) +"%" : 0+"%";
            this.abstimmErgebnisse_s1.abwesend.mea = this.abstimmErgebnisse.abwesend.einheiten;
            this.abstimmErgebnisse_s1.abwesend.geteilt = this.abstimmErgebnisse.abwesend.einheiten > 0 ? this.globals.germanDecimal2(( this.abstimmErgebnisse.abwesend.einheiten / this.abstimmErgebnisse.einheitenGesamt) / 0.01) +"%" : 0+"%";
            this.abstimmErgebnisse_s1.legend_top = `Einheiten abgestimmt <b> ${this.abstimmErgebnisse.abgestimmt_einheiten2} / ${this.abstimmErgebnisse.einheitenGesamt}</b>`;
            this.abstimmErgebnisse_s1.legend_bottom = `Offene Abstimmungen: <b>${this.abstimmErgebnisse.offeneAbstimmungen.einheiten} ${this.abstimmErgebnisse.offeneAbstimmungen.einheiten == 1 ? "Einheit" : "Einheiten"}.</b> Enthaltungen: ${this.abstimmErgebnisse.enthaltung.einheiten} ${s1_stimmgeber_bezeichnung}`;
            if(relation_s1 === 0)
            {
              this.abstimmErgebnisse_s1.ja.geteilt = this.abstimmErgebnisse.ja.einheiten > 0 ? this.globals.germanDecimal2(( this.abstimmErgebnisse.ja.einheiten / s1_einheiten_abgestimmt_) / 0.01) +"%" : 0+"%";
              this.abstimmErgebnisse_s1.nein.geteilt = this.abstimmErgebnisse.nein.einheiten > 0 ? this.globals.germanDecimal2(( this.abstimmErgebnisse.nein.einheiten / s1_einheiten_abgestimmt_) / 0.01) +"%" : 0+"%";
              this.abstimmErgebnisse_s1.enthaltung.geteilt = this.abstimmErgebnisse.enthaltung.einheiten > 0 ? this.globals.germanDecimal2(( this.abstimmErgebnisse.enthaltung.einheiten / s1_einheiten_abgestimmt_) / 0.01) +"%" : 0+"%";
              this.abstimmErgebnisse_s1.anwesend.geteilt = this.abstimmErgebnisse.anwesend.einheiten > 0 ? this.globals.germanDecimal2(( this.abstimmErgebnisse.anwesend.einheiten / s1_einheiten_abgestimmt_) / 0.01) +"%" : 0+"%";
              this.abstimmErgebnisse_s1.abwesend.geteilt = this.abstimmErgebnisse.abwesend.einheiten > 0 ? this.globals.germanDecimal2(( this.abstimmErgebnisse.abwesend.einheiten / s1_einheiten_abgestimmt_) / 0.01) +"%" : 0+"%";
              this.abstimmErgebnisse_s1.meaStimmenGesamt = s1_einheiten_abgestimmt_;
            }        
          break;

          default:
          break;
        }

        switch(s2_key)
        {
          case stimmgeber_key.stimmgeber_tn:       
            s2_stimmgeber_bezeichnung = "Köpfe";     
            this.abstimmErgebnisse_s2.ja.mea = this.abstimmErgebnisse.ja.kopf_stimmen;
            this.abstimmErgebnisse_s2.meaStimmenGesamt = this.abstimmErgebnisse.berechtigte_teilnehmer;
            this.abstimmErgebnisse_s2.ja.geteilt = this.abstimmErgebnisse.ja.kopf_stimmen > 0 ? this.globals.germanDecimal2((this.abstimmErgebnisse.ja.kopf_stimmen / this.abstimmErgebnisse.berechtigte_teilnehmer) / 0.01) +"%" : 0+"%";
            this.abstimmErgebnisse_s2.nein.mea = this.abstimmErgebnisse.nein.kopf_stimmen;
            this.abstimmErgebnisse_s2.nein.geteilt = this.abstimmErgebnisse.nein.kopf_stimmen > 0 ? this.globals.germanDecimal2((this.abstimmErgebnisse.nein.kopf_stimmen / this.abstimmErgebnisse.berechtigte_teilnehmer) / 0.01) +"%" : 0+"%";
            this.abstimmErgebnisse_s2.enthaltung.mea = this.abstimmErgebnisse.enthaltung.kopf_stimmen;
            this.abstimmErgebnisse_s2.enthaltung.geteilt = this.abstimmErgebnisse.enthaltung.kopf_stimmen > 0 ? this.globals.germanDecimal2((this.abstimmErgebnisse.enthaltung.kopf_stimmen / this.abstimmErgebnisse.berechtigte_teilnehmer) / 0.01) +"%" : 0+"%";
            this.abstimmErgebnisse_s2.anwesend.mea = this.abstimmErgebnisse.anwesend.kopf_stimmen;
            this.abstimmErgebnisse_s2.anwesend.geteilt = this.abstimmErgebnisse.anwesend.kopf_stimmen > 0 ? this.globals.germanDecimal2((this.abstimmErgebnisse.anwesend.kopf_stimmen / this.abstimmErgebnisse.berechtigte_teilnehmer) / 0.01) +"%" : 0+"%";
            this.abstimmErgebnisse_s2.abwesend.mea = this.abstimmErgebnisse.abwesend.kopf_stimmen;
            this.abstimmErgebnisse_s2.abwesend.geteilt = this.abstimmErgebnisse.abwesend.kopf_stimmen > 0 ? this.globals.germanDecimal2((this.abstimmErgebnisse.abwesend.kopf_stimmen / this.abstimmErgebnisse.berechtigte_teilnehmer) / 0.01) +"%" : 0+"%";
            this.abstimmErgebnisse_s2.legend_top = `Köpfe abgestimmt <b>${this.abstimmErgebnisse.teilnehmer_haben_abgestimmt2} / ${this.abstimmErgebnisse.berechtigte_teilnehmer}</b>`;
            this.abstimmErgebnisse_s2.legend_bottom = `Offene Abstimmungen: <b>${this.abstimmErgebnisse.offeneAbstimmungen.kopf_stimmen} ${s2_stimmgeber_bezeichnung}.</b> Enthaltungen: ${this.abstimmErgebnisse.enthaltung.kopf_stimmen} ${s2_stimmgeber_bezeichnung}`;
            if(relation_s2 === 0) // nur Abstimmungen
            {
              this.abstimmErgebnisse_s2.ja.geteilt = this.abstimmErgebnisse.ja.kopf_stimmen > 0 ? this.globals.germanDecimal2((this.abstimmErgebnisse.ja.kopf_stimmen / s2_teilnehmer_haben_abgestimmt_) / 0.01) +"%" : 0+"%";
              this.abstimmErgebnisse_s2.nein.geteilt = this.abstimmErgebnisse.nein.kopf_stimmen > 0 ? this.globals.germanDecimal2((this.abstimmErgebnisse.nein.kopf_stimmen / s2_teilnehmer_haben_abgestimmt_) / 0.01) +"%" : 0+"%";
              this.abstimmErgebnisse_s2.enthaltung.geteilt = this.abstimmErgebnisse.enthaltung.kopf_stimmen > 0 ? this.globals.germanDecimal2((this.abstimmErgebnisse.enthaltung.kopf_stimmen / s2_teilnehmer_haben_abgestimmt_) / 0.01) +"%" : 0+"%";
              this.abstimmErgebnisse_s2.anwesend.geteilt = this.abstimmErgebnisse.anwesend.kopf_stimmen > 0 ? this.globals.germanDecimal2((this.abstimmErgebnisse.anwesend.kopf_stimmen / s2_teilnehmer_haben_abgestimmt_) / 0.01) +"%" : 0+"%";
              this.abstimmErgebnisse_s2.abwesend.geteilt = this.abstimmErgebnisse.abwesend.kopf_stimmen > 0 ? this.globals.germanDecimal2((this.abstimmErgebnisse.abwesend.kopf_stimmen / s2_teilnehmer_haben_abgestimmt_) / 0.01) +"%" : 0+"%";
              this.abstimmErgebnisse_s2.meaStimmenGesamt = s2_teilnehmer_haben_abgestimmt_;
            }            
          break;

          case stimmgeber_key.stimmgeber_mea:
            s2_stimmgeber_bezeichnung = "MEA";   
            this.abstimmErgebnisse_s2.ja.mea = this.abstimmErgebnisse.ja.mea;
            this.abstimmErgebnisse_s2.meaStimmenGesamt = this.abstimmErgebnisse.meaStimmenGesamt;
            this.abstimmErgebnisse_s2.ja.geteilt = this.abstimmErgebnisse.ja.kopf_stimmen > 0 ? this.globals.germanDecimal2(( this.abstimmErgebnisse.ja.mea / this.abstimmErgebnisse.meaStimmenGesamt) / 0.01) +"%" : 0+"%";
            this.abstimmErgebnisse_s2.nein.mea = this.abstimmErgebnisse.nein.mea;
            this.abstimmErgebnisse_s2.nein.geteilt = this.abstimmErgebnisse.nein.mea > 0 ? this.globals.germanDecimal2(( this.abstimmErgebnisse.nein.mea / this.abstimmErgebnisse.meaStimmenGesamt) / 0.01) +"%" : 0+"%";
            this.abstimmErgebnisse_s2.enthaltung.mea = this.abstimmErgebnisse.enthaltung.mea;
            this.abstimmErgebnisse_s2.enthaltung.geteilt = this.abstimmErgebnisse.enthaltung.mea > 0 ? this.globals.germanDecimal2((this.abstimmErgebnisse.enthaltung.mea / this.abstimmErgebnisse.meaStimmenGesamt) / 0.01) +"%" : 0+"%";
            this.abstimmErgebnisse_s2.anwesend.mea = this.abstimmErgebnisse.anwesend.mea;
            this.abstimmErgebnisse_s2.anwesend.geteilt = this.abstimmErgebnisse.anwesend.mea > 0 ? this.globals.germanDecimal2(( this.abstimmErgebnisse.anwesend.mea / this.abstimmErgebnisse.meaStimmenGesamt) / 0.01) +"%" : 0+"%";
            this.abstimmErgebnisse_s2.abwesend.mea = this.abstimmErgebnisse.abwesend.mea;
            this.abstimmErgebnisse_s2.abwesend.geteilt = this.abstimmErgebnisse.abwesend.mea > 0 ? this.globals.germanDecimal2(( this.abstimmErgebnisse.abwesend.mea / this.abstimmErgebnisse.meaStimmenGesamt) / 0.01) +"%" : 0+"%";
            this.abstimmErgebnisse_s2.legend_top = `MEA abgestimmt <b>${this.globals.germanDecimal2(this.abstimmErgebnisse.meaAbgestimmt2)} / ${this.globals.germanDecimal2(this.abstimmErgebnisse.meaStimmenGesamt)}</b>`;
            this.abstimmErgebnisse_s2.legend_bottom = `Offene Abstimmungen: <b>${this.globals.germanDecimal2(this.abstimmErgebnisse.offeneAbstimmungen.mea)} ${s2_stimmgeber_bezeichnung}.</b> Enthaltungen: ${this.globals.germanDecimal2(this.abstimmErgebnisse.enthaltung.mea)} ${s2_stimmgeber_bezeichnung}`;
            if(relation_s2 === 0)
            {
              this.abstimmErgebnisse_s2.ja.geteilt = this.abstimmErgebnisse.ja.mea > 0 ? this.globals.germanDecimal2(( this.abstimmErgebnisse.ja.mea / s2_mea_abgestimmt_) / 0.01) +"%" : 0+"%";
              this.abstimmErgebnisse_s2.nein.geteilt = this.abstimmErgebnisse.nein.mea > 0 ? this.globals.germanDecimal2(( this.abstimmErgebnisse.nein.mea / s2_mea_abgestimmt_) / 0.01) +"%" : 0+"%";
              this.abstimmErgebnisse_s2.enthaltung.geteilt = this.abstimmErgebnisse.enthaltung.mea > 0 ? this.globals.germanDecimal2((this.abstimmErgebnisse.enthaltung.mea / s2_mea_abgestimmt_) / 0.01) +"%" : 0+"%";
              this.abstimmErgebnisse_s2.anwesend.geteilt = this.abstimmErgebnisse.anwesend.mea > 0 ? this.globals.germanDecimal2(( this.abstimmErgebnisse.anwesend.mea / s2_mea_abgestimmt_) / 0.01) +"%" : 0+"%";
              this.abstimmErgebnisse_s2.abwesend.geteilt = this.abstimmErgebnisse.abwesend.mea > 0 ? this.globals.germanDecimal2(( this.abstimmErgebnisse.abwesend.mea / s2_mea_abgestimmt_) / 0.01) +"%" : 0+"%";
              this.abstimmErgebnisse_s2.meaStimmenGesamt = s2_mea_abgestimmt_;
            }            
          break;

          case stimmgeber_key.stimmgeber_eh:
            s2_stimmgeber_bezeichnung = this.abstimmErgebnisse.enthaltung.einheiten == 1 ? "Einheit" : "Einheiten";   
            this.abstimmErgebnisse_s2.ja.mea = this.abstimmErgebnisse.ja.einheiten;
            this.abstimmErgebnisse_s2.meaStimmenGesamt = this.abstimmErgebnisse.einheitenGesamt;
            this.abstimmErgebnisse_s2.ja.geteilt = this.abstimmErgebnisse.ja.einheiten > 0 ? this.globals.germanDecimal2(( this.abstimmErgebnisse.ja.einheiten / this.abstimmErgebnisse.einheitenGesamt) / 0.01) +"%" : 0+"%";
            this.abstimmErgebnisse_s2.nein.mea = this.abstimmErgebnisse.nein.einheiten;
            this.abstimmErgebnisse_s2.nein.geteilt = this.abstimmErgebnisse.nein.einheiten > 0 ? this.globals.germanDecimal2(( this.abstimmErgebnisse.nein.einheiten / this.abstimmErgebnisse.einheitenGesamt) / 0.01) +"%" : 0+"%";
            this.abstimmErgebnisse_s2.enthaltung.mea = this.abstimmErgebnisse.enthaltung.einheiten;
            this.abstimmErgebnisse_s2.enthaltung.geteilt = this.abstimmErgebnisse.enthaltung.einheiten > 0 ? this.globals.germanDecimal2(( this.abstimmErgebnisse.enthaltung.einheiten / this.abstimmErgebnisse.einheitenGesamt) / 0.01) +"%" : 0+"%";
            this.abstimmErgebnisse_s2.anwesend.mea = this.abstimmErgebnisse.anwesend.einheiten;
            this.abstimmErgebnisse_s2.anwesend.geteilt = this.abstimmErgebnisse.anwesend.einheiten > 0 ? this.globals.germanDecimal2(( this.abstimmErgebnisse.anwesend.einheiten / this.abstimmErgebnisse.einheitenGesamt) / 0.01) +"%" : 0+"%";
            this.abstimmErgebnisse_s2.abwesend.mea = this.abstimmErgebnisse.abwesend.einheiten;
            this.abstimmErgebnisse_s2.abwesend.geteilt = this.abstimmErgebnisse.abwesend.einheiten > 0 ? this.globals.germanDecimal2(( this.abstimmErgebnisse.abwesend.einheiten / this.abstimmErgebnisse.einheitenGesamt) / 0.01) +"%" : 0+"%";
            this.abstimmErgebnisse_s2.legend_top = `Einheiten abgestimmt <b> ${this.abstimmErgebnisse.abgestimmt_einheiten2} / ${this.abstimmErgebnisse.einheitenGesamt}</b>`;
            this.abstimmErgebnisse_s2.legend_bottom = `Offene Abstimmungen: <b>${this.abstimmErgebnisse.offeneAbstimmungen.einheiten} ${this.abstimmErgebnisse.offeneAbstimmungen.einheiten == 1 ? "Einheit" : "Einheiten"}.</b> Enthaltungen: ${this.abstimmErgebnisse.enthaltung.einheiten} ${s2_stimmgeber_bezeichnung}`;
            if(relation_s2 === 0)
            {
              this.abstimmErgebnisse_s2.ja.geteilt = this.abstimmErgebnisse.ja.einheiten > 0 ? this.globals.germanDecimal2(( this.abstimmErgebnisse.ja.einheiten / s2_einheiten_abgestimmt_) / 0.01) +"%" : 0+"%";
              this.abstimmErgebnisse_s2.nein.geteilt = this.abstimmErgebnisse.nein.einheiten > 0 ? this.globals.germanDecimal2(( this.abstimmErgebnisse.nein.einheiten / s2_einheiten_abgestimmt_) / 0.01) +"%" : 0+"%";
              this.abstimmErgebnisse_s2.enthaltung.geteilt = this.abstimmErgebnisse.enthaltung.einheiten > 0 ? this.globals.germanDecimal2(( this.abstimmErgebnisse.enthaltung.einheiten / s2_einheiten_abgestimmt_) / 0.01) +"%" : 0+"%";
              this.abstimmErgebnisse_s2.anwesend.geteilt = this.abstimmErgebnisse.anwesend.einheiten > 0 ? this.globals.germanDecimal2(( this.abstimmErgebnisse.anwesend.einheiten / s2_einheiten_abgestimmt_) / 0.01) +"%" : 0+"%";
              this.abstimmErgebnisse_s2.abwesend.geteilt = this.abstimmErgebnisse.abwesend.einheiten > 0 ? this.globals.germanDecimal2(( this.abstimmErgebnisse.abwesend.einheiten / s2_einheiten_abgestimmt_) / 0.01) +"%" : 0+"%";
              this.abstimmErgebnisse_s2.meaStimmenGesamt = s2_einheiten_abgestimmt_;
            }        
          break;

          default:
          break;
        }

        this.cleanLegends(relation_s1, relation_s2);
  

      }
    }
    catch (error)
    {
      console.log(error)
    }

  }

  private cleanLegends(relation_s1 = 0, relation_s2 = 0):void
  {
    this.abstimmErgebnisse_s1.legend_bottom = this.abstimmErgebnisse_s1.legend_bottom.split(".</b>")[1].trim()+"</b>";
    this.abstimmErgebnisse_s2.legend_bottom = this.abstimmErgebnisse_s2.legend_bottom.split(".</b>")[1].trim()+"</b>";
    if( this.selectedTagesordnungspunkt.abstimmung_beendet > 0   )
    {
      this.abstimmErgebnisse_s1.legend_bottom = relation_s1 == 1 ? "" : this.abstimmErgebnisse_s1.legend_bottom;
      this.abstimmErgebnisse_s2.legend_bottom = relation_s2 == 1 ? "" : this.abstimmErgebnisse_s2.legend_bottom;
    }
    else
    {
      this.abstimmErgebnisse_s1.legend_bottom = relation_s1 == 1 ? "" : this.abstimmErgebnisse_s1.legend_bottom;
      this.abstimmErgebnisse_s2.legend_bottom = relation_s2 == 1 ? "" : this.abstimmErgebnisse_s2.legend_bottom;
    }
  }

}
