export enum e_event_emitter_enum{
  update_tops_after_top_creation_from_vorlage = "update_tops_after_top_creation_from_vorlage",
  BenutzerSperren = "BenutzerSperren",
  refreshDashboardVersammlungslister = "refreshDashboardVersammlungsliste",
  updateNavigate = "updateNavigate",
  onSocketInit = "onSocketInit",
  abstimmung_main_onSocketInit = "abstimmung_main_onSocketInit",
  authorized = "authorized",
  updateVersammlung = "updateVersammlung",
  teilnehmerUeberBarcodeAngemeldet = "teilnehmerUeberBarcodeAngemeldet",
  getSettingsFromServer = "getSettingsFromServer",
  connect = "connect",
  disconnect = "disconnect",
  onLogin = "onLogin",
  open_video_stream_btn = "open_video_stream_btn",
  openVideoSessionTab = "openVideoSessionTab",
  openVersammlungbeendenModal = "openVersammlungbeendenModal",
  response_vorlagenTop = "response_vorlagenTop",
  logo_loaded = "logo_loaded",
  setSelectedVersammlung = "setSelectedVersammlung",
  querySelectedVersammlung = "querySelectedVersammlung",
  unauthorized = "unauthorized",
  getHausverwalter = "getHausverwalter",
  getErgebnissComponentData = "getErgebnissComponentData",
  auswertungComponentOptions = "auswertungComponentOptions",
  abstimmungBeendet = "abstimmungBeendet",
  abstimmungGobBeendet = "abstimmungGobBeendet",
  setErgebnissData = "setErgebnissData",
  reloadComponentData = "reloadComponentData",
  setErklaerungsData = "setErklaerungsData",
  setDefaultPage = "setDefaultPage",
  teilnehmerSperren = "teilnehmerSperren",
  teilnehmerAbmelden = "teilnehmerAbmelden",
  tn_vertritt_anderen_tn = "tn_vertritt_anderen_tn",
  abstimmung_gob = "abstimmung_gob",
  startNotificationTimer = "startNotificationTimer",
  getAllErgebnissComponentDataForVersammlung = "getAllErgebnissComponentDataForVersammlung",
  updatePersonalSessionLink = "updatePersonalSessionLink",
  setCssStyle = "setCssStyle",
  displaySambaContainer = "displaySambaContainer",
  newVersionDetected = "newVersionDetected"
}

export enum abstimmung_next_commands 
{
  showVielenDankFuerDieAbstimmung = "showVielenDankFuerDieAbstimmung",
  showNaechsteAbstimmung = "showNaechsteAbstimmung",
  showVielenDankFuerDieTeilnahme = "showVielenDankFuerDieTeilnahme",
  showSieHabenKeineEinheiten = "showSieHabenKeineEinheiten"
}


export enum e_command_enum{
  authentication = "authentication",
  showAuswertungDetails = "showAuswertungDetails" ,
  updateAnwesenheitsstatus = "updateAnwesenheitsstatus",
  teilnehmerSperren = "teilnehmerSperren",
  abstimmungStarten = "abstimmungStarten",
  abstimmungBeenden = "abstimmungBeenden",
  updateErgebnisVerkuendung = "updateErgebnisVerkuendung",
  updateTagesordnungspunktEinstellungen = "updateTagesordnungspunktEinstellungen",
  updateProtokollnotiz = "updateProtokollnotiz",
  updateGeschaeftsordnungsbeschluss = "updateGeschaeftsordnungsbeschluss",
  updateBeschlussgegenstand = "updateBeschlussgegenstand",
  updateBeschlussvorschlag = "updateBeschlussvorschlag",
  updateErlaeuterung = "updateErlaeuterung",
  getSelectedVersammlung = "getSelectedVersammlung",
  navigate = "navigate",
  by_checkAuthUser = "by_checkAuthUser",
  setTagesordnungspunkt_GOB = "setTagesordnungspunkt_GOB",
  getTagesordnungspunkt_GOB = "getTagesordnungspunkt_GOB",
  singleAbstimmung = "singleAbstimmung",
  multiAbstimmung = "multiAbstimmung",
  toggle = "toggle",
  toIndex = "toIndex",
  toText = "toText",
  showHeader = "showHeader",
  showTopBarLogo = "showTopBarLogo",
  disableTopBarLogo = "disableTopBarLogo",
  logout = "logout",
  response_refresh = "response_refresh",
  teilnehmerAbmeldenBackToLogin = "teilnehmerAbmeldenBackToLogin",
  login = "login"

}

export enum e_anwesenheit {
    gesperrt = -1,
    abwesend = 0,
    anwesend = 1,
    angemeldet = 2
}
export enum e_abstimmungen {
    abwesend_nicht_abgestimmt = -3,
    anwesend_nicht_abgestimmt = -2,
    nein = -1,
    enthaltung = 0,
    ja = 1,
}

export enum e_mehrheit{
  einfache_mehrheit = 0,
  qualifizierte_mehrheit = 1,
  doppelte_qualifizierung = 2,
  doppelte_qualifizierung_spezial = 3
}


export class AnmeldungsStatus
{
    public e_anwesenheit = e_anwesenheit;
    public getAnmeldungsStatus(anwesend)
    {  
      let text = ``;
      switch(anwesend)
      {
        case e_anwesenheit.gesperrt:
          text = "gesperrt";
          break;
        case e_anwesenheit.abwesend:
          text = "abwesend";
          break;
        case e_anwesenheit.anwesend:
          text = "anwesend";
          break;
        case e_anwesenheit.angemeldet:
          text = "angemeldet";
          break;
        default: 
          text = "gesperrt"
          break;
      }
    
      // return anwesend==1 ? 'anwesend' : (anwesend==2 ? 'angemeldet' : 'abwesend');
      return text;
    }

    public  getAbstimmungsText(abstimmung, toUpper  = false)
    {  
      let text = ``;
     
      switch(abstimmung)
      {
        case e_abstimmungen.abwesend_nicht_abgestimmt:
            toUpper === true ? text = "Abwesend nicht abgestimmt" : text = "abwesend nicht abgestimmt";
        break;
        case e_abstimmungen.anwesend_nicht_abgestimmt:
            toUpper === true ? text = "Anwesend nicht abgestimmt" : text = "anwesend nicht abgestimmt";
        break;
        case e_abstimmungen.nein:
            toUpper === true ? text = "Nein" : text = "nein";
        break;
        case e_abstimmungen.enthaltung:
            toUpper === true ? text = "Enthaltung" : text = "enthaltung";
        break;
        case e_abstimmungen.ja:
            toUpper === true ? text = "Ja" : text = "ja";
        break;
        default: 
            toUpper === true ? text = "Keine Abstimmung" : text = "keine Abstimmung";
        break;
      }
    

      return text;
    }

    public getAnmeldungsStatus2(anwesend , toUpper  = false)
    {  
      let text = ``;
      
        switch(anwesend)
        {
          case e_anwesenheit.gesperrt:
              toUpper === true ? text = "Gesperrt" : text = "gesperrt";
          break;
          case e_anwesenheit.abwesend:
              toUpper === true ? text = "Abwesend" : text = "abwesend";
          break;
          case e_anwesenheit.anwesend:
              toUpper === true ? text = "Offline-Teilnahme" : text = "offline-Teilnahme";
          break;
          case e_anwesenheit.angemeldet:
              toUpper === true ? text = "Online-Teilnahme" : text = "online-Teilnahme";
          break;
          default: 
              toUpper === true ? text = "Gesperrt" : text = "gesperrt";
          break;
        }
      

      return text;
    }

}

export enum AbstimmErgebnisseHelperEnum {
  teilnehmer_haben_abgestimmt = "teilnehmer_haben_abgestimmt"
}