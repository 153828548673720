/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-empty */
import { Component, OnInit, OnDestroy, AfterViewInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { DataExchangeService } from 'src/app/_services/data-exchange.service';
// import { CookieServiceService } from 'src/app/_services/cookie-service.service';
import { SocketService } from 'src/app/_services/socket.service';

import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';

import {browserRefresh} from '../../app.component'
import { LoggerService } from 'src/app/services/logger.service';
import { MatDialog } from '@angular/material/dialog';
import { StandardBestaetigenModal } from '../modals/modal-standard-bestaetigung';
import { e_command_enum } from 'src/app/_models/enum';
import {EventEmitterService} from '../../services/event-emitter.service';
import {AuthenticationService} from '../../_services/authentication.service';
import {TeilnehmerService} from '../../_services/user.service';
import {AlertService} from '../../_services/alert.service';

declare let HelperFunctions: any;

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit, OnDestroy {
  // Listener
  private getCurrentUserListener: Subscription;
  private eventEmitterSubscription: Subscription;
  private getSelectedVersammlungFromDexListener: Subscription;
  // Vars
  public currentUser: any;
  public topbarLoginUser: string;
  public displayHeader = true;
  public selectedVersammlung : any = {};
  public browserRefresh : any = false;
  public showOptions : any = 
  {
    topbarLogo: true
  }
  public showAppInfoData : any = {
    clickTimer : null,
    clicks : 0,
    clicksNecessary : 3,
    clickInterval : 2000,
    modalRef: null,
  }

  constructor(
    private dex: DataExchangeService,
    public _socketService: SocketService,
    public authenticationService: AuthenticationService,
    private userService: TeilnehmerService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    @Inject(DOCUMENT) private _document: Document,
    private alertService: AlertService,
    public dialog: MatDialog,
    private eventEmitterService: EventEmitterService,
    private logger: LoggerService)
  { 
    this.showOptions.topbarLogo = true;
  }

  ngOnInit(): void 
  {
    this.currentUser = this.authenticationService.currentUserValue;
    this.topbarLoginUser = '<i class="fas fa-user-alt"></i> : '+this.currentUser;
    this.initListener();
  }
  ngOnDestroy(): void 
  {
    this.browserRefresh = browserRefresh;
    this.logger.info(browserRefresh, '[Browser refreshed]?');
    this.getCurrentUserListener.unsubscribe();
    this.eventEmitterSubscription.unsubscribe();
    this.getSelectedVersammlungFromDexListener != undefined ? this.getSelectedVersammlungFromDexListener.unsubscribe() : null;
  }
  initListener()
  {
    this.getCurrentUserListener = this.authenticationService.currentUser.subscribe(userData => {
      try
      {
        this.currentUser = userData;
        this.topbarLoginUser = '<i class="fas fa-user-alt"></i> : '+this.currentUser;
      }catch(e){this.logger.error(e);}
    });

    // Event Emitter
    this.eventEmitterSubscription = this.eventEmitterService.eventEmitter.subscribe(msg => {
      try
      {
        if(msg.hasOwnProperty("cmd"))
        {
          const cmd = msg.cmd;
          if(cmd === e_command_enum.showHeader)
          {           
            if(msg.hasOwnProperty("data") && msg.data.hasOwnProperty("display"))
            {
              if(msg.data.display === 'none')
              {
                this.displayHeader = false;
              }
              if(msg.data.display === 'inherit')
              {
                this.displayHeader = true;
              }
            }
          }
          if(cmd === e_command_enum.showTopBarLogo)
          {
            this.showOptions.topbarLogo = true;
          }
          if(cmd === e_command_enum.disableTopBarLogo)
          {
            this.showOptions.topbarLogo = false;
          }          
         
        }
      }catch(e){this.logger.error(e);}
    });

    // getSelectedVersammlung
    this.getSelectedVersammlungFromDexListener = this.dex.currentSelectedVersammlung.subscribe( async (data : any )=>{
      try
      {
        if(data=== null || data === '' || data ==='undefined' || Object.keys(data).length === 0)
        {
        }
        else
        {
          this.selectedVersammlung = data; 
          this.logger.info(data, "[topbar.component] getSelectedVersammlungFromDexListener ");
        }
      }
      catch(e){this.logger.error(e);}
      });
  }


  reloadComponent(route) 
  {
    this._document.defaultView.location.reload();
  }

  public showAppInfo()
  {
    // console.log("click ",this.showAppInfoData.clicks, " ",this.showAppInfoData.modalRef);
    this.showAppInfoData.clicks ++;

    if(this.showAppInfoData.clickTimer == null)
    {
      this.showAppInfoData.clickTimer = setTimeout(() => {
        // console.log("clear")
        clearTimeout(this.showAppInfoData.clickTimer);
        this.showAppInfoData.clickTimer = null;
        this.showAppInfoData.clicks = 0;
      }, this.showAppInfoData.clickInterval);
    }
    else
    {
      
      if(this.showAppInfoData.clicks >= 3 && !this.showAppInfoData.modalRef)
      {
        // open Modal StandardBestaetigenModal
        this.showAppInfoData.modalRef = this.dialog.open(StandardBestaetigenModal, {
          width: "85vw",
          ariaLabelledBy: 'modal-basic-title',
          
        });
        this.showAppInfoData.modalRef.componentInstance.afterViewinit.subscribe((modal : StandardBestaetigenModal) => {
          if(Object.keys(this.dex.dbsettings).length > 0)
          {
            modal.content.nativeElement.innerHTML = 
            `<p>Version : ${this.dex.dbsettings.filter(s=>{return s.key == "version"})[0].value }<br/>Raum : ${this.dex.dbsettings.filter(s=>{return s.key == "raum"})[0].value }</p> 
            `;
          }
          else
          {
            modal.content.nativeElement.innerHTML = 
            `<p>Keine Settings vorhanden / lesbar.</p> `;
          }
          modal.okBtn.nativeElement.innerHTML = "Schließen";
          modal.closeBtn.nativeElement.style.display ='none';
        });
        this.showAppInfoData.modalRef.disableClose = true;
  
        this.showAppInfoData.modalRef.componentInstance.onInit.subscribe((modal: StandardBestaetigenModal) => {
          modal.data = this.selectedVersammlung;
          modal.header = "Vulcavo APP Infos"
        });

        this.showAppInfoData.modalRef.componentInstance.afterOkpressed.subscribe((data: any) => {
          this.showAppInfoData.modalRef.close();
          this.showAppInfoData.modalRef = null;
        })
  
        this.showAppInfoData.modalRef.componentInstance.afterClosepressed.subscribe((data: any) => {
          this.showAppInfoData.modalRef = null;
        })

        this.showAppInfoData.modalRef.componentInstance.ok_btn = function(text, router){ 
   
        };
        this.showAppInfoData.modalRef.componentInstance.cancel_function = null;
      }
    }
  }
}
