import {Injectable} from '@angular/core';
import {ToastrService} from "ngx-toastr";

@Injectable({providedIn: 'root'})

export class NewalertService {
  constructor(private toastr: ToastrService) {
  }

  success(message: string) {
    this.toastr.success(message, "Erfolgreich");
  }

  warning(message: string) {
    this.toastr.warning(message, "Warnung");
  }

  error(message: string) {
    this.toastr.error(message, "Fehler");
  }

  info(message: string) {
    this.toastr.info(message, "Information");
  }
}
