import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { SocketService } from '../_services/socket.service';
// import pSBC from 'shade-blend-color';
import { LoggerService } from './logger.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CssModifierService {

  // VARS
  public cssUrl: string;
  public faviconUrl: string;
  private favicon_id = "app-fav-icon";
  private theme_link_id = "client-theme-link";
  private custom_style_id = "client-theme-style";
  private customStyleSrc: BehaviorSubject<any>;
  public currentCustomStyle : Observable<any>;
  // Advance / Shade only this know Colors to lighter and darker
  private only_known_colors : Array<string> = [
    "--highlighte_color", "--secondary_color", "--c_gray_1" , "--base_color" ,"--c_white" , "--c_black"
  ]
  // Exclude CSS Var's matching this part strings
  private exclude: Array<string> = ["-hsl" , "-hsl-" , "-darken-" , "-lighten-" , "box_shadow"];
  // Include CSS Vars which are Colors
  private include: Array<string> = ["#" , "rgb" , "rgba"];

  constructor(
    private _socketService: SocketService,
    private http: HttpClient,
    private logger: LoggerService,
    @Inject(DOCUMENT) private document: Document,
  ) 
  { 
    this.cssUrl = `${this._socketService.getServerPath()}/assets/css/custom.css`;
    this.faviconUrl = `${this._socketService.getServerPath()}/assets/favicon/favicon.ico`;
    this.customStyleSrc = new BehaviorSubject<any>('');
    this.currentCustomStyle =  this.customStyleSrc.asObservable();
  }


  // https://juristr.com/blog/2019/08/dynamically-load-css-angular-cli/
  loadStyle() 
  {
    this.setFavicon();
    const themeLink = this.document.getElementById(this.theme_link_id) as HTMLLinkElement;
    if (themeLink) 
    {
      this.deleteThemeLink();
      this.createThemeLink();
    } 
    else 
    {
      this.createThemeLink();
    }
    this.retrieveStyleFromServerHostedCssFile();
  }

  setFavicon()
  {
    const faviconLink = this.document.getElementById(this.favicon_id) as HTMLLinkElement;
    if (faviconLink) 
    {
      this.document.getElementById(this.favicon_id).remove();
    }    
    const head = this.document.getElementsByTagName('head')[0];
    const style = this.document.createElement('link');
    style.id = this.favicon_id;
    style.rel = 'icon';
    style.type = 'image/x-icon';
    // style.media = 'all';
    style.href = `${this.faviconUrl}`;
    // Style laden
    head.appendChild(style);
  }

  deleteThemeLink()
  {
    // console.log("deleteThemeLink")
    const themeLink = this.document.getElementById(this.theme_link_id) as HTMLLinkElement;
    themeLink.href = this.cssUrl;
    this.document.getElementById(this.theme_link_id).remove();
  }
  createThemeLink()
  {
    // console.log("createThemeLink")
    const head = this.document.getElementsByTagName('head')[0];
    const style = this.document.createElement('link');
    style.id = this.theme_link_id;
    style.rel = 'stylesheet';
    style.type = 'text/css';
    style.media = 'all';
    style.href = `${this.cssUrl}`;
    // Style laden
    head.appendChild(style);
  }

  getCustomStyleFromServer()
  {
    const url = `${this._socketService.getServerPath()}/getCustomStyle`;
    const headers= new HttpHeaders({
      // 'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Headers': 'Content-Type',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE'
    })
    this.http.get(url, { headers: headers, responseType: "text"})
    .subscribe((style:string) => {
        this.customStyleSrc.next(style.trim());
    })
  }

  retrieveStyleFromServerHostedCssFile() 
  {
      const headers= new HttpHeaders({

            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Credentials': 'true',
            'Access-Control-Allow-Headers': 'Content-Type',
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE'
      })
      this.http.get(this.cssUrl, { headers: headers, responseType: "text"})
      .subscribe((style:string) => {
          this.customStyleSrc.next(style);
          this.handleStyle(style);
      })
  }


  handleStyle(style: string)
  {
    const new_provide_root_vars : Array<string> = this.getRootCssVarsFromString(style);
    // Generate Advanced Colors from server css
    let advancedColors = this.generateAdvancedColorsFromArray(new_provide_root_vars); 
    const computedColors = this.generateAdvancedColorsFromArray(this.getComputedColors(this.only_known_colors));
    advancedColors = computedColors.concat(advancedColors);
    // remove Dublicates
    advancedColors = [...new Set(advancedColors)];
    this.appendColorsToRoot(advancedColors);
  }

  getRootCssVarsFromString(style:string): Array<string>
  {
    const cleaned = style.split(":root")[1].replace(/\s/g,'')
    .replace(/^.*{([^}]+)}.*/,'$1')
    .match(/([^;]+)/g);
    return cleaned;
  }

  appendColorsToRoot(colorsArray: Array<string> , styleText: string = null)
  {
    if(styleText == null)
    {
      styleText = `:root{`;
      for (let i = 0; i < colorsArray.length; i++) {
        const color = colorsArray[i];
        styleText+=`${color};`
      }
      styleText+=`}`;
    }

    const styleElement = this.document.getElementById(this.custom_style_id) as HTMLStyleElement;
    if (styleElement) 
    {
      this.document.getElementById(this.custom_style_id).remove();
    } 

    const head = this.document.getElementsByTagName('head')[0];
    const new_styleElement = this.document.createElement('style');
    new_styleElement.id = this.custom_style_id;
    // in Fact not neccessary to set media
    new_styleElement.media = 'all';      
    new_styleElement.append(styleText);
    head.appendChild(new_styleElement);
  }

  // https://stackoverflow.com/questions/48760274/get-all-css-root-variables-in-array-using-javascript-and-change-the-values
  getComputedCSSVars() : CSSStyleSheet[]
  {
    const styleSheet = this.document.styleSheets;
    let styleSheetArray = Array.from(styleSheet);
    // Don't handle CSS with unknown external Source
    // let startsWidth = window.location.origin;
    const startsWidth = this._socketService.getSettings().prefix+"://"+this._socketService.getSettings().server_ip;
    styleSheetArray = styleSheetArray
    .filter(
      (sheet) =>{
        // console.log("sheet" ,sheet)
        return sheet.href === null || (sheet.href.includes(startsWidth) && !sheet.href.includes(this.cssUrl) )//sheet.href.startsWith(startsWidth)
      }
    )
    .reduce(
      (acc, sheet) =>
        (acc = [
          ...acc,
          ...Array.from(sheet.cssRules).reduce(
            (def, rule:any) =>
              (def =
                rule.selectorText === ":root"
                  ? [
                      ...def,
                      ...Array.from(rule.style).filter((name:string) =>
                        name.startsWith("--")
                      )
                    ]
                  : def),
            []
          )
        ]),
      []
    );

    return styleSheetArray;
  }

  generateAdvancedColorsFromArray(colorArray: Array<string> = []) : Array<string>
  {
    let advancedColors : Array<string> = [];
    for (let i = 0; i < colorArray.length; i++) {
      const color = colorArray[i];
      const colorName = color.split(":")[0];
      const colorValue = color.split(":")[1];
      advancedColors = advancedColors.concat([color]);
      advancedColors = advancedColors.concat(this.generateAdvancedColor(colorName, colorValue))
    }
    return advancedColors;
  }

  getComputedColors(known_colorNames: Array<string> = null) : Array<string>
  {
    const fn_getComputedStyle = window.getComputedStyle;
    const computedStyle = fn_getComputedStyle(this.document.documentElement);
    let colorArray: Array<any> = [];

    colorArray = this.getComputedCSSVars().filter((varName: any) => {
      const value = (computedStyle.getPropertyValue(varName)+"").trim();
      if(this.exclude.filter(word =>{return varName.includes(word)}).length == 0
         && this.include.filter(word =>{return value.includes(word)}).length > 0
      )
      {
        return varName;
      }
    });
    if(known_colorNames != null)
    {
      colorArray = colorArray.filter(varName => {
        if(known_colorNames.filter(known_colorName =>{return varName.includes(known_colorName)}).length > 0)
        {
          return varName
        }
      });
    }
    colorArray = colorArray.map(varName =>{
      const value = (computedStyle.getPropertyValue(varName)+"").trim();
      const color = `${varName}:${value}`;
      // advancedColors = advancedColors.concat(this.generateAdvancedColor(varName,value));
      return color;
    })
    return colorArray;
  }

  // color blend shade percent lighten or darken  https://stackoverflow.com/questions/5560248/programmatically-lighten-or-darken-a-hex-color-or-rgb-and-blend-colors
  blendColors(c0: string, c1: string, p: number) : string {
    const f = parseInt(c0.slice(1),16), t = parseInt(c1.slice(1),16), R1 = f>>16, G1 = f>>8&0x00FF, B1 = f&0x0000FF, R2 = t>>16, G2 = t>>8&0x00FF, B2 = t&0x0000FF;
    return "#"+(0x1000000+(Math.round((R2-R1)*p)+R1)*0x10000+(Math.round((G2-G1)*p)+G1)*0x100+(Math.round((B2-B1)*p)+B1)).toString(16).slice(1);
  }
  
  generateAdvancedColor(colorName: string, colorValue:string) : Array<string>
  {
    const levels: Array<number> = [
      -0.9, -0.8 ,-0.7 ,-0.6, -0.5 , -0.4, -0.3, -0.2, -0.1, -0.05 , -0.03 , - 0.02, 0.02, 0.03, 0.05, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9
    ]
    const advancedColors: Array<string> = [];
    levels.forEach(level => {
      // const advanced_color = `${colorName}-${level < 0 ? 'darken' : 'lighten'}-${Math.abs(level*100)}: ${pSBC(level,colorValue)}`; //rgb(114,93,20)
      const shade_color2 = level < 0 ? "#000000": "#FFFFFF"//this.blendColors("#FFFFFF", "#FFFFFF",0.2);
      const advanced_color = `${colorName}-${level < 0 ? 'darken' : 'lighten'}-${Math.abs(level*100)}: ${this.blendColors(colorValue, shade_color2,level < 0 ? level*-1: level)}   `; //rgb(114,93,20)

      advancedColors.push(advanced_color);
    });  
    return advancedColors; 
  }

  cssText (cssSelector, stylesArr = null)  {
    const ele = document.querySelector(cssSelector);
    const filtered = Object.entries(getComputedStyle(ele))
    .filter(([k]) => stylesArr?.includes(k) ?? true)
    .map(([k, v]) => {
      let st = `${k}:${v}`
      st = v
      return st;
    });

    // console.log("test5 ",filtered )
    return Object.entries(getComputedStyle(ele))
      .filter(([k]) => stylesArr?.includes(k) ?? true)
      .map(([k, v]) => `${k}:${v}`)
      .join(";");
  }

  // Minimize readed CSS 
  private minimizeData( _content ) 
  {
    let content = _content;
    content = content.replace( /(\/\*.*\*\/)|(\n|\r)+|\t*/g, '' );
    content = content.replace( /\s{2,}/g, ' ' );
    content = content.replace( /(\s)*:(\s)*/g, ':' );
    content = content.replace( /(\s)+\./g, ' .' );
    content = content.replace( /(\s|\n|\r)*\{(\s|\n|\r)*/g, '{' );
    content = content.replace( /(\s|\n|\r)*\}(\s|\n|\r)*/g, '}' );
    content = content.replace( /;(\s)+/g, ';' );
    content = content.replace( /,(\s)+/g, ',' );
    content = content.replace( /(\s)+!/g, '!' );
    return content;
  }
}
