// Typedefintion / Class / Interface
interface FileSettings
{
    server_ip: string,
    http_port: number,
    client_type: string,
    prefix: string
}
const fileSettingsInit : FileSettings = {
    server_ip: "localhost",
    http_port: 4201,
    client_type: "mobile",
    prefix: "http"
}
// Abstimmung
export interface Abstimmung{
    id?: number,
    einheit_id: number,
    versammlung_id: number,
    tagesordnungspunkt_id: number,
    timestamp: string,
    abstimmung: number
}

// Tagesordnungspunkte
// Tagesordnungspunkte
export interface Tagesordnungspunkt_Gob
{
  tagesordnungspunkt_id : number;
  versammlung_id : number;
  ist_gob_von : number,
  rang : number;
  rang_unique : number;
  beschlussgegenstand : string;
  beschlussvorschlag : string;
  erlaeuterung : string;
  name : string;
  bezeichnung : string;
  qualifizierungstyp : any;
  gemeinschaftstypen : Array<number>;
  geschaeftsordnungsbeschluss : string;
  protokollnotiz : string;
  dauer : number;
  abstimmung_beendet : number;
  abstimmung_gestartet_timestamp : string;
  abstimmung_verkuendet_timestamp : string;
  verkuendungsErgebnis : string;
  active : number;
}

export interface Tagesordnungspunkt
{
  tagesordnungspunkt_id : number;
  versammlung_id : number;
  ist_unterpunkt : number;
  rang : number;
  rang_unique : number;
  beschlussgegenstand : string;
  name : string;
  bezeichnung : string;
  abstimmung_erforderlich : number;
  qualifizierungstyp : Qualifizierungskonfiguration;
  gemeinschaftstypen : Array<number>;
  dauer : number;
  erlaeuterung : string;
  beschlussvorschlag : string;
  geschaeftsordnungsbeschluss : string;
  protokollnotiz : string;
  abstimmung_gestartet_timestamp : string;
  abstimmung_beendet : number;
  abstimmung_verkuendet_timestamp : string;
  verkuendungsErgebnis : string;
  active : number;
  mea? : number;
  objekt_name? : string;
  objekt_id? : number;
  gemeinschaftstyp_name? : string;
  anz_einheiten? : number;
  anz_teilnehmer? : number;
}

const Tagesordnungspunkte_EXAMPLE_ROW : Tagesordnungspunkt = 
{
    tagesordnungspunkt_id : 1, versammlung_id : 1, ist_unterpunkt: 0,
    rang : 1 , rang_unique : 1 , beschlussgegenstand : 'kein Beschlussgegenstand' , name : '', bezeichnung : '', abstimmung_erforderlich : 0 ,
    qualifizierungstyp : {id:0, stimmgeber:{s1:{key:"stimmgeber_mea",schwellwert:50, relation: 1} , s2:{key:"stimmgeber_eh",schwellwert:50, relation: 1}}} , gemeinschaftstypen : [99] , dauer : 520 , erlaeuterung : '' , beschlussvorschlag : '' , geschaeftsordnungsbeschluss : ' ' ,
    protokollnotiz : ' ' , abstimmung_gestartet_timestamp : '' , abstimmung_beendet : 0 , abstimmung_verkuendet_timestamp : '' , verkuendungsErgebnis : '' , active : 1,
    mea : 1333 , objekt_name : 'Objekt Name' , objekt_id : 1002 , gemeinschaftstyp_name : 'Gemeinschaftstyp'
}

export interface Import_Gemeinschaftstyp{
    id: number,
    name: string,
    active?: number
}

export interface Einheit{
    einheit_id?: number,
    einheit_bez: string,
    teilnehmer_id: number,
    objekt_id: number,
    versammlung_id: number,
    name: string,
    gemeinschaftstyp_id: number,
    anz_stimmen: number,
    active? : number
  }

interface Qualifizierungskonfiguration
{
    id: number,
    name?: string,
    stimmgeber: StimmgeberObjekt
}

interface StimmgeberObjekt
{
    s1: Stimmgeber,
    s2: Stimmgeber
}
interface Stimmgeber
{
    key: string,
    schwellwert: number,
    relation: number
}


interface TopVorlagenVerwaltung
{
    benutzer_id : number;
    t_vorlage_top_id : number;
    name : string;
    active : number;
    benutzer_vorname? : string;
    benutzer_nachname? : string;
    benutzer_email? : string;
    vorlage_tagesordnungspunkt? : any;
}
const TopVorlagenVerwaltung_EXAMPLE_ROW : TopVorlagenVerwaltung = 
{
    benutzer_id : 1, t_vorlage_top_id : 1, name: '', active : 1,
    benutzer_vorname : "" , benutzer_nachname : "" , benutzer_email : 'kein Email' , vorlage_tagesordnungspunkt : {}
}
  
export {
    FileSettings, fileSettingsInit,
    Tagesordnungspunkte_EXAMPLE_ROW,
    Tagesordnungspunkt as Tagesordnungspunkte,
    TopVorlagenVerwaltung_EXAMPLE_ROW,
    TopVorlagenVerwaltung,
    Qualifizierungskonfiguration
}