<!-- Neu -->
<ng-container *ngIf="sambaService?.displayVideoConferenceSrc.value == true">
    <div class="row animated--grow-in"> 
        <div class="col col-12 p-0" #sambaContainer>
                <div class="samba-container" #sambaContainer2>
                    <iframe #sambaFrame allowusermedia allow = "microphone *; camera *; autoplay;  display-capture;"  
                        frameborder="0" allowfullscreen
                        src = ""
                        class="iframe-samba"
                    >
                    </iframe>
                </div>
        </div>
    </div>
</ng-container>

