<div class="modal-header">
    <div class="modal-title">{{header}}</div>
    <!-- <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="x_function()">
      <span aria-hidden="true">&times;</span>
    </button> -->
</div>
<div class="modal-body angular-with-newlines2">
    <div class="row">
        <!-- Linke Spalte -->
        <div class="col col-12 " [innerHTML]="modalData.modalBody">
        </div>
    </div>
</div>

<div class="modal-footer">
  <ng-container *ngIf="ok_btn">
      <button type="button" ngbAutoFocus class="btn btn-abstimmung-bestaetigen" (click)="ok_function()">
      Bestätigen
      <i class="fas fa-chevron-right btn-icon"></i>
    </button>
  </ng-container>

  <button type="button" class="btn btn-abstimmung-aendern" (click)="cancel_function()">
    <i class="fas fa-times-circle btn-icon"></i>
    Schließen
  </button>
</div>