/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-inner-declarations */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable no-prototype-builtins */
import { Component, OnInit, OnDestroy,  AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { abstimmungsTexteEnum, DataExchangeService } from 'src/app/_services/data-exchange.service';
import { SocketService } from 'src/app/_services/socket.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import { StandardModalComponent } from '../../modals/modals.component';
import { TopbarComponent } from '../../topbar/topbar.component';
import { LoggerService } from 'src/app/services/logger.service';
import { abstimmung_next_commands, e_command_enum, e_event_emitter_enum } from 'src/app/_models/enum';
import { use_abstimmung_greater } from 'src/app/_services/globals.service';
import {EventEmitterService} from '../../../services/event-emitter.service';
import {AuthenticationService} from '../../../_services/authentication.service';
import {AlertService} from '../../../_services/alert.service';
import {VertretungenService} from '../../../services/vertretungen.service';
import {SambaService} from "../../../services/samba.service";
import {NewalertService} from "../../../_services/newalert.service";

declare let HelperFunctions: any;

@Component({
  selector: 'app-abstimmung-gob',
  templateUrl: './abstimmung-gob.component.html',
  styleUrls: ['./abstimmung-gob.component.scss']
})
export class AbstimmungGOBComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('abstimmenText',{static: false}) abstimmenText: ElementRef;

  // Public Vars
  public abstimmungsTexteEnum: any;
  public anweisungstext = `<b>Bitte wählen Sie die Einheiten aus für die Sie zunächst Absitmmen möchten.</b>`;
  public anweisungstext2 = `Nachdem Sie für die ausgewählten Einheiten abgestimmt haben, können Sie für die verbleibenden Einheiten separat abstimmen.`;
  public currentUser: any;
  public buttons: any = {logout: false};
  public selectedVersammlung: any = { text: ''};
  public selectedTagesordnungspunkt: any;
  public tagesordnungspunkt_GOB: any = {};
  public abstimmungen : any;
  public einheiten : any;
  public gemeinschaftstypen : any;
  public einheit_name : any = "";
  public panelOpenState: any = false;
  public tagesordnungspunkt_string: any;
  public tagesordnungspunkt_gob_string: any;
  public vertreterText : any = '';
  public einheitenSelectiert = false;
  private calledOnce  = false;
  public showAbgestimmt = false;
  public versammlungFertigAbgestimmt = false;
  public is_unloaded  = false;
  public vertreterdaten : any = {vertreter: []}; //vertreter , vertretereinheiten
  public texte = {
    abstimmen_text1: `Bitte stimmen Sie nun für Ihre Einheiten ab:`,
    abstimmen_text11: `Bitte stimmen Sie nun für die Einheiten der Teilnehmer ab, welche Sie vertreten:`,
    abstimmen_text2: `Bitte wählen Sie die Einheit ab, über welche schon abgestimmt wurde:`,
    abstimmen_text3: `Bitte wählen Sie noch nicht abgestimmte Einheiten aus:`,
    abstimmen_text4: `Alle Einheiten wurden abgestimmt, bitte warten Sie auf die Abhandlung des nächsten Tagesordnugnspunktes oder beenden der Versammlung.`,
    abstimmen_label : ``
  }
  public dbsettings : any = {};
  public _use_abstimmung_greater = -2;
  private abstimmungSubmitModalRef : MatDialogRef<StandardModalComponent, any> = null;

  // Private Vars
  private eventEmitterSubscription: Subscription = null;
  private getSelectedVersammlungFromDexListener: Subscription = null;
  private getAbstimmungListener: Subscription = null;
  private vertretungenListener: Subscription = null;
  private handleGeschaeftsordnungsbeschlussListener: Subscription = null;

  constructor(
    public dex: DataExchangeService,
    private _socketService: SocketService,
    private authenticationService: AuthenticationService,
    private router: Router,
    public dialog: MatDialog,
    public topbar: TopbarComponent,
    private alertService: AlertService,
    private eventEmitterService: EventEmitterService,
    private vertretungenService: VertretungenService,
    private logger: LoggerService,
    public sambaService: SambaService,
    private newalertService: NewalertService)
  {
    this._use_abstimmung_greater = use_abstimmung_greater;
    this.abstimmungsTexteEnum = abstimmungsTexteEnum
    this.selectedTagesordnungspunkt = {
      beschlussgegenstand: ''
    }
    this.texte.abstimmen_label = this.texte.abstimmen_text1;
    this.showAbgestimmt = false;
  }

  // Angular Lifecycle Hooks
  ngOnDestroy(): void
  {
    this.is_unloaded = true;
    this.eventEmitterSubscription != null ? this.eventEmitterSubscription.unsubscribe() : null;
    this.getSelectedVersammlungFromDexListener != null ? this.getSelectedVersammlungFromDexListener.unsubscribe() : null;
    this.getAbstimmungListener != null ? this.getAbstimmungListener.unsubscribe() : null;
    this.vertretungenListener != null ? this.vertretungenListener.unsubscribe() : null;
    this.handleGeschaeftsordnungsbeschlussListener != null ? this.handleGeschaeftsordnungsbeschlussListener.unsubscribe() : null;
    this.abstimmungSubmitModalRef !== null ? this.abstimmungSubmitModalRef.close() : null;
    this.calledOnce === false;
    this.dex.stopRefreshTimer();
  }

  ngAfterViewInit(): void
  {
    this.dex.startRefreshTimer();
  }

  ngOnInit(): void
  {
    this.currentUser = this.authenticationService.currentUserValue;
    this.initListener();
    if(this.currentUser)
    {
      this.initListenerLater();
      this.loadDataFromSocket();
    }
  }

  // Class Functions
  loadDataFromSocket()
  {
    this.dex.querySelectedVersammlung("abstimmung_gob")
  }

  loadDataFromSocketLater()
  {
    // if(this.calledOnce === false)
    // {
    //
    //   const parameters = {
    //     cmd: "querySelectedTagesordnungspunkt",
    //     versammlung_id: this.selectedVersammlung.versammlung_id,
    //     teilnehmer_id: this.currentUser.teilnehmer_id,
    //     active: true,
    //     getVertreterDaten: true,
    //     onlyPreview: false,
    //     gob: true
    //   };
    //   this.logger.info(this.selectedTagesordnungspunkt, "[loadDataFromSocketLater] loadDataFromSocketLater")
    //   this.logger.info(parameters);
    //   this._socketService.querySelectedTagesordnungspunkt(parameters);
    //   this.calledOnce = true;
    // }
    this.onReadedVersammlung();
  }

  initListenerLater()
  {
      this.vertretungenListener != null ? this.vertretungenListener.unsubscribe() : null;
      this.vertretungenListener = this.vertretungenService.vertretungenResponse$.subscribe( data => {
        // console.log("@@@@@@@@@@@@ [Abstimmung-GOB] vertretungenService.vertretungenResponse$",data);
        if(data != null && data.hasOwnProperty("submitted") && data.submitted.hasOwnProperty("versammlung_id") && data.submitted.versammlung_id === this.selectedVersammlung.versammlung_id)
        {
          this.calledOnce = false;
          this.abstimmungSubmitModalRef != null && this.abstimmungSubmitModalRef.getState() === 0 ? this.abstimmungSubmitModalRef.close() : null;
          this.loadDataFromSocketLater();
        }
      });
  }
  initListener()
  {
    this.handleGeschaeftsordnungsbeschlussListener != null ? this.handleGeschaeftsordnungsbeschlussListener.unsubscribe() : null;
    this.handleGeschaeftsordnungsbeschlussListener = this._socketService.response_handleGeschaeftsordnungsbeschluss().subscribe( async (data : any )=>{
      try
      {
        if(HelperFunctions.Misc.isJson2(data) == 'string')
        {
          data = JSON.parse(data);
          const cmd = data.submitted.cmd;
          if(data.response)
          {
            this.logger.info(data, "[handleGeschaeftsordnungsbeschlussListener]");

            if(cmd === e_command_enum.getTagesordnungspunkt_GOB)
            {
              this.getTagesordnungspunktHandler(data.response);
              this.alertService.clear()
              if(data.response.einheiten?.length === 0 && data.response.vertreterdaten?.vertretereinheiten?.length === 0 && data.response.einzel_teilnehmer.einheiten?.length === 0) {
                this.alertService.error(this.dex.error.keine_einheiten);
                this.alertService.clearAfterTime(10000);
              }
            }
          }
        }
      } catch (error) {        this.logger.error(error);      }
    });

    // Event Emitter
    this.eventEmitterSubscription != null ? this.eventEmitterSubscription.unsubscribe() : null;
    this.eventEmitterSubscription = this.eventEmitterService.eventEmitter.subscribe(msg => {
      try
      {
        if(msg.hasOwnProperty("cmd"))
        {
          const cmd = msg.cmd;
          if(cmd === e_event_emitter_enum.connect)
          {
            this.logger.info(msg, "Connect")
          }
          if(cmd === e_event_emitter_enum.disconnect)
          {
            this.logger.info(msg, "Disconnect")
          }
          if(cmd === e_event_emitter_enum.tn_vertritt_anderen_tn)
          {
            this.logger.info(msg, "[EM] tn_vertritt_anderen_tn ");
            this.calledOnce = false;
            this.loadDataFromSocketLater();
          }
          if(cmd === e_event_emitter_enum.abstimmung_gob)
          {
            this.logger.info(msg, "[EM] abstimmung_gob querySelectedVersammlung");
            this.onReadedVersammlung();
          }
        }
      }catch(e){this.logger.error(e);}

    });

    // getSelectedVersammlung
    this.getSelectedVersammlungFromDexListener != null ? this.getSelectedVersammlungFromDexListener.unsubscribe() : null;
    this.getSelectedVersammlungFromDexListener = this.dex.currentSelectedVersammlung.subscribe( async (data : any )=>{
    try
    {
      if(data=== null || data === '' || data ==='undefined' || Object.keys(data).length == 0)
      {
        this.dex.querySelectedVersammlung("from abstimmung gob");
      }
      else
      {
        this.selectedVersammlung = data;
        this.logger.info(data, "[abstimmung-gob.component] getSelectedVersammlungFromDexListener ");
      }
    }
    catch(e){this.logger.error(e);}
    });

    // callback from Abstimmen click
    this.getAbstimmungListener != null ? this.getAbstimmungListener.unsubscribe() : null;
    this.getAbstimmungListener = this._socketService.response_abstimmung().subscribe( (data: any ) => {
      try
      {
        if(HelperFunctions.Misc.isJson2(data) == 'string')
        {
          data = JSON.parse(data);
          const submitted = data.submitted;
          const response = data.response;
          if(response.hasOwnProperty("error"))
          {
            this.logger.error(response, "Abstimmung Error !!!!!");
            this.openAbstimmungUnzulaessigModal(response);
          }
          else
          {
            this.newalertService.success('Die Abstimmung wurde erfolgreich übermittelt');

            if(submitted.cmd === e_command_enum.multiAbstimmung)
            {
              let einheiten_ = this.einheiten;
              if(this.vertreterdaten.hasOwnProperty("vertretereinheiten") && this.vertreterdaten.vertretereinheiten.length > 0)
              {
                einheiten_ = einheiten_.concat(this.vertreterdaten.vertretereinheiten);
              }
              for(let i = 0 ; i < response.length; i++)
              {
                let abstimmungFromCallback : any = [];
                if(response[i].hasOwnProperty("update")){abstimmungFromCallback = response[i].update[0];}
                if(response[i].hasOwnProperty("insert")){abstimmungFromCallback = response[i].insert[0];}
                // let einheit = this.einheiten.filter(eh => {return eh.einheit_id === abstimmungFromCallback.einheit_id})
                let einheit: any = [];
                if(this.einheiten.filter(eh => {return eh.einheit_id === abstimmungFromCallback.einheit_id}).length > 0 )
                {
                  einheit = this.einheiten.filter(eh => {return eh.einheit_id === abstimmungFromCallback.einheit_id});
                }
                else
                {
                  if(this.vertreterdaten.hasOwnProperty("vertretereinheiten") && this.vertreterdaten.vertretereinheiten.filter(eh => {return eh.einheit_id === abstimmungFromCallback.einheit_id}).length > 0)
                  {
                    einheit = this.vertreterdaten.vertretereinheiten.filter(eh => {return eh.einheit_id === abstimmungFromCallback.einheit_id});
                  }
                }
                if(einheit.length > 0 )
                {
                  einheit = einheit[0];
                  const abgestimmt = this.convertAbstimmung(abstimmungFromCallback.abstimmung, "toText");
                  einheit.abstimmungsText = abgestimmt;
                }
              }
            }
          }

          const istAuchVertreter : boolean = this.vertreterdaten.hasOwnProperty("vertretereinheiten") && this.vertreterdaten.vertretereinheiten.length > 0;
          this.checkFertigAbgestimmt(istAuchVertreter) ;
          // podcast
          this.removeCheckBoxSelection(istAuchVertreter);
          this.logger.info(submitted.cmd)
          this.logger.info(data)
          this.logger.info(istAuchVertreter, "istAuchVertreter")
        }
      }
      catch(e)      {        this.logger.error(e);      }
     });

  }

  onReadedVersammlung()
  {
    try
    {
      let navigateCommand: any = (this.selectedVersammlung.navigateClient);
      if(HelperFunctions.Misc.isJson2(this.selectedVersammlung.navigateClient) == 'string')
      {
        navigateCommand = JSON.parse(this.selectedVersammlung.navigateClient);
      }
      else
      {
        this.logger.warning(navigateCommand, "[onReadedVersammlung] NavigateCommand is not a parseable String, maybe its already an JSON object");
      }

      this.logger.info(navigateCommand, "[onReadedVersammlung] navigate command")
      if(
          navigateCommand.hasOwnProperty("site") &&
          navigateCommand.site != 2 &&
          navigateCommand.site != 7 &&
          navigateCommand.site != 6 &&
          navigateCommand.site === 31
      )
      {
        const parameters =
            {
              cmd: "getTagesordnungspunkt_GOB",
              data: {
                tagesordnungspunkt_id : navigateCommand.data.tagesordnungspunkt.tagesordnungspunkt_id,
                versammlung_id : navigateCommand.data.versammlung_id,
                getVertreterDaten: true,
                teilnehmer_id : this.currentUser.teilnehmer_id
              }
            }
        if(this.calledOnce === false)
        {
          this.calledOnce = true;
          this.logger.info(parameters, "[handleGeschaeftsordnungsbeschluss]");
          this._socketService.handleGeschaeftsordnungsbeschluss(parameters);
        }
      }
      else
      {
        this.logger.warning("########[onReadedVersammlung] no matching navigation command");
      }
    } catch (error)     {      this.logger.error(error);    }
  }


  getTagesordnungspunktHandler(data)
  {
    const self = this;
    if(data && data.code && data.hasOwnProperty("tagesordnungspunkt_gob") && data.tagesordnungspunkt_gob != undefined)
    {
      switch(data.code)
      {
        case -1:
          // Kein Tagesordnungspunkt durch das Dashboard angewählt
        break;
        case 0:
          // Teilnehmer besitzt keine Einheiten
        break;
        case 1:
          // Valide, es liegen bereits Abstimmungen vor
          processData(data);
        break;
        case 2:
          // Teilnehmer hat noch keine Abstimmung getätigt
          processData(data);
        break;
        case 3:
        break;
      }

      function processData(data)
      {
        self.logger.info(data, "[abstimmung-gob.component] Process Data");
        try
        {
          // -2 -3 fake Abstimmungen entfernen
          data.abstimmungen =  HelperFunctions.Sorting.filterAbstimmungen(data.abstimmungen , self._use_abstimmung_greater);
          data.tagesordnungspunkt = data.tagesordnungspunkt[0];
          data.tagesordnungspunkt_gob = data.tagesordnungspunkt_gob[0];
          data.tagesordnungsoberpunkt ? data.tagesordnungsoberpunkt = data.tagesordnungsoberpunkt[0]: null;
          self.gemeinschaftstypen = data.gemeinschaftstypen;
          self.einheiten = data.einheiten;
            self.einheiten = data.einzel_teilnehmer.einheiten;
          self.abstimmungen = data.abstimmungen;
          self.vertreterdaten = data.hasOwnProperty("vertreterdaten") && data.vertreterdaten ? data.vertreterdaten : {vertreter : []} ;
          self.selectedTagesordnungspunkt = data.tagesordnungspunkt;
          self.tagesordnungspunkt_GOB = data.tagesordnungspunkt_gob;
          self.tagesordnungspunkt_GOB.abstimmungen = self.abstimmungen;

          // Handle Vertreter Daten if Present
          if(data.hasOwnProperty("vertreterdaten") && data.vertreterdaten.hasOwnProperty("vertreter") &&
             data.vertreterdaten.vertreter.length > 0)
          {
            for(let v = 0; v < self.vertreterdaten.vertreter.length ; v++)
            {
              const vtreter = self.vertreterdaten.vertreter[v];
              vtreter.einheiten = self.vertreterdaten.vertretereinheiten.filter(e => {return e.teilnehmer_id === vtreter.teilnehmer_id});
              if(vtreter.einheiten.length > 0)
              {
                for(let e = 0; e < vtreter.einheiten.length; e++)
                {
                  const eh = vtreter.einheiten[e];
                  eh.checked = false;
                  eh.abstimmungsText = self.getAbstimmungsText(eh.einheit_id);
                }
                // self.checkFertigAbgestimmt();
              }
              if( vtreter.vertreter_vorname != '' && vtreter.vertreter_nachname != '')
              {
                vtreter.vertreterText = `${vtreter.vertreter_vorname} ${vtreter.vertreter_nachname}`;
              }
            }
            // set all einheiten.checked to false (checkbox selection)
            for(let i = 0; i< self.vertreterdaten.vertretereinheiten.length; i++)
            {
              self.vertreterdaten.vertretereinheiten[i].checked = self.getMehrheitsprinzipRelevanteDaten().checked;
              self.vertreterdaten.vertretereinheiten[i].disable_selection = self.getMehrheitsprinzipRelevanteDaten().disable_selection;
              self.vertreterdaten.vertretereinheiten[i].kopfprinzip = self.getMehrheitsprinzipRelevanteDaten().kopfprinzip;
              self.vertreterdaten.vertretereinheiten[i].abstimmungsText = self.getAbstimmungsText(self.vertreterdaten.vertretereinheiten[i].einheit_id);
            }
          }

          self.logger.info(self.vertreterdaten, "[abstimmung-gob.component] self.vertreterdaten");
          // set all einheiten.checked to false (checkbox selection)
          for(let i = 0; i< self.einheiten.length; i++)
          {
            self.einheiten[i].checked = self.getMehrheitsprinzipRelevanteDaten().checked;
            self.einheiten[i].disable_selection = self.getMehrheitsprinzipRelevanteDaten().disable_selection;
            self.einheiten[i].kopfprinzip = self.getMehrheitsprinzipRelevanteDaten().kopfprinzip;
            self.einheiten[i].abstimmungsText = self.getAbstimmungsText(self.einheiten[i].einheit_id);
          }
          const istAuchVertreter: boolean = data && data.hasOwnProperty("vertreterdaten") && data.vertreterdaten.hasOwnProperty("vertreter") && data.vertreterdaten.vertreter.length > 0;

          // check if user has vertreter
          if( self.currentUser.vertreter_vorname != '' && self.currentUser.vertreter_nachname != '')
          {
            self.vertreterText = `${self.currentUser.vertreter_vorname} ${self.currentUser.vertreter_nachname}`;
          }
          // code 1: abstimmung starten  2: die Abstimmung ist nun beendet 3: es wurde bereits abgestimmt
          if(data.code === 1 || data.code === 2)
          {
            try
            {
              self.einheit_name = self.gemeinschaftstypen.filter(gm => { return self.tagesordnungspunkt_GOB.gemeinschaftstypen.includes(gm.id)})[0].name;
            }
            catch(e){self.logger.error(e);}
          }
          self.tagesordnungspunkt_string = self.selectedTagesordnungspunkt.rang_unique;
          self.tagesordnungspunkt_gob_string = self.tagesordnungspunkt_GOB.rang_unique;
          // GOB Tagesordnungspunkte haben keine unterpunkte (bzw können kein Unterpunkt sein)
          // Podcast
          if(self.selectedTagesordnungspunkt.ist_unterpunkt > 0)
          {
            self.tagesordnungspunkt_string = data.tagesordnungsoberpunkt.rang_unique+"."+self.selectedTagesordnungspunkt.rang_unique;
          }
          self.checkFertigAbgestimmt(istAuchVertreter);

          // 0 - Einfache Mehrheit | 1 - Qualifizierte Mehrheit | 2 - Doppelte Qualifizierung | 3 - Doppelte Qualifizierung (Spezial) |
          self.anweisungstext = self.showAbgestimmt === false ? `<b>Bitte stimmen Sie ab</b>` : `<b>Sie haben bereits abgestimmt</b>`;
          self.anweisungstext2 = self.showAbgestimmt === false ? `Nachdem Sie für die ausgewählten Einheiten abgestimmt haben, können Sie für die verbleibenden Einheiten separat abstimmen.` : `Sie können die Abstimmung jedoch noch ändern.`;

        }catch(e){self.logger.error(e)}
      }
    }else{
      console.log("[abstimmungGOB] no tagesordnungspunkt_gob found",data);
    }
  }

  getMehrheitsprinzipRelevanteDaten()
  {
    const data = {checked: false, disable_selection: false, kopfprinzip: false}
    try
    {
      // 0 - Einfache Mehrheit | 1 - Qualifizierte Mehrheit | 2 - Doppelte Qualifizierung | 3 - Doppelte Qualifizierung (Spezial) |
      /*
      switch(this.tagesordnungspunkt_GOB.qualifizierungstyp.id)
      {
        case 0:
        break;
        case 1:
          // data.checked = true;
          // data.disable_selection = true;
          // setTimeout(() => {
          //   this.checkEinheitSelektiert();
          // }, 10);
          data.kopfprinzip = true;
        break;
        case 2:
          // data.checked = true;
          // data.disable_selection = true;
          // setTimeout(() => {
          //   this.checkEinheitSelektiert();
          // }, 10);
          data.kopfprinzip = true;
        break;
        case 3:
        break;
      }
      */
      data.kopfprinzip = this.dex.qt_helper.istKopfprinzip(this.tagesordnungspunkt_GOB.qualifizierungstyp)

    }catch(e){this.logger.error(e)}

    return data;
  }

  removeCheckBoxSelection(vertreterabstimmung)
  {
    if(vertreterabstimmung == false)
    {
      for(let e = 0 ; e < this.einheiten.length; e++)
      {
        const einheit = this.einheiten[e];
        einheit.checked = false;
      }
    }
    if(vertreterabstimmung == true)
    {
      for(let e = 0 ; e < this.einheiten.length; e++)
      {
        const einheit = this.einheiten[e];
        einheit.checked = false;
      }
      for(let e = 0 ; e < this.vertreterdaten.vertretereinheiten.length; e++)
      {
        const einheit =  this.vertreterdaten.vertretereinheiten[e];
        einheit.checked = false;
      }
    }

    this.einheitenSelectiert = false;
  }

  checkFertigAbgestimmt(vertreterabstimmung)
  {
    let fertig = 0;
    // Falls es keine Einheiten mehr zum Abstimmen gibt, aber generell Einheiten vorhanden sind
    // if(vertreterabstimmung === false)
    // {

    if(this.getCountOfAbzustimmendeEinheiten(vertreterabstimmung) == 0 && this.einheiten.length > 0)
    {
      // this.versammlungFertigAbgestimmt = true;
      // this.showAbgestimmt = true;
      // this.texte.abstimmen_label = this.texte.abstimmen_label = this.texte.abstimmen_text4;
      fertig++;
    }
    else
    {
      if(this.einheiten.length === 0)
      {
        fertig++;
      }
    }
    // }
    if(vertreterabstimmung === true)
    {
      if(this.getCountOfAbzustimmendeEinheiten(vertreterabstimmung) == 0 &&
         this.vertreterdaten.vertretereinheiten.length > 0
      )
      {
        fertig++;
      }
    }

    this.showAbgestimmt = false;
    this.texte.abstimmen_label =  this.texte.abstimmen_text1;
    if(vertreterabstimmung === true)
    {
      if(fertig === 2)
      {
        this.showAbgestimmt = true;
        this.texte.abstimmen_label = this.texte.abstimmen_label = this.texte.abstimmen_text4;
      }
    }
    if(vertreterabstimmung === false)
    {
      if(fertig === 1)
      {
        this.showAbgestimmt = true;
        this.texte.abstimmen_label = this.texte.abstimmen_label = this.texte.abstimmen_text4;
      }
    }
    if(this.showAbgestimmt === true)
    {
      setTimeout(() => {

        if(this.is_unloaded == false)
        {
          let cmd_ = abstimmung_next_commands.showVielenDankFuerDieAbstimmung;
          if(Object.keys(this.einheiten).length == 0)
          {
            if(this.vertreterdaten && this.vertreterdaten.hasOwnProperty("vertretereinheiten") && Object.keys(this.vertreterdaten.vertretereinheiten).length == 0)
            {
              cmd_ = abstimmung_next_commands.showSieHabenKeineEinheiten;
            }
          }
          // VUL-45
          // this.navigateToAbstimmungFertigIfNecessary(cmd_);
        }
      }, 3000);
    }
  }
  getCountOfAbzustimmendeEinheiten(vertreterabstimmung)
  {
    let nochEinheitenDa = 0;
    if(vertreterabstimmung === false)
    {
      for(let i = 0; i< this.einheiten.length; i++)
      {
        if(this.einheiten[i].abstimmungsText == abstimmungsTexteEnum.keineAbstimmung)
        {
          nochEinheitenDa++;
        }
      }
    }

    if(vertreterabstimmung === true)
    {
      for(let i = 0; i < this.vertreterdaten.vertretereinheiten.length; i++)
      {
        if(this.vertreterdaten.vertretereinheiten[i].abstimmungsText == abstimmungsTexteEnum.keineAbstimmung)
        {
          nochEinheitenDa++;
        }
      }
    }
    return nochEinheitenDa;
  }

  // async getTeilnehmerHandler(data)
  // {
  // }

  logout()
  {
  }
  einheitenCheck(event: MouseEvent)
  {
  }
  selectEinheit(row, alsVertreter = false ,  forceSelection = 0)
  {
    const click_von_checkbox_selbst = false;
    // event === false ? click_von_checkbox_selbst = true: null;
    setTimeout(() => {
      let einheit: any = [];
      let einheiten_ :any = [];
      let new_check_value : boolean = forceSelection == 1 ? true : forceSelection == -1 ? false : false;
      // Fülle die lokalen Einheiten abhängig davon ob vom Vertreter oder TN selbst
      if(alsVertreter == true)
      {
        einheit = this.vertreterdaten.vertretereinheiten.filter(e => { return e.einheit_id === row.einheit_id}); einheit = einheit[0];
        einheiten_ = this.vertreterdaten.vertretereinheiten;
      }
      else {
        einheit = this.einheiten.filter(e => { return e.einheit_id === row.einheit_id}); einheit = einheit[0];
        einheiten_ = this.einheiten;
      }

      // checkbox operations
      // VUL-45
      // if(row.abstimmungsText === abstimmungsTexteEnum.keineAbstimmung &&
      //    row.disable_selection === false && row.kopfprinzip === false)
      // {
      if(row.disable_selection === false && row.kopfprinzip === false)
      {
        if(forceSelection === 0)
        {
          einheit.checked === true? new_check_value = false: new_check_value = true;
        }
        einheit.checked = new_check_value;
        for(let e = 0 ; e < einheiten_.length; e++)
        {
          if(einheiten_[e].checked=== true)
          {
            einheiten_[e].abstimmungsText === abstimmungsTexteEnum.keineAbstimmung && einheiten_[e].checked === true? true: false;

          }
        }
        this.checkEinheitSelektiert();
      }

      // VUL-45 if(row.abstimmungsText === abstimmungsTexteEnum.keineAbstimmung && row.kopfprinzip === true)
      if(row.kopfprinzip === true)
      {
        // Qualifizierte Mehrheit und Doppelte Qualifizierung
        let einheiten_von_teilnehmer = einheiten_.filter(e => {return e.teilnehmer_id === einheit.teilnehmer_id});
        if(forceSelection === 0)
        {
          einheit.checked === true? new_check_value = false: new_check_value = true;
        }
        einheit.checked = new_check_value;
        // einheit.checked === true? einheit.checked = false: einheit.checked = true;
        einheiten_von_teilnehmer = einheiten_von_teilnehmer.map(eh => {
          eh.checked = einheit.checked;
          return eh;
        })

        this.checkEinheitSelektiert();
      }
    }, 2);
  }

  checkEinheitSelektiert()
  {
    const einheiten_checked = [];
    let gesamt_count = 0;
    if(this.einheiten.length > 0)
    {
      for (let i = 0 ; i < this.einheiten.length; i++)
      {
        if(this.einheiten[i].checked === true)
        {
          einheiten_checked.push(this.einheiten[i]);
        }
        gesamt_count++;
      }
    }
    if(this.vertreterdaten.hasOwnProperty("vertretereinheiten") && this.vertreterdaten.vertretereinheiten.length > 0)
    {
      for (let i = 0 ; i < this.vertreterdaten.vertretereinheiten.length; i++)
      {
        if(this.vertreterdaten.vertretereinheiten[i].checked === true)
        {
          einheiten_checked.push(this.vertreterdaten.vertretereinheiten[i]);
        }
        gesamt_count++;
      }
    }
    if(einheiten_checked.length > 0)
    {
      this.einheitenSelectiert = true;
    }
    else
    {
      this.einheitenSelectiert = false;
    }
  }

  abstimmen(auswahl)
  {
    const data = []; //JSON.parse(JSON.stringify(this.checkboxSelectionEinheiten));
    for (let i = 0 ; i < this.einheiten.length; i++)
    {
      if(this.einheiten[i].checked === true)
      {
        data.push({
            radio: this.convertAbstimmung(auswahl, 'toIndex'),
            vertreterabstimmung: false,
            einheit_id: this.einheiten[i].einheit_id,
            auswahl: auswahl,
            name: this.einheiten[i].name
        });
      }
    }
    if(this.vertreterdaten.hasOwnProperty("vertretereinheiten") && this.vertreterdaten.vertretereinheiten.length > 0)
    {
      for (let i = 0 ; i < this.vertreterdaten.vertretereinheiten.length; i++)
      {
        if(this.vertreterdaten.vertretereinheiten[i].checked === true)
        {
          data.push({
              radio: this.convertAbstimmung(auswahl, 'toIndex'),
              vertreterabstimmung: true,
              einheit_id: this.vertreterdaten.vertretereinheiten[i].einheit_id,
              auswahl: auswahl,
              name: this.vertreterdaten.vertretereinheiten[i].name
          });
        }
      }
    }

    const parameters = {
      cmd:"multiAbstimmung",
      tagesordnungspunkt_id: this.tagesordnungspunkt_GOB.tagesordnungspunkt_id,
      versammlung_id: this.selectedVersammlung.versammlung_id,
      client_type: "client",
      teilnehmer_id : this.currentUser.teilnehmer_id,
      data: data,
      gob: true
    };
    this.openAbstimmModal(parameters);
  }
  openAbstimmModal(data)
  {
    this.logger.info(data, "_data");
    this.abstimmungSubmitModalRef = this.dialog.open(StandardModalComponent,
    {
      width: '85%',
      maxHeight : '94vh',
      data: data,
      autoFocus: false
    });

    this.abstimmungSubmitModalRef.componentInstance.data = data;
    this.abstimmungSubmitModalRef.componentInstance.modalData.modalBody = `<p>Bitte bestätigen Sie folgende Abstimmungen</p>`;
    for(let i = 0; i < data.data.length; i++)
    {
      const eh = data.data[i];
      this.abstimmungSubmitModalRef.componentInstance.modalData.modalBody += `
        <div class="row">
          <div class="col col-12">
            ${(eh.name)} - <strong class="btn-abstimmung-modal-auswahl align-middle">${eh.auswahl[0].toUpperCase()+eh.auswahl.slice(1)}</strong>

          </div>
        </div>
      <!-- ${(eh.name)} - <strong class="btn-abstimmung-modal-auswahl align-middle">${eh.auswahl[0].toUpperCase()+eh.auswahl.slice(1)}</strong></br>
      -->
      `;
    }
    this.abstimmungSubmitModalRef.componentInstance.ok_btn = function(){
      this._socketService.abstimmung(this.data);
      this.x_function();
    };
    this.abstimmungSubmitModalRef.afterClosed().subscribe(result => {
    });
  }

  public selectDeselect(cmd)
  {
    const new_check_value : number = this.einheitenSelectiert === false ? 1 : -1;
    if(cmd === e_command_enum.toggle)
    {
      if(Object.keys(this.einheiten).length > 0)
      {
        for(let i = 0 ; i < this.einheiten.length; i++)
        {
          const einheit = this.einheiten[i];
          this.selectEinheit(einheit, false, new_check_value)
        }
      }
      if(Object.keys(this.vertreterdaten.vertreter).length > 0)
      {
        for(let i = 0 ; i < this.vertreterdaten.vertreter.length; i++)
        {
          const vertreter: any = this.vertreterdaten.vertreter[i];
          if(Object.keys(vertreter.einheiten).length > 0)
          {
            for(let j = 0 ; j < vertreter.einheiten.length; j++)
            {
              const einheit = vertreter.einheiten[j];
              this.selectEinheit(einheit, true , new_check_value)
            }
          }

        }
      }

    }
  }

  openAbstimmungUnzulaessigModal(parameters)
  {
    const data: any = {};
    const modalRef = this.dialog.open(StandardModalComponent,
    {
      width: '75%',
      data: data,
      autoFocus: false
    });
    modalRef.componentInstance.data = data;
    modalRef.componentInstance.header = "Abstimmung unzulässig";
    modalRef.componentInstance.modalData.modalBody = `<p>bitte loggen Sie sich erneut ein.</p>`;
    modalRef.componentInstance.ok_btn = null;
    modalRef.afterClosed().subscribe(result => {
    });

  }

  convertAbstimmung(abs, cmd)
  {
     let erg: any = '';
     if(cmd === e_command_enum.toIndex)
     {
      erg = -2;
      switch(abs)
      {
         case "ja" || "Ja":
           erg = 1;
         break;

         case "nein" || "Nein":
           erg = -1;
         break;

         case "enthaltung" || "Enthaltung":
           erg	= 0;
         break;

         default:
           erg = -2;
         break;
      }
     }
     if(cmd === e_command_enum.toText)
     {
       erg = abstimmungsTexteEnum.keineAbstimmung;
       switch(abs)
       {
          case 0:
            erg = 'Enthaltung';
          break;
          case 1:
            erg = 'Ja';
          break;
          case 2:

          break;
          case -1:
            erg = 'Nein';
          break;
          default:

          break;

       }
     }

     return erg;
  }

  getAbstimmungsText(einheit_id)
  {
    let abstimmung = this.abstimmungen.filter(a => { return a.einheit_id === einheit_id});
    // Keine Abstimmung vorhanden
    if(abstimmung.length < 1) //|| !abstimmung[0].hasOwnProperty(abstimmung))
    {
      abstimmung = [];
      abstimmung.push({abstimmung: this._use_abstimmung_greater});
    }
    const erg = this.convertAbstimmung(abstimmung[0].abstimmung, "toText");
    return erg;
  }

  handleFertigAbgestimmt()
  {
    this.showAbgestimmt = true;
  }

  navigateToAbstimmungFertigIfNecessary(cmd = abstimmung_next_commands.showVielenDankFuerDieAbstimmung)
  {
    const path = (['mobile-client/abstimmung/abstimmung-next',{cmd: cmd }]);
    if(this.showAbgestimmt === true)
    {
      this.router.navigate(path);
    }
  }
}
