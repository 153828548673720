<div class="mt-0">
    <app-alert></app-alert>
</div>


<div class="row d-flex justify-content-center mb-4">
    <div class="col-xs-auto">
        <!-- <p class="h1" style="margin-bottom: 0px;">Erläuterung</p> -->
        <h1 class="h1" style="margin-bottom: 0px;">Erläuterung</h1>
    </div>
</div>

<div class="justify-content-center animated--grow-in justify-content-center row">
<div class="align-self-center" [ngClass]="{'col-12 col-xs-12 col-md-10 col-lg-6 col-xl-4': sambaService?.displayVideoConferenceSrc.value === false, 'col-12': sambaService?.displayVideoConferenceSrc.value === true}">
<ng-container>
    <div class="row d-flex animated--grow-in pl-3 pr-3">
        <div class="col col-12 col-xs-12 col-md-12 col-lg-12 col-xl-12">
            <ul class="list-inline mb-0">
                <li class="list-inline-item">
                    <p class="mb-0">Objekt: </p>
                </li>
                <li class="list-inline-item">
                    <p class="mb-0"><strong>{{this.selectedVersammlung?.objekt_name}} </strong></p>
                </li>
            </ul>
        </div>
    </div>
    <div class="row d-flex justify-content-start animated--grow-in pl-3 pr-3">
        <div class="col col-12 col-xs-12 col-md-12 col-lg-12 col-xl-12">
            <ul class="list-inline mb-0">
                <li class="list-inline-item">
                    <p class="mb-0">Versammlung: </p>
                </li>
                <li class="list-inline-item">
                    <p class="mb-0"><strong>{{this.selectedVersammlung?.name}} </strong></p>
                </li>
            </ul>
        </div>
    </div>

    <div class="row d-flex justify-content-start animated--grow-in pl-3 pr-3">
        <div class="col-md-auto">
            {{selectedTagesordnungspunkt?.ist_gob_von ? "Geschäftsordnungsbeschluss" : "Tagesordnungspunkt"}} <b>{{this.selectedTagesordnungspunkt?.rangText}}</b> {{(selectedTagesordnungspunkt?.abstimmung_erforderlich == 1 ) ? "mit Abstimmung": "ohne Abstimmung"}}
        </div>
        <ng-container *ngIf="selectedTagesordnungspunkt?.abstimmung_erforderlich > 0 || selectedTagesordnungspunkt?.ist_gob_von">
            <div class="col-md-auto">
                Gemeinschaftstyp(en): <b>{{this.selectedTagesordnungspunkt?.gemeinschaftstypen_namen}}</b>
            </div>
            <div class="col-md-auto">
                Qualifizierung: <b>{{this.selectedTagesordnungspunkt?.qualifizierungstyp?.id >= 0? this.dex.top_helper.getStimmgeberNamenByTopId(selectedTagesordnungspunkt , true, false) : "" }} </b>
            </div>
            <div class="col-md-auto">
                {{this.selectedTagesordnungspunkt?.qualifizierungstyp.id == 2 ? "Berücksichtigung 1. Qualifizierung:":"Berücksichtigung:"}} <b>{{this.dex.top_helper.getBeruecksichtigung(selectedTagesordnungspunkt?.qualifizierungstyp , "s1") }}</b>
            </div>
            <ng-container *ngIf="this.selectedTagesordnungspunkt?.qualifizierungstyp.id == 2">
                <div class="col-md-auto">
                    Berücksichtigung 2. Qualifizierung: <b>{{this.dex.top_helper.getBeruecksichtigung(this.selectedTagesordnungspunkt?.qualifizierungstyp , "s2") }}</b>
                </div>
            </ng-container>
        </ng-container>
    </div>
</ng-container>


<!-- Accordion -->
<ng-container>
    <div class="row d-flex justify-content-center mt-3">
        <div class="col col-12 col-xs-12 col-md-12 col-lg-12 col-xl-12 ml-3 ml-lg-0" >
            <mat-accordion>
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" #expansionPanel>
                    <mat-expansion-panel-header class="pl-1 pr-1 text-nowrap no-user-select">
                        <mat-panel-title>
                            <!-- Tagesordnungspunkt: {{tagesordnungspunkt_string}} -->
                            Informationen
                        </mat-panel-title>
                        <mat-panel-description class="">
                            <small>{{panelOpenState ? 'einklappen' : 'ausklappen'}}</small>
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <div class="row">
                        <div class="col col-12 pl-1 pr-1">
                            <p class="no-padding-bottom no-margin-bottom"><strong>Beschlussgegenstand:</strong></p>
                            <div class="pre-wrap">{{selectedTagesordnungspunkt?.beschlussgegenstand}}</div>
                        </div>
                    </div>

                    <div class="row mt-2">
                        <div class="col col-12 pl-1 pr-1">
                            <p class="no-padding-bottom no-margin-bottom"><strong>Erläuterung:</strong></p>
                            <div class="pre-wrap">{{selectedTagesordnungspunkt?.erlaeuterung}}</div>
                        </div>
                    </div>

                    <ng-container *ngIf="selectedTagesordnungspunkt?.beschlussvorschlag != ''">
                        <div class="row mt-2">
                            <div class="col col-12 pl-1 pr-1">
                                <p class="no-padding-bottom no-margin-bottom"><strong>Beschlussvorschlag:</strong></p>
                                <div class="pre-wrap">{{selectedTagesordnungspunkt?.beschlussvorschlag}}</div>
                            </div>
                        </div>
                    </ng-container>

                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
</ng-container>

<ng-container>
    <div class="row d-flex justify-content-center ml-2 mt-4 animated--grow-in"  @openClose>
        <div class="col col-12">
            <div class="d-flex align-items-center justify-content-center">
                <p class="text-legende">
                    {{selectedTagesordnungspunkt?.abstimmung_erforderlich == 1 ? "Dieser Tagesordnungspunkt enthält eine Abstimmung": "Über diesen Tagesordnungspunkt wird nicht abgestimmt."}}
                </p>
            </div>
        </div>
    </div>
</ng-container>
</div>
</div>





