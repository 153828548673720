/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-empty */
/* eslint-disable no-prototype-builtins */
import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { DataExchangeService } from 'src/app/_services/data-exchange.service';
import { SocketService } from 'src/app/_services/socket.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { LoggerService } from 'src/app/services/logger.service';
import { e_command_enum } from 'src/app/_models/enum';
import { Teilnehmer } from 'src/app/_models';
import {callback__VertretungLoeschen, callback__VertretungSpeichern, vertretungCMDs, VertretungenService} from '../../services/vertretungen.service';
import {EventEmitterService} from '../../services/event-emitter.service';
import {AuthenticationService} from '../../_services/authentication.service';
import {TeilnehmerService} from '../../_services/user.service';
import {AlertService} from '../../_services/alert.service';
import {SambaService} from "../../services/samba.service";

declare let HelperFunctions: any;

@Component({
  selector: 'app-lockedscreen',
  templateUrl: './lockedscreen.component.html',
  styleUrls: ['./lockedscreen.component.scss']
})
export class LockedscreenComponent implements OnInit, OnDestroy, AfterViewInit {

    // Listener
    private currentUserListener: Subscription;
    private eventEmitterSubscription: Subscription;
    private getSelectedVersammlungFromDexListener: Subscription;
    private getTeilnehmerListener: Subscription;
    private getSelectedTagesordnungspunktListener: Subscription;
    private getCurrentSettingsFromDbListener: Subscription;
    private vertretungenListener: Subscription;
    // private checkValidAnmeldungListener: Subscription;
    // private querySelectedTagesordnungspunkt: Subscription;

    // Vars
    public routerLinkParameter: any = [];
    public currentUser: Teilnehmer;
    public buttons: any = {logout: false};
    public selectedVersammlung: any = { text: ''};
    public selectedTagesordnungspunkt: any;
    public abstimmungen : any;
    public einheiten : any;
    public gemeinschaftstypen : any;
    public firstListenerInstantiated  = false;
    private settingsFromDb : any = [] ;
    public anmeldungKorrekt  = true;
    public force_stay  = false;


  constructor(
    private dex: DataExchangeService,
    private _socketService: SocketService,
    private authenticationService: AuthenticationService,
    private userService: TeilnehmerService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private logger: LoggerService,
    private vertretungService: VertretungenService,
    private eventEmitterService: EventEmitterService,
    public sambaService: SambaService,
  )
    {

    }

  ngOnDestroy(): void
  {
    this.currentUserListener != undefined ? this.currentUserListener.unsubscribe() : null;
    this.eventEmitterSubscription != undefined ? this.eventEmitterSubscription.unsubscribe() : null;
    this.getSelectedVersammlungFromDexListener != undefined ? this.getSelectedVersammlungFromDexListener.unsubscribe() : null;
    this.getTeilnehmerListener != undefined ? this.getTeilnehmerListener.unsubscribe() : null;
    this.getSelectedTagesordnungspunktListener != undefined ? this.getSelectedTagesordnungspunktListener.unsubscribe() : null;
    this.getCurrentSettingsFromDbListener != undefined ? this.getCurrentSettingsFromDbListener.unsubscribe() : null;
    this.vertretungenListener != undefined ? this.vertretungenListener.unsubscribe() : null;
    this.eventEmitterService.eventEmitter.next({cmd: e_command_enum.showHeader , data: {display: "inherit"}});
    this.force_stay = false;
    this.dex.stopRefreshTimer();
  }

  ngAfterViewInit(): void
  {
    this.logger.info(this.selectedVersammlung, "selectedVersammlung ")
    // this.dex.currentSelectedVersammlung.subscribe(vs=>{
    //   this.logger.info(vs, "dex vs")
    // });
    this.dex.startRefreshTimer();
  }

  ngOnInit(): void
  {
    this.initListener();
    // this._socketService.getSettingsFromServer({});

    // Ablauf
    // 1: querySelectedVersammlung über socket-listener-component => dex.saveSelectedVersammlung
    // 2: hier getSelectedVersammlungFromDexListener => loadDataFromSocketLater
    // 3: querySelectedTagesordnungspunkt => dex.querySelectedTagesordnungspunkt => dex.saveSelectedTagesordnungspunkt
    // 4: getSelectedTagesordnungspunktListener => navigate to
  }

  getRouterLinkParameter()
  {
    try
    {
      if(this.activatedRoute.snapshot.paramMap.get("data"))
      {
        this.routerLinkParameter = this.activatedRoute.snapshot.paramMap.get("data");
        this.routerLinkParameter = JSON.parse(this.routerLinkParameter);
        this.logger.info(this.routerLinkParameter, "getRouterLinkParameter");
        if(Object.keys(this.routerLinkParameter).length > 0)
        {
          if(this.routerLinkParameter.hasOwnProperty("force_stay"))
          {
            this.force_stay = this.routerLinkParameter.force_stay;
          }
          if(this.routerLinkParameter.hasOwnProperty("anmeldungKorrekt") &&
             this.routerLinkParameter.anmeldungKorrekt === true)
          {
            // Anmeldung korrekt -> kann direkt weiter wenn eine andere View vorgesehen ist
            this.initReadSettingsFromDbListener();
          }
        }
      }
      else
      {
        this.logger.warning("getRouterLinkParameter nothing found");
      }
    }
    catch(e)    {      this.logger.error(e)    }
  }

  loadDataFromSocket()
  {
    this.dex.querySelectedVersammlung("lockedscreen_comp")
    this.eventEmitterService.eventEmitter.next({cmd: e_command_enum.showHeader , data: {display: "inherit"}});
  }

  loadDataFromSocketLater()
  {
      this._socketService.querySelectedTagesordnungspunkt({
        cmd: "querySelectedTagesordnungspunkt",
        versammlung_id: this.selectedVersammlung.versammlung_id,
        teilnehmer_id: this.currentUser.teilnehmer_id,
        active: true,
        onlyPreview: true
      });
  }

  initListener()
  {
    // Vertretungen Listener
    // this.vertretungenListener = this._socketService.getSocketObservable(vertretungCMDs.vertretungen).subscribe((callback: callback__VertretungSpeichern | callback__VertretungLoeschen) => {
    //   console.log("@@@@@ vertretungenListener", callback);
    // });
    // this.vertretungService.initVertretungenListener();
    // this.vertretungService.vertretungenResponse$.subscribe((callback: callback__VertretungSpeichern | callback__VertretungLoeschen) => {
    //   console.log(">>>> VertretungenResponse", callback);
    // });

    // Event Emitter
    this.eventEmitterSubscription = this.eventEmitterService.eventEmitter.subscribe(msg => {
      try
      {
        if(msg.hasOwnProperty("cmd"))
        {
          const cmd = msg.cmd;
        }
      }catch(e){this.logger.error(e);}
    });

    // getSelectedVersammlung
    this.getSelectedVersammlungFromDexListener = this.dex.currentSelectedVersammlung.subscribe( async (data : any )=>{
      try
      {
        if( (data=== null || data === '' || data ==='undefined' || Object.keys(data).length === 0)  && this.authenticationService.currentUserValue)
        {
          this.dex.querySelectedVersammlung("from Lockedscreen");
        }
        else
        {
          this.selectedVersammlung = data;
          this.logger.info(data, "[lockscreen] getSelectedVersammlungFromDexListener ");
          this.getRouterLinkParameter();
          // Zwangs Navigate
          // const navigateCommand = JSON.parse(this.selectedVersammlung.navigateClient);
        }
      }
      catch(e){this.logger.error(e);}
    });

    this.currentUserListener = this.authenticationService.currentUser.subscribe(userData => {
      try
      {
        this.currentUser = userData;
      }catch(e){this.logger.error(e);}
    });
  }

  initListenerLater()
  {
    // GET Tagesordnungspunkt Daten
      // GET Teilnehmer Daten
      this.getTeilnehmerListener = this.dex.currentSelectedTeinehmerData.subscribe( async (data : any )=>{
        try
        {
          if(HelperFunctions.Misc.isJson2(data) == 'string' && data.length > 0)
          {
              data = JSON.parse(data);
              this.logger.info(data, "TEILNEHMER DATA");
              if(data.response)
              {
                  await this.getTeilnehmerHandler(data);
              }
          }
        }
        catch(e) { this.logger.error(e); }
      });

    // GET Tagesordnungspunkt Daten
    // Code -1 - kein tP | 0 TN hat keine Einheit | 1: valide, hat schon was abgestimmt | 2: TN hat noch nichts abgestimmt
    this.getSelectedTagesordnungspunktListener = this.dex.currentSelectedTagesordnungsPunkt.subscribe( async (data : any )=>{
      try
      {
        if(data && data.code)
        {
          switch(data.code)
          {
            case -1:
              // Kein Tagesordnungspunkt durch das Dashboard angewählt
            break;
            case 0:
              // Teilnehmer besitzt keine Einheiten
            break;
            case 1:
              // Valide, es liegen bereits Abstimmungen vor
              // this.gemeinschaftstypen = data.gemeinschaftstypen;
              // this.einheiten = data.einheiten;
              // this.abstimmungen = data.abstimmungen;
              // this.selectedTagesordnungspunkt = data.tagesordnungspunkt;
            break;
            case 2:
              // Teilnehmer hat noch keine Abstimmung getätigt
              // this.gemeinschaftstypen = data.gemeinschaftstypen;
              // this.einheiten = data.einheiten;
              // this.abstimmungen = data.abstimmungen;
              // this.selectedTagesordnungspunkt = data.tagesordnungspunkt;
            break;
            case 3:
            break;

          }
          if(data.code === 1 || data.code === 2)
          {
          }

        }
      }
      catch(e) { this.logger.error(e); }
    });
  }

  logout()
  {
    this.authenticationService.logout();
    this.router.navigate(['/mobile-client/login']);
  }

  // deleteUser(id: number) { }
  async getTeilnehmerHandler(data){}

  public initReadSettingsFromDbListener()
  {

    this.getCurrentSettingsFromDbListener = this.dex.currentSettingsFromDb.subscribe(settings => {
      if(Object.keys(settings).length > 0)
      {
        this.settingsFromDb = settings;
        this.logger.info(this.settingsFromDb, "settings !!!!");
        let navigateCommand: any = this.settingsFromDb.filter(s => {return s.key ==="navigate"});
        if(navigateCommand.length > 0 && navigateCommand[0].hasOwnProperty("value") &&
           navigateCommand[0].value.hasOwnProperty("site") &&
           navigateCommand[0].value.site != 2 &&
           navigateCommand[0].value.site != 7)
        {
          navigateCommand = navigateCommand[0];
          const data = {
            cmd: navigateCommand.key,
            site: navigateCommand.value.site,
            delay: navigateCommand.value.delay? navigateCommand.value.delay: 1,
            data: navigateCommand.value.data? navigateCommand.value.data: {}
          };
          if(this.force_stay === false)
          {
          }
          else
          {
            this.logger.info("Forced to Stay on Lockedscreen");
          }
        }
        else
        {
          this.initListenerLater();
        }
    }})
  }

  zwangsNavigate(navigateCommand: any = {})
  {
    if(Object.keys(navigateCommand).length > 0)
    {
      const data = {
        cmd: "navigate",
        site: navigateCommand.site,
        description: "from Lockedscreen",
        delay: navigateCommand.delay? navigateCommand.delay: 1,
        data: navigateCommand.data? navigateCommand.data: {}
      };
      this.logger.info(data, "### Zwangs Navigate by selectedVersammlung , and => check valid ! ");
      setTimeout(() => {
        this.dex.broadcastHandler(data);
      }, 50);
    }
  }
}
