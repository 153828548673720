<!-- <link rel="stylesheet" type = 'text/css' [href]='sanitizer.bypassSecurityTrustResourceUrl(cssUrl)'> -->
<!-- NEW -->
<div id="wrapper" class="wrapper mobile-client" #wrapper>
    <div id="content-wrapper" class="wrapper-content" #contentWrapper [ngStyle]="{'background-image':  _socketService.getBackgroundImageUrl('background-mobile.jpg') }">
        <app-topbar></app-topbar>
        <div class="row">
            <ng-container *ngIf="(sambaService?.displayVideoConferenceSrc.value == true && this.allow_video_conference_here == true)">
                <div [ngClass]="{'col-xs-12 col-sm-12 col-md-12 col-lg-9': (currentUser && currentUser != null && currentUser.personalSessionLink)}">
                    <app-samba></app-samba>
                </div>
            </ng-container>
            <div
                [ngClass]="{'col-xs-12 col-sm-12 col-md-12 col-lg-3 pl-0': (sambaService?.displayVideoConferenceSrc.value == true &&  this.allow_video_conference_here == true ), 'col col-12': ( sambaService?.displayVideoConferenceSrc.value == false || this.allow_video_conference_here == false ) }"
                >
                <div class="container-fluid" #outletWrapper
                    [ngClass]="{'content-scroll': enableContentScroll() === true}"
                >
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    </div>
</div>
