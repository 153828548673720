/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/quotes */
import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, Subject } from 'rxjs';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface alert_message_object {
    type: alert_message_type;
    text: string;
}

export enum alert_message_type {
    success = "success",
    warning = "warning",
    error = "error",
    info = "info"
}

@Injectable({ providedIn: 'root' })
export class AlertService
{
    private subject = new Subject<any>();
    private keepAfterRouteChange = false;
    private clearTimer: any = null;
    constructor(private router: Router)
    {
        // clear alert messages on route change unless 'keepAfterRouteChange' flag is true
        this.router.events.subscribe(event =>
        {
            if (event instanceof NavigationStart)
            {
                if (this.keepAfterRouteChange)
                {
                    // only keep for a single route change
                    this.keepAfterRouteChange = false;
                }
                else
                {
                    // clear alert message
                    this.clear();
                }
            }
        });
    }

    getAlert(): Observable<any>
    {
        return this.subject.asObservable();
    }

    success(message: string, keepAfterRouteChange = false)
    {
        this.keepAfterRouteChange = keepAfterRouteChange;
        this.subject.next({ type: alert_message_type.success, text: message });
    }
    warning(message: string, keepAfterRouteChange = false)
    {
        this.keepAfterRouteChange = keepAfterRouteChange;
        this.subject.next({ type: alert_message_type.warning, text: message });
    }
    error(message: string, keepAfterRouteChange = false)
    {
        this.keepAfterRouteChange = keepAfterRouteChange;
        this.subject.next({ type: alert_message_type.error, text: message });
    }

    info(message: string, keepAfterRouteChange = false)
    {
        this.keepAfterRouteChange = keepAfterRouteChange;
        this.subject.next({ type: alert_message_type.info, text: message });
    }

    clear()
    {
        // clear by calling subject.next() without parameters
        clearTimeout(this.clearTimer);
        this.subject.next(null);
    }
    clearAfterTime(time)
    {
        this.clearTimer = setTimeout(() =>
        {
            this.clear();
        }, time);
    }

    messageAfterTime(delay, clearTime = 2000,  message: alert_message_object, clear_prev_message_now = true)
    {
        clear_prev_message_now === true ? this.clear() : null;
        this.clearTimer =
        setTimeout(() =>
        {
            clear_prev_message_now === false ? this.clear() : null;


            if(message.type === alert_message_type.info)
            {
                this.info(message.text);
            }

            if(message.type === alert_message_type.error)
            {
                this.error(message.text);
            }

            if(message.type === alert_message_type.warning)
            {
                this.warning(message.text);
            }

            if(message.type === alert_message_type.success)
            {
                this.success(message.text);
            }

            if(clearTime > 0)
            {
                this.clearAfterTime(this.clearAfterTime(clearTime));
            }

            clearTimeout(this.clearTimer);
            this.clearTimer = null;
        }, delay);
    }

    resetClearAfterTimeTimeout()
    {
        clearTimeout(this.clearTimer);
        this.clearTimer = null;
    }
}
