<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{header}}</h4>
    <!-- <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click'), this.dialogRef.close()"> -->
    <button type="button" class="close" aria-label="Close" (click)="closeModal()" #closeBtn>
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body angular-with-newlines" #content>
  Text
</div>
<div class="modal-footer">   
    <ng-container *ngIf="cancel_function">
      <button type="button" #cancelBtn class="btn btn-outline-dark" (click)="cancel_function()">Abbrechen</button>
    </ng-container>
         
    <button type="button" #okBtn ngbAutofocus class="btn btn-info" (click)="ok_function()">Weiter</button>
    
</div>