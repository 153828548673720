/* eslint-disable no-unsafe-finally */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-case-declarations */
/* eslint-disable no-inner-declarations */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-prototype-builtins */
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import {BehaviorSubject,  Observable, Subject, Subscription} from 'rxjs';
import { SocketService } from './socket.service';
import { Router } from '@angular/router';
import { LoggerService } from '../services/logger.service';
import { abstimmung_next_commands, e_command_enum, e_event_emitter_enum } from '../_models/enum';
import { GlobalsService, qualifizierungsTypen_, relations, stimmgeber_, stimmgeber_key } from './globals.service';
import { Qualifizierungskonfiguration } from '../_models/interfaces';
import {EventEmitterService} from '../services/event-emitter.service';
import {AuthenticationService} from './authentication.service';

declare let HelperFunctions: any;

// Interfaces

interface qt_helper_interface {
  istKopfprinzip : Function;
  getQualifizierungsTypenArray : Function;
  getQualifizierungstypAsName : Function;
  getIndexOfQualifizierungsTypenArray : Function;

}
interface top_helper_interface {
  getStimmgeberNameByKey : Function;
  getStimmgeberNamenByTopId : Function;
  getBeruecksichtigung : Function;
}
interface gm_helper_interface {
  getGemeinschaftstypenNamen : Function;
}

// Enums
export enum abstimmungsTexteEnum {
  keineAbstimmung = "offen",
  KeineAbstimmung = "Offen",
  ja = "ja",
  Ja = "Ja",
  nein = "nein",
  Nein = "Nein",
  enthaltung = "enthaltung",
  Enthaltung = "Enthaltung"
}
// Models

//versammlung RoW
export interface VersammlungModel
{
  versammlung_id: number,
  objekt_id: number;
  name: string;
  beschreibung: string;
  gesamtstimmzahl: number;
  beschlussfaehigkeit: number;
  versammlungsdatum: string;
  active: number;
}

export interface TagesordnungspunktModel
{
  tagesordnungspunkt_id: number,
  versammlung_id: number,
  ist_unterpunkt: number,
  rang: number,
  beschlussgegenstand: string,
  name: string,
  bezeichnung: string,
  abstimmung_erforderlich: number,
  qualifizierungstyp: number,
  gemeinschaftstypen: Array<number>,
  dauer: number,
  erlaeuterung: string,
  beschlussvorschlag: string,
  protokollnotiz: string,
  active: number
}

@Injectable({
  providedIn: 'root'
})

// Event Emitter datatype
// export class EventEmitterEvent {
//   message: any;
// }

export class DataExchangeService implements OnInit, OnDestroy {

  // public backButtonState = new Subject();
  public versammlungBearbeiten: {observer, init, set, get , remove};

  private backBtnSource = new BehaviorSubject({text: "Zurück",backBtn:{routerLink: '/', state: 0, fn: null}});
  public currentBackBtn = this.backBtnSource.asObservable();

  private navCommunication = new BehaviorSubject('');
  public currentNav = this.navCommunication.asObservable();

  private selectedVersammlungSrc : BehaviorSubject<any>;
  public currentSelectedVersammlung : Observable<any>;

  private selectedTeilnehmerDataSrc : BehaviorSubject<any>;
  public currentSelectedTeinehmerData : Observable<any>;


  private selectedAbstimmenDataSrc = new BehaviorSubject('');
  public currentAbstimmenData = this.selectedAbstimmenDataSrc.asObservable();

  private selectedTagesordnungspunktSrc : BehaviorSubject<any>;
  public currentSelectedTagesordnungsPunkt : Observable<any>;

  private settingsFromDbSrc : BehaviorSubject<any>;
  public currentSettingsFromDb : Observable<any>;
  public dbsettings: any = {};


  // Listener
  private currentUserListener: Subscription;
  private eventEmitterSubscription: Subscription;
  private authorizedListener: Subscription;
  private unauthorizedListener: Subscription;
  // private createNewVersammlungListener: Subscription;
  private getTeilnehmerListener: Subscription;
  private querySelectedTagesordnungspunkt: Subscription;
  private getSettingsFromServerListener: Subscription;
  private querySelectedVersammlungListener: Subscription;
  private updateListener: Subscription;
  private refreshListener: Subscription;
  private broadcastListener: Subscription = null;
  private getErgebnissComponentDataListener : Subscription;

  // Vars
  public qualifizierungsTypen : any = qualifizierungsTypen_; // [{id:0 , name:"Einfache Mehrheit", active: 1}, {id: 1, name:"Qualifizierte Mehrheit", active: 1} , {id:2 , name: "Doppelte Qualifizierung", active: 0} , {id:3, name:"Doppelte Qualifizierung (Spezial)", active: 0}];
  public currentUser: any = null;
  public hausverwalter: any = { name: "Name des Hausverwalters", firma: "Firmenname"};
  public selectedVersammlung: any = {};


  public router: Router;
  private onSocketInitListenerInited = false;
  public refreshTimer : any = {
    intervalTime: 4000,
    refreshFunction: null
  };

  public error: any;
  public warning: any;
  public info: any;

  constructor(
    private logger: LoggerService,
    public globals: GlobalsService,
    private eventEmitterService: EventEmitterService,
    private _socketService : SocketService,
    private authenticationService : AuthenticationService
  )
  {
    this.selectedVersammlungSrc = new BehaviorSubject<any>(JSON.parse(localStorage.getItem("selectedVersammlungMobileClient")));
    this.currentSelectedVersammlung =  this.selectedVersammlungSrc.asObservable();
    this.selectedTagesordnungspunktSrc = new BehaviorSubject<any>('');
    this.currentSelectedTagesordnungsPunkt =  this.selectedTagesordnungspunktSrc.asObservable();
    this.selectedTeilnehmerDataSrc = new BehaviorSubject<any>('');
    this.currentSelectedTeinehmerData =  this.selectedTeilnehmerDataSrc.asObservable();
    this.settingsFromDbSrc = new BehaviorSubject<any>('');
    this.currentSettingsFromDb =  this.settingsFromDbSrc.asObservable();

    this.error = {
      teilnehmer_ist_gesperrt: "Dieser Teilnehmer ist aktuell gesperrt. Bitte melden Sie sich bei Ihrem Hausverwalter",
      interner_vertreter_unzulaessig: "Ihre Anmeldung ist unzulässig. Dieser Teilnehmer wird durch einen anderen Teilnehmer vertreten. Melden Sie sich bitte als dieser Teilnehmer an.",
      anmeldung_nicht_mehr_korrekt: "Ihre Anmeldung ist abgelaufen. Bitte erneut anmelden.",
      keine_versammlung: "Anmeldung nicht möglich. Aktuell wird keine Versammlung durchgeführt!",
      keine_versammlung_durchgefuehrt: "Die gewünschte Versammlung wurde noch nicht gestartet. Ihre Zugangsdaten sind korrekt. Bitte versuchen Sie es später erneut, wenn Ihr Hausverwalter die Versammlung gestartet hat.",
      anmeldung_invalid: "Keinen Teilnehmer mit diesem Nachnamen oder Passwort für diese Versammlung gefunden. Bitte erneut probieren.",
      teilnehmer_nicht_vorgesehen: "Sie sind für diese Versammlung nicht registriert oder bereits auf einem Gerät angemeldet. Bitte wenden Sie sich an Ihren Hausverwalter.",
      user_has_hausverwalter_vertreter: "Dieser Teilnehmer wird durch den Hausverwalter vertreten. Eine Anmeldung ist daher nicht möglich.",
      user_has_vertreter_error: "Dieser Teilnehmer wird durch einen anderen Teilnehmer vertreten. Bitte melden Sie sich als dieser Teilnehmer an.",
      user_has_vertreter_success: "Sie haben sich erfolgreich als Vertreter angemeldet.",
      no_socket_callback: "Keine Antwort vom Server.",
      keine_einheiten: "Sie besitzen keine Einheiten und vertreten keinen Teilnehmer mit Einheiten über die abgestimmt werden kann.",
      email_versand_fehlgeschlagen: "Der Email-Versand ist fehlgeschlagen. Möglicherweise wird keine Versammlung durchgeführt, oder es wurde kein Teilnehmer zu den Benutzerdaten gefunden.",
    }

    this.warning = {
      vertritt_einheiten: "Sie besitzen keine Einheiten, vertreten aber einen Teilnehmer mit Einheiten über die abgestimmt werden kann.",
      email_kein_teilnehmer: "Es wurde kein Teilnehmer mit den eigegebenen Zugangsdaten zu einer aktiven Eigentümerversammlung gefunden."
    }

    this.info = {
      email_versand_gestartet: "Der E-Mail-Versand wurde gestartet. Warte auf Antwort vom Server.",
      email_versand_erfolgreich: "Alle angewählten Teilnehmer wurden per E-Mail eingeladen.",
      email_testversand_erfolgreich: "Der E-Mail-Testversand ist erfolgreich.",
      email_nicht_alle_teilnehmer_eingeladen: "Nicht alle Teilnehmer wurden per E-Mail eingeladen, möglicherweise stimmen eine oder mehrere E-Mail-Adressen des oder der Teilnehmer nicht.",
      email_erfolgreich_verschickt: "Die E-Mail wurde erfolgreich verschickt"
    }
   }

   ngOnInit(): void
   {
        // load selected Versammlung from localstorage for pre init, if restart or hard reload the app
        try
        {
           let selectedVersammlung = null;
           selectedVersammlung = localStorage.getItem("selectedVersammlungMobileClient");
           if(selectedVersammlung != null && selectedVersammlung != '' && selectedVersammlung!= 'undefined' )
           {
             this.changeSelectedVersammlung(JSON.parse(selectedVersammlung));
           }
        }
        catch(e)
        {
          this.logger.error(e);
        }
   }

   ngOnDestroy(): void
   {
      this.eventEmitterSubscription != undefined ? this.eventEmitterSubscription.unsubscribe() : null;
      this.currentUserListener != undefined ? this.currentUserListener.unsubscribe() : null;
      this.unsubscribeAll();
   }

   unsubscribeAll()
   {
      this.getErgebnissComponentDataListener != undefined ? this.getErgebnissComponentDataListener.unsubscribe() : null;
      this.authorizedListener != undefined ? this.authorizedListener.unsubscribe() : null;
      this.unauthorizedListener != undefined ? this.unauthorizedListener.unsubscribe() : null;
      this.getSettingsFromServerListener != undefined ? this.getSettingsFromServerListener.unsubscribe() : null;
      this.querySelectedTagesordnungspunkt != undefined ? this.querySelectedTagesordnungspunkt.unsubscribe() : null;
      this.getTeilnehmerListener != undefined ? this.getTeilnehmerListener.unsubscribe() : null;
      this.querySelectedVersammlungListener != undefined ? this.querySelectedVersammlungListener.unsubscribe() : null;
      this.updateListener != undefined ? this.updateListener.unsubscribe() : null;
      this.refreshListener != undefined ? this.refreshListener.unsubscribe() : null;
      this.broadcastListener != null ? this.broadcastListener.unsubscribe() : null;
      this.stopRefreshTimer();
      if(this.onSocketInitListenerInited === true)
      {
        this.onSocketInitListenerInited = false;
      }
   }

  changeBackBtn(message: any) {
    this.backBtnSource.next(message);
  }
  getBackBtnListener(){
    return this.currentBackBtn;
  }

  changeNav(message: any){
    this.navCommunication.next(message);
  }
  getNavCommuniction(){
    return this.currentNav;
  }

  changeSelectedVersammlung(versammlung: any)
  {
    this.selectedVersammlungSrc.next(versammlung);
  }

  changeAbstimmenData(message: any)
  {
    this.selectedAbstimmenDataSrc.next(message);
  }
  getAbstimmenData(){
    return this.currentAbstimmenData;
  }

  // To save selected Versammlung in localStorage and her in DEX   // VersammlungModel
  saveSelectedVersammlung(versammlung: any)
  {
    this.selectedVersammlung = versammlung;
    localStorage.setItem("selectedVersammlungMobileClient",JSON.stringify(versammlung));
    this.changeSelectedVersammlung(versammlung);
  }

  // delete selectedVersammlung
  public deleteSelectedVersammlung()
  {
    localStorage.removeItem("selectedVersammlungMobileClient");
    this.selectedVersammlungSrc.next(null);
  }

  // To save selected Teilnehmer in localStorage and her in DEX
  saveAbstimmenData(data: any)
  {
    localStorage.setItem("abstimmenData",JSON.stringify(data));
    this.changeAbstimmenData(data);
  }

  // SelectedTagesordnungspunkt
  saveSelectedTagesodnungspunkt(tp)
  {
    this.selectedTagesordnungspunktSrc.next(tp);
  }
  // delete SelectedTagesodnungspunkt
  public deleteSelectedTagesodnungspunkt()
  {
    this.selectedTagesordnungspunktSrc.next('');
  }
  // SelectedTagesordnungspunkt
  saveSelectedTeilnehmerData(data)
  {
    this.selectedTeilnehmerDataSrc.next(data);
  }

  // SettingsFromDb
  saveSettingsFromDb(data)
  {
    const new_version = data.filter(s => s.key === "version").shift().value;
    if(this.globals.needReload(new_version) === true)
    {
      this.eventEmitterService.eventEmitter.next({cmd: e_event_emitter_enum.newVersionDetected+"" , data: {old_version: localStorage.getItem("version_mobile_client"), new_version: new_version}});
    }
    this.settingsFromDbSrc.next(data);
    localStorage.setItem("version_mobile_client",new_version);
  }

  getFormattedVersammlungRow(versammlung_row_from_db)
  {
    try
    {
      const formattedVersammlungRow: VersammlungModel = {
        beschlussfaehigkeit: versammlung_row_from_db.beschlussfaehigkeit,
        beschreibung: versammlung_row_from_db.beschreibung,
        gesamtstimmzahl: versammlung_row_from_db.gesamtstimmzahl,
        name: versammlung_row_from_db.versammlungsname,
        objekt_id: versammlung_row_from_db.objekt_id,
        versammlung_id: versammlung_row_from_db.versammlung_id,
        versammlungsdatum: versammlung_row_from_db.versammlungsdatum,
        active: versammlung_row_from_db.active
      }
      return formattedVersammlungRow;
    }
    catch(e){this.logger.error(e);}
  }

  initListener()
  {
    this.currentUserListener = this.authenticationService.currentUser.subscribe(userData => {
      try
      {
        this.currentUser = userData;
      }catch(e){this.logger.error(e);}
    });

    // Event Emitter
    this.eventEmitterSubscription = this.eventEmitterService.eventEmitter.subscribe(msg => {
      try
      {
        if(msg.hasOwnProperty("cmd"))
        {
          const cmd = msg.cmd;
          if(cmd === e_event_emitter_enum.connect)
          {
            this.logger.info(msg, "Connect")
          }
          if(cmd === e_event_emitter_enum.disconnect)
          {
            this.logger.info(msg, "Disconnect")
          }
          if(cmd === e_event_emitter_enum.onSocketInit)
          {
              this.unsubscribeAll();
              this.onSocketInit();
          }
        }
      }catch(e){this.logger.error(e);}
    });
  }

  public initRefreshListener()
  {
     // RefreshListener => this.dex.startRefreshTimer();
     this.refreshListener = this._socketService.response_refresh().subscribe( async (data : any ) => {
      if(HelperFunctions.Misc.isJson2(data) == 'string')
      {
        data = JSON.parse(data);
        this.logger.info(data, "dex refresh");
        if(data.response.hasOwnProperty("needReload") && data.response.needReload === true && data.response.hasOwnProperty("versammlung"))
        {
          if(this.currentUser && this.currentUser.versammlung_id === data.response.versammlung.versammlung_id &&
            this.selectedVersammlung.versammlung_id != data.response.versammlung.versammlung_id
          )
          {
            this.saveSelectedVersammlung(data.response.versammlung);
            if(HelperFunctions.Misc.isJson2(data.response.versammlung.navigateClient) == 'string')
            {
              const navigate = JSON.parse(data.response.versammlung.navigateClient);
              this.broadcastHandler({cmd: "navigate" , site: navigate.site, delay: 5});
              this.eventEmitterService.eventEmitter.next({cmd: e_command_enum.response_refresh , data: data});
            }
          }
        }
      }
    });
  }

  public onSocketInitListener()
  {
    this.onSocketInitListenerInited = true;
    // ############## temporär wichtige Listener #########################

    this.getErgebnissComponentDataListener = this._socketService.response_getErgebnissComponentData().subscribe( async (data : any )=>{
      try
      {
        if(HelperFunctions.Misc.isJson2(data) == 'string')
        {
          data = JSON.parse(data);
          // console.log(">>>> [response_getErgebnissComponentData] ",data)
          switch(data.submitted.cmd)
          {
            case e_event_emitter_enum.getErgebnissComponentData:
              this.eventEmitterService.eventEmitter.next({cmd: e_event_emitter_enum.getErgebnissComponentData+"" , data: data});
            break;
            case e_event_emitter_enum.getAllErgebnissComponentDataForVersammlung:
              this.eventEmitterService.eventEmitter.next({cmd: e_event_emitter_enum.getAllErgebnissComponentDataForVersammlung+"" , data: data});
            break;
          }

        }
      } catch (error) { console.log(error)}
    });

    // authorized Listener
    this.authorizedListener = this._socketService.response_authorized().subscribe( async ( data: any ) => {
        this.logger.info(data, `[dex] authorized - emit ${e_event_emitter_enum.authorized}`);
        this.eventEmitterService.eventEmitter.next({cmd: e_event_emitter_enum.authorized , data: {}});
    });

    // authorized Listener
    this.unauthorizedListener = this._socketService.response_unauthorized().subscribe( async ( data: any ) => {
        this.logger.info(data, `[dex] unauthorized - emit ${e_event_emitter_enum.unauthorized}`);
        this.eventEmitterService.eventEmitter.next({cmd: e_event_emitter_enum.unauthorized , data: {}});
    });

    // querySelectedTagesordnungspunkt
    this.querySelectedTagesordnungspunkt = this._socketService.response_querySelectedTagesordnungspunkt().subscribe( async (data : any )=>{
      try
      {
        if(HelperFunctions.Misc.isJson2(data) == 'string')
        {
            data = JSON.parse(data);
            if(data.response)
            {
              this.saveSelectedTagesodnungspunkt(data.response);
            }
        }
      }
      catch(e)
      {
        this.logger.error(e);
      }
    });

    // getTeilnehmerListener
    this.getTeilnehmerListener = this._socketService.response_getTeilnehmer().subscribe( async (data : any )=>{
      try
      {
        if(HelperFunctions.Misc.isJson2(data) == 'string')
        {
            data = JSON.parse(data);
            if(data.response)
            {
                this.saveSelectedTeilnehmerData(data.response);
            }
        }
      }
      catch(e)
      {
        this.logger.error(e);
      }
    });

    // getSettingsFromServerListener
    this.getSettingsFromServerListener = this._socketService.response_getSettingsFromServer().subscribe( async (data : any )=>{
      try
      {
        if(HelperFunctions.Misc.isJson2(data) == 'string')
        {
            data = JSON.parse(data);
            if(data.response && data.response.hasOwnProperty("settings") && Object.keys(data.response.settings).length > 0)
            {
              this.logger.info(data, "[dex] getSettingsFromDbListener 2");
              const s = data.response.settings.filter(s => {return s.client_type === "client" ||s.client_type === "all" });
              this.eventEmitterService.eventEmitter.next({cmd: e_event_emitter_enum.getSettingsFromServer , data: data.response.settings});
              this.saveSettingsFromDb(s);
              this.dbsettings = data.response.settings;
            }
        }
      }
      catch(e){this.logger.error(e);}
    });

    // querySelectedVersammlungListener
    this.querySelectedVersammlungListener = this._socketService.response_querySelectedVersammlung().subscribe( async (data : any )=>{
      try
      {
        if(HelperFunctions.Misc.isJson2(data) == 'string')
        {
            data = JSON.parse(data);
            const response = data.response;
            const submitted = data.submitted;
            this.logger.info(data, "[dex] response_querySelectedVersammlungListener");
            if(response.hasOwnProperty("selectedVersammlung"))
            {
              const cmd = data.submitted.cmd;
              if(cmd === e_command_enum.getSelectedVersammlung)
              {
                this.saveSelectedVersammlung(response.selectedVersammlung);
              }
              if(submitted.data.text === "login_code_1" || submitted.data.text === "login_code_3" )
              {
                this.navigateDecider();
              }
            }
            if(data.submitted.data.text === "abstimmung_gob")
            {
              this.saveSelectedVersammlung(response.selectedVersammlung);
              this.eventEmitterService.eventEmitter.next({cmd: e_event_emitter_enum.abstimmung_gob , data: data});
            }
        }
      }
      catch(e){this.logger.error(e);}
    });

    // UpdateListener
    this.updateListener = this._socketService.response_updateInDB().subscribe( async (data : any )=>{
      try
      {
        let cmd = '';
        if(HelperFunctions.Misc.isJson2(data) == 'string')
        {
            data = JSON.parse(data);
            if(data.response )
            {
              this.logger.info(data, "updateListener")
              this.logger.info(this.currentUser, "currentUser")
              if(data.submitted && data.submitted.hasOwnProperty("cmd"))
              {
                cmd = data.submitted.cmd;
                if(cmd === e_command_enum.teilnehmerSperren)
                {
                  if(data.submitted.data.vertreter_vorname == this.currentUser.vorname &&
                    data.submitted.data.vertreter_nachname == this.currentUser.nachname)
                  {
                    this.eventEmitterService.eventEmitter.next({cmd: e_event_emitter_enum.tn_vertritt_anderen_tn , data: data});
                  }

                  if( data.submitted.data.teilnehmer_id === this.currentUser.teilnehmer_id &&
                      data.submitted.data.versammlung_id === this.selectedVersammlung.versammlung_id)
                  {
                    this.eventEmitterService.eventEmitter.next({cmd: e_event_emitter_enum.teilnehmerSperren , data: data});
                  }
                }
              }
            }
        }
      }
      catch(e){ this.logger.error(e);}
    });

    // broadcastListener
    this.broadcastListener !== null ? this.broadcastListener.unsubscribe() : null;
    this.broadcastListener = this._socketService.response_broadcast().subscribe( async (data : any )=>{
      try
      {
        if(HelperFunctions.Misc.isJson2(data) == 'string')
        {
            data = JSON.parse(data);
            this.broadcastHandler(data);
        }
      }
      catch(e){this.logger.error(e);}
    });
  }

  public querySelectedVersammlung(absender = "von socket-listener.component")
  {
    const parameters = {cmd: 'getSelectedVersammlung', data:{
      versammlung_id: this.currentUser.versammlung_id,
      text : absender
    }}
    this._socketService.querySelectedVersammlung(parameters);
  }

  public checkAuthUser()
  {
    if(this.currentUser !== null || this.currentUser)
    {
      // Starte socket Verbindung zum Server auf zb. Reload
      this.logger.info("[checkAuthUser] user vorhanden starte Connection zum server")
      this._socketService.startConnection(this.currentUser, this.hausverwalter, {cmd: "by_checkAuthUser"});
    }
  }

  public onSocketInit()
  {
      this.onSocketInitListener();

      try {
        if(localStorage.getItem("selectedVersammlungMobileClient"))
        {
          const vs = JSON.parse(localStorage.getItem("selectedVersammlungMobileClient"));
          this.logger.info(vs, "vs");
          if(this.selectedVersammlung == null || Object.keys(this.selectedVersammlung).length === 0)
          {
            this.saveSelectedVersammlung(vs);
          }
        }
      } catch (error) {
        this.logger.error(error);
      }
  }

  public navigateDecider(_data = {})
  {
    try {
      // let navto = null;
      this.logger.info(_data, "[dex] navigateDecider code");
      this.logger.info(this.selectedVersammlung, "[dex] navigateDecider selectedVersammlung");
      if(this.currentUser)
      {
        if(this.selectedVersammlung != null  && Object.keys(this.selectedVersammlung).length > 0 )
        {
          let navigateCommand = {site: 2, delay: 150, data: {tagesordnungspunkt: null, versammlung_id : this.selectedVersammlung.versammlung_id}};
          if(typeof typeof this.selectedVersammlung.navigateClient === "string" && this.selectedVersammlung.navigateClient.length > 2)
          {
            navigateCommand = JSON.parse(this.selectedVersammlung.navigateClient);
          }
          // valide
          const parameters = {
            cmd: "navigate" ,
            site: navigateCommand.site ,
            delay : navigateCommand.delay,
            data: {
              tagesordnungspunkt: navigateCommand.data.tagesordnungspunkt ? navigateCommand.data.tagesordnungspunkt : null,
              versammlung_id: navigateCommand.data.versammlung_id ? navigateCommand.data.versammlung_id : null}}
          // navto = 'mobile-client/lockedscreen';
          this.broadcastHandler(parameters);
        }
        else
        {
          // navto = 'mobile-client/login';
          this.logger.info("no Selected Versammlung found !")
        }
      }
      else
      {
        // navto = 'mobile-client/login';
      }

      // this.logger.info(navto, "[navigateDecider]")
      // this.logger.info(this.currentUser, "currentUser")
      // if(navto != null)
      // {
      //   return navto;
      // }
    } catch (error) {
      this.logger.error(error)
    }
  }

  public checkIsCountryAT() : boolean{
    if (Object.keys(this.dbsettings).length > 1 && this.dbsettings.filter(s => {
      return s.key == "is_at_country"
    }).length > 0) {
      return HelperFunctions.Misc.castBoolean(this.dbsettings.filter(s => {
        return s.key == "is_at_country"
      })[0]['value']);
    }
  }
  // Handler to manage incoming Broadcasting messages data = {cmd: "machet" ,site: 2, delay : 0, data: {tagesordnungspunkt: OBJ}}
  public broadcastHandler(data)
  {
    this.logger.info(data, `[dex] broadcastHandler to Site: ${data.site?data.site:""}`);

    if(data.cmd === e_command_enum.navigate)
    {
      const self = this;
      if (data && data.hasOwnProperty("delay"))
      {
        setTimeout(() => {
          processNavigate();
        }, data.delay);
      }
      else
      {
        processNavigate();
      }

      function processNavigate()
      {
        // navigate site :
        // | 0
        // | 1: login
        // | 2: lockedscreen
        // | 21: Erklaerung
        // | 3: abstimmung-main
        // | 4: abstimmung-main > vielen dank für die Teilnahme
        // | 5: abstimmung-next > vielen dank für die Teilnahme
        // | 6: abstimmung-next > showNaechsteAbstimmung
        self.logger.info(data, "[dex] processNavigate");
        switch(data.site)
        {
          case 0:
            break;
          case 1:
            self.router.navigate(['mobile-client/login']);
            break;
          case 2:
              try
              {
                if(data.data && data.data.hasOwnProperty("versammlung_id") &&
                  (data.data.versammlung_id != self.currentUser.versammlung_id || data.data.versammlung_id != self.selectedVersammlung.versammlung_id)
                )
                {
                  self.logger.info(data, "Hier zwecks Lockedscreen muss ich die Versammlung neu anfordern")
                  self.logger.info(self.selectedVersammlung)
                  self.currentUser.versammlung_id = data.data.versammlung_id;
                  self.authenticationService.login2(self.currentUser);
                  self.querySelectedVersammlung("from navigate to Lockedscreen case 2:");
                }
                self.router.navigate(['/mobile-client/lockedscreen']);
              }catch(err){self.logger.error(err);}
            break;


          case 21:

           // setTimeout(() => {
                // self.router.navigate(['stage-client/erklaerung']);
                const url = '/mobile-client/erklaerung';
                if(self.selectedVersammlung != undefined && self.selectedVersammlung != null &&
                   Object.keys(self.selectedVersammlung).length > 0 && self.selectedVersammlung.versammlung_id === data.data.versammlung_id
                )
                {
                  if(data.hasOwnProperty("data") && data.data.hasOwnProperty("force")
                      // && data.data.force === true
                  )
                  {
                    const param: any = {};
                    data.data.force? param.force = data.data.force : null;
                    data.data.tagesordnungspunkt ? param.tagesordnungspunkt = data.data.tagesordnungspunkt : null;
                    self.router.navigate([url , {data: JSON.stringify(param) }] );

                    // self.router.navigate(['mobile-client/ergebniss', {data : JSON.stringify(data.data?.tagesordnungspunkt)} ]);
                    self.eventEmitterService.eventEmitter.next({cmd: e_event_emitter_enum.setErklaerungsData , data: param});
                  }
                  else
                  {
                    // self.router.navigate([url , {data : JSON.stringify(data.data?.tagesordnungspunkt)} ]);
                    self.router.navigate([url]);
                  }
                  // Reload Data if on same Component
                  if(self.router.url.includes(url))
                  {
                    // self.logger.info("emit reload data");
                    self.eventEmitterService.eventEmitter.next({cmd: e_event_emitter_enum.reloadComponentData , data: data});
                  }
                }
                else
                {
                  self.logger.warning(self.selectedVersammlung, "dex - broadcasthandler - navigate site 21 Wrong ID")
                  self.logger.warning(data)
                }
          //  }, 50);
            break;
          case 3:
            self.router.navigate(['mobile-client/abstimmung/abstimmung-main']);
            break;
          case 31:
            self.router.navigate(['mobile-client/abstimmung/abstimmung-gob']);
            break;
          // TODO: Case 4 ist obsolet durch VUL-302
          case 4:
            // showVielenDankFuerDieTeilnahme
            self.router.navigate([`mobile-client/abstimmung/abstimmung-next`,{cmd: "showVielenDankFuerDieTeilnahme" }]);
            self.eventEmitterService.eventEmitter.next({cmd: abstimmung_next_commands.showVielenDankFuerDieTeilnahme , data: data});
            break;
          case 5:
            //showVielenDankFuerDieAbstimmung
            // self.router.navigate(['mobile-client/abstimmung/abstimmung-next',{cmd: "showVielenDankFuerDieAbstimmung" }]);

            // NEU: Ergebnisview
            self.router.navigate(['mobile-client/ergebniss', {data : JSON.stringify(data.data?.tagesordnungspunkt)} ]);

            self.eventEmitterService.eventEmitter.next({cmd: e_event_emitter_enum.setErgebnissData , data: data.data?.tagesordnungspunkt});
            break;
          case 6:
            self.router.navigate([`mobile-client/abstimmung/abstimmung-next`,{cmd: abstimmung_next_commands.showNaechsteAbstimmung }]);
            self.eventEmitterService.eventEmitter.next({cmd: abstimmung_next_commands.showNaechsteAbstimmung , data: data});
            //showNaechsteAbstimmung
            break;

            // Teilnehmer Abmelden da Versammlung durchgelaufen ist.
           case 7:
            //  VUL-290
            const haveSelectedVersammlung : boolean = self.selectedVersammlung?.versammlung_id ? true : false;
            const dataHasSelectedVersammlung : boolean = data.data.selectedVersammlung?.versammlung_id ? true : false;
            let sameVersammlung_id  = false;
            if(dataHasSelectedVersammlung === true && haveSelectedVersammlung === true && self.selectedVersammlung?.versammlung_id === data.data.selectedVersammlung?.versammlung_id && typeof self.selectedVersammlung?.versammlung_id === "number")
            { sameVersammlung_id = true }
            // console.log("haveSelectedVersammlung ",haveSelectedVersammlung, " dataHasSelectedVersammlung ", dataHasSelectedVersammlung, " sameVersammlung_id ",sameVersammlung_id)
            if(haveSelectedVersammlung === true && dataHasSelectedVersammlung === true && sameVersammlung_id === true)
            {
              self.router.navigate([`mobile-client/abstimmung/abstimmung-next`,{cmd: abstimmung_next_commands.showVielenDankFuerDieTeilnahme }]);
              self.eventEmitterService.eventEmitter.next({cmd: abstimmung_next_commands.showVielenDankFuerDieTeilnahme , data: data});
            }
            else
            {
              if(haveSelectedVersammlung === false && (dataHasSelectedVersammlung === true || dataHasSelectedVersammlung === false))
              {
                self.router.navigate([`mobile-client/abstimmung/abstimmung-next`,{cmd: abstimmung_next_commands.showVielenDankFuerDieTeilnahme }]);
                self.eventEmitterService.eventEmitter.next({cmd: abstimmung_next_commands.showVielenDankFuerDieTeilnahme , data: data});
              }
              else
              {
                self.logger.info(data,">>> Warning no matching versammlung_id (broadcastData)")
                self.logger.info(self.selectedVersammlung,">>> (selectedVersammlung)")
              }
            }


           break;

           case 8:
            self.router.navigate(['/mobile-client/login']);
           break;
        }
      }
    }
    if(data.cmd==="setVersammlungAbgeschlossen")
    {
      this.logger.info(data, "Broadcast setVersammlungAbgeschlossenListener");
      this.deleteSelectedVersammlung();
      this.authenticationService.logout();
      this._socketService.closeConnection();
      this.router.navigate(['mobile-client/login']);
    }
  }

  isUsersValidForVersammlung(data)
  {
    let success = false;
    try
    {
      if(this.currentUser != null && Object.keys(this.currentUser).length > 1)
      {
        if(
          (    data.hasOwnProperty("data")
          && data.data.hasOwnProperty("versammlung_id")
          && data.data.versammlung_id != this.selectedVersammlung.versammlung_id))
        {
          success = false;
        }
        else
        {
          success = true;
        }
      }
    }
    catch(e)
    {
      this.logger.error(e);
    }
    return success;
  }

  loadDataFromSocket(){}


    // VUL-332 produce errors
    public startRefreshTimer(startAfterTime = 5000)
    {
      // setTimeout(() => {
      //   let self = this;

      //   if(this.refreshTimer.refreshFunction === null)
      //   {
      //     this.stopRefreshTimer();
      //     this.initRefreshListener();
      //     self.logger.info("startRefreshTimer")
      //     self.refreshTimer.refreshFunction = setInterval(() => {
      //       let site = 0;
      //       if(self.selectedVersammlung.hasOwnProperty("navigateClient") && self.selectedVersammlung.navigateClient !="")
      //       {
      //         let navigate = self.selectedVersammlung.navigateClient;
      //         if(HelperFunctions.Misc.isJson2(navigate) == 'string')
      //         {
      //           navigate = JSON.parse(navigate);
      //         }
      //         navigate.hasOwnProperty("site") ? site = navigate.site: null;
      //         let parameters =
      //         {
      //           site: site,
      //           versammlung_id : self.selectedVersammlung.versammlung_id
      //         }
      //         self._socketService.refresh(parameters);
      //       }
      //     }, self.refreshTimer.intervalTime)
      //   }
      // }, startAfterTime);
    }

    // VUL-332 produce errors
    public stopRefreshTimer()
    {
      // this.logger.info("stopRefreshTimer")
      // try
      // {
      //   clearInterval(this.refreshTimer.refreshFunction);
      //   this.refreshTimer.refreshFunction = null;
      //    if(this.refreshListener != undefined)
      //   {
      //     this.refreshListener.unsubscribe()
      //   }
      // }
      // catch (error)
      // {
      //   this.logger.error(error)
      // }
    }



    public gm_helper : gm_helper_interface = {
      getGemeinschaftstypenNamen : (gemeinschaftstypen : Array<number> = null, gemeinschaftstypen_array : any = {}) =>
      {
        // console.log("gemeinschaftstypen ",gemeinschaftstypen)
        let name = "";
        try
        {
          if(gemeinschaftstypen != null)
          {
            for (let i = 0; i < gemeinschaftstypen.length; i++)
            {
              const gm_id = gemeinschaftstypen[i];
              const name_ = gemeinschaftstypen_array.filter(g => {return g.id === gm_id});
              if(Object.keys(name_).length > 0 && name_[0].name)
              {
                i > 0 ? name += ", " : null;
                name += name_[0].name;
                // return name;
              }
            }
          }
        }
        catch (error) {
          console.log(error)
        }
        finally
        {
          return name;
        }
      }
    }

    public qt_helper : qt_helper_interface = {

      istKopfprinzip : (qt : Qualifizierungskonfiguration) =>
      {
        let kopfprinzip  = false;
        try
        {
          if(Object.keys(qt).length > 0 && this.globals.qualifzierungstypObjectIsCorrect(qt) == true )
          {
            if( qt.stimmgeber.s1.key === stimmgeber_key.stimmgeber_tn ||
                (qt.stimmgeber.s2.key === stimmgeber_key.stimmgeber_tn && qt.id >= 2) ||
                (qt.id === 3)
            )
            {
              kopfprinzip = true;
            }
          }
        }
        catch (error)
        {
          console.log(error)
        }
        finally
        {
          return kopfprinzip;
        }
      },
      getQualifizierungsTypenArray : (cmd  = "none") =>
      {
        let selection = this.qualifizierungsTypen;
        if(Object.keys(selection).length > 0 && cmd === "onlyActive")
        {
          selection = selection.filter(typ => { return typ.active != 0})
        }
        selection = selection.map(typ => {
            return typ.name
        });

        return selection;
      },

      getQualifizierungstypAsName : (qt_index : number) =>
      {
        let res = '';
        if(qt_index < this.qualifizierungsTypen.length ){
          res = this.qualifizierungsTypen[qt_index].name;
        }
        return res;
      },

      getIndexOfQualifizierungsTypenArray : (qualifizierungstyp_string : string, arr : any = {}) =>
      {
        let i = -1;
        if(Object.keys(arr).length < 1)
        {
          arr = this.qt_helper.getQualifizierungsTypenArray();
        }
        return i = arr.indexOf(qualifizierungstyp_string);
      }
    }

    public top_helper : top_helper_interface = {
      getStimmgeberNameByKey : (key_  = "") =>
      {
        let name = "";
        try {
          const _stimmgeber = stimmgeber_;
          // loop through object
          for (const [key, value] of Object.entries(_stimmgeber)) {
            if(key_ == value['key'])
            {
              name = value['value'];
              break;
            }
          }

        } catch (error) {
          console.log(error);
        }finally{return name;}
      },

      getStimmgeberNamenByTopId : (top: any = {} , prefix  = false , beruecksichtigung = true) =>
      {
        // console.log("top ", top)
        let text = ""
        try
        {     
          //  loop through object
          if(top.hasOwnProperty("stimmgeber")){top = {qualifizierungstyp : top};}
          if(top.hasOwnProperty("qualifizierungstyp"))
          {
            let index = 0;
            for (const [key, value] of Object.entries(top.qualifizierungstyp.stimmgeber))
            {

              if(prefix == true)
              {
                prefix = false;
                const qualifizierungstyp_ = this.qt_helper.getQualifizierungsTypenArray()[top.qualifizierungstyp.id];
                // text += ` , `;
                text += `${qualifizierungstyp_} ${value['key'].length > 2?" (":""}`;
              }
              if(value['key'].length > 2)
              {
                if(text.length < 5)
                {
                  text +=  `Stimmgeber : ` ;
                }
                // else{text += ` , `; }
                key=="s2"?text += " / ": "";
                 //text += `${this.top_helper.getStimmgeberNameByKey(value['key'])} - ${value['schwellwert']} %`;
                 // VUL-691

                if(typeof value['schwellwert'] === 'string' || value['schwellwert'] instanceof String) {
                  text += `${top.qualifizierungstyp.id == 2 ? (index + 1) + ". " : ""}${this.top_helper.getStimmgeberNameByKey(value['key'])} - ${value['schwellwert'].replace('.', ',')} %`;
                } else {
                  text += `${top.qualifizierungstyp.id == 2 ? (index + 1) + ". " : ""}${this.top_helper.getStimmgeberNameByKey(value['key'])} - ${value['schwellwert'].toFixed(2).replace('.', ',')} %`;
                }
                if(beruecksichtigung === true)
                text += `, Berücksichtigung: ${relations[value['relation']].text2}`;
                if(this.checkIsCountryAT() === true){
                  text = `MEA`; // , ${relations[value['relation']].text2}`;
                }
              }
              index++;
            }
            this.checkIsCountryAT() === false ? text += ")" : null;
          }

        }
        catch (error)
        {
          console.log(error)
        }
        finally
        {
          return text;
        }
      },

      getBeruecksichtigung : (qualifizierungskonfiguration: Qualifizierungskonfiguration = null , _stimmgeber  = "s1") =>
      {
        // console.log("top ", top)
        // Doppelte Qualifizierung , MEA Anteile - 58 % , Einheiten - 46 %
        const stimmgeber_ids = ["s1","s2"];
        let text = ""
        try
        {
          //  loop through object
          // if(qualifizierungskonfiguration.hasOwnProperty("stimmgeber")){top = {qualifizierungstyp : top};}
          if(qualifizierungskonfiguration != null && stimmgeber_ids.indexOf(_stimmgeber) != -1)
          {
            for (const [key, stimmgeber_] of Object.entries(qualifizierungskonfiguration.stimmgeber))
            {
              // console.log(key , " - " , stimmgeber_);
              if(key ===  _stimmgeber)
              {
                if(stimmgeber_.relation == 0)
                {
                  text = "Abgegebene Stimmen";
                }
                if(stimmgeber_.relation == 1)
                {
                  text = "Alle Stimmen"
                }
              }
            }
          }

        }
        catch (error)
        {
          console.log(error)
        }
        finally
        {
          return text;
        }
      }
    }

}
