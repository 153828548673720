<!-- Erste View -->
<div class="loginContent background-vulcavo animated--grow-in">
  <!-- Zweite View Neuer Ansatz -->
  <ng-container >
    <!-- Neuer Ansatz -->
    <div class="row h-100 align-items-center justify-content-center animated--grow-in no-user-select">
  
      <div class="col col-12 col-xs-12 col-md-10 col-lg-6 col-xl-4 background-vulcavo">
            <div class="row d-flex align-items-center justify-content-center">
              <div class="col-8 text-center">
                <img src="{{this._socketService.getImageLogoUrl('logo.png')}}" class="login-logo mb-3 mt-3"/>
              </div>
            </div>
            <mat-card class="login-card mt-1 mb-1">
                <!-- <mat-card-subtitle>
                  
                </mat-card-subtitle>
                <mat-card-title></mat-card-title> -->
                <mat-card-content>
                    <div class="row justify-content-center">
                      <div class="align-self-center text-center">
                        Bitte melden Sie sich mit den Daten aus Ihrer Einladungs-E-Mail an.
                      </div>
                    </div>
                    <div class="row justify-content-center align-items-center mt-2">
                      <!-- Inputs -->
                      <form [formGroup]="loginForm" autocomplete="on">
                        <div class="form-group mb-1">
                            <!-- neue Buttons -->
                            <p>
                              <mat-form-field class="example-full-width vulcavo-form-field" appearance="outline">
                                <mat-label>Ihr Nachname</mat-label>
                                <input matInput formControlName="nachname">
                                <mat-error *ngIf="!loginForm.controls.nachname.valid">{{getErrorMessage('nachname')}}</mat-error>
                              </mat-form-field>
                            </p>
                            <p>
                              <mat-form-field class="example-full-width vulcavo-form-field" appearance="outline">
                                <mat-label>Ihr Zugangscode</mat-label>
                                <input type="password" pattern="[0-9]*" maxlength="6" inputmode="numeric"  formControlName="password" matInput>
                                <mat-error *ngIf="!loginForm.controls.password.valid">{{getErrorMessage('password')}}</mat-error>
                              </mat-form-field>
                            </p>
                            
                        </div>
                      </form>
                      <!-- Ende Inputs -->
                    </div>
                    <mat-divider inset></mat-divider>
                    <div class="row justify-content-center align-items-center mt-3 mb-3">
                      <div class="align-self-center text-center">
                          <b>Ich bestätige hiermit folgende Teilnahmebedingungen</b>
                      </div>
                    </div>
                    <!-- Disclaimer -->
                    <div class="row  align-items-start justify-content-start align-items-center">
                        <div class="col-xs-auto">
                          <mat-checkbox id="checkbox1" 
                                (ngModelChange)="handlerFunctionOne($event)"   
                                [(ngModel)]="this.disclaimer.checkboxes.check1"
                            >            
                          </mat-checkbox>
                        </div>
                        <div class="col col-xs-auto" (click)="handlerFunctionOne($event.target)" id="text1" >
                          Es handelt sich bei der anzumeldenden Person um mich persönlich oder um einen vom Hausverwalter genehmigten Vertreter bzw. Bevollmächtigten.
                        </div>
                    </div>

                    <div class="row   align-items-start justify-content-start align-items-center mt-2">
                        <div class="col-xs-auto">
                          <mat-checkbox class="" id="checkbox2" 
                                (ngModelChange)="handlerFunctionOne($event)"   
                                [(ngModel)]="this.disclaimer.checkboxes.check2"
                            >            
                          </mat-checkbox>
                        </div>
                        <div class="col col-xs-auto" (click)="handlerFunctionOne($event.target)" id="text2" >
                          Die Versammlung findet unter Ausschluss Dritter statt. Jegliche in der Versammlung genannten Daten & Informationen werden nicht an Dritte weitergeleitet.
                        </div>
                    </div>

                    <div class="row  align-items-start justify-content-start align-items-center mt-2">
                        <div class="col-xs-auto">
                          <mat-checkbox class="" id="checkbox3" 
                                (ngModelChange)="handlerFunctionOne($event)"   
                                [(ngModel)]="this.disclaimer.checkboxes.check3"
                            >           
                          </mat-checkbox>
                        </div>
                        <div class="col col-xs-auto" (click)="handlerFunctionOne($event.target)" id="text3" > 
                          Ton- oder Video-Aufnahmen sind allgemein nicht gestattet.
                          <!-- <i class="fas fa-chevron-right btn-icon"></i>
                          <i class="fa fa-chevron-right"></i>
                          <fa-icon [icon]="faCoffee"></fa-icon> -->
                        </div>
                    </div>
                    <!-- Ende Disclaimer -->
                    
                </mat-card-content>
                
                <mat-card-actions>
                    <div class="row align-items-center justify-content-center mt-2">
                      <div class="col-xs-auto">
                        <button type="button" class="btn btn-lg btn-primary vulcavo-btn-primary" (click)="anmelden(false)" 
                        [disabled]="loading === true || !this.loginForm.valid || this.disclaimer.checkboxes.check1 === false || this.disclaimer.checkboxes.check2 === false || this.disclaimer.checkboxes.check3 === false">
                          Anmelden
                          <!-- <i class="fas fa-chevron-right btn-icon"></i> -->
                          <!-- <i class="fa fa-chevron-right"></i> -->
                        </button>  
                      </div>
                    </div>
                    <!-- <button mat-button>SHARE</button> -->
                </mat-card-actions>
                <mat-card-footer>
                  <mat-progress-bar mode="indeterminate" class="mb-1" *ngIf="this.loading === true"></mat-progress-bar>
                  <div class="">
                    <app-alert></app-alert>
                  </div>
                </mat-card-footer>
            </mat-card>
      </div>
    </div>
    <!-- Ende Neuer Ansatz -->
    
  
  </ng-container>
  
</div>
