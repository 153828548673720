import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class LoggerService {
  constructor() {}

  public info2(msg: any, _text="", _forceprint: Boolean = false) {
    return Observable.create((observer) => {
        observer.next(this.logit2("> INFO: ", msg, _text, _forceprint));
    });
}

  public info(msg: any, _text="", _forceprint: Boolean = false) {
    this.logit("> INFO: ", msg, _text, _forceprint);
  }

  public warning(msg: any, _text="", _forceprint: Boolean = false) {
    this.logit(">> WARNING: ", msg, _text, _forceprint);
  }

  public error(msg: any, _text="", _forceprint: Boolean = false) {
    this.logit(">>> ERROR: ", msg, _text, _forceprint);
  }

  public success(msg: any, _text="", _forceprint: Boolean = false) {
    this.logit("SUCCESS: ", msg, _text, _forceprint);
  }

  private logit(prefix: String, msg: any, _text, _forceprint: Boolean) {
    if(!environment.production || _forceprint) {
      console.log(prefix+_text+" ", msg);
    }
  }
  private logit2(prefix: String, msg: any, _text, _forceprint: Boolean) {
    if(!environment.production || _forceprint) {
      return {text: prefix+_text+" ", msg:msg};
    }
  }
}
