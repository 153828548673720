/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable no-empty */
/* eslint-disable no-prototype-builtins */
import { Injectable, OnInit, OnDestroy } from '@angular/core';
import {io,Manager, Socket, SocketOptions, ManagerOptions, connect, protocol} from 'socket.io-client';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { DataExchangeService } from './data-exchange.service';
import { LoggerService } from '../services/logger.service';
import { e_command_enum, e_event_emitter_enum } from '../_models/enum';
import { environment } from 'src/environments/environment';
import { FileSettings, fileSettingsInit } from '../_models/interfaces';
import {loginParameters} from '../components/login/login.component';
import {EventEmitterService} from '../services/event-emitter.service';
declare let require: any;

// https://socket.io/docs/client-api/
// https://codingblast.com/chat-application-angular-socket-io/

@Injectable({
  providedIn: 'root'
})
export class SocketService implements OnInit, OnDestroy {
  // Vars
  private settings : FileSettings;
  public socket: Socket = null; 

  // Listener
  private eventEmitterSubscription: any;

  constructor(
    private http: HttpClient,
    // private dex: DataExchangeService,
    private logger: LoggerService,
    private eventEmitterService: EventEmitterService
  ) {
    this.settings = fileSettingsInit
    if(Object.keys(window['settings']).length > 0 && window['settings'].hasOwnProperty("server"))
    {
      this.settings = window['settings']['server'];
    }
  }

  ngOnInit(): void 
  { 
    
    this.initListener();
  }

  ngOnDestroy(): void {
    this.eventEmitterSubscription.unsubscribe();
  }

  public getServerPath(): string {
    // VUL-820
    let portStr = ""; // this.settings.http_port;
    if (environment.production == true || this.settings.prefix === "https") {
    } else {
      portStr = ":88"; // was 88
    }
    return `${this.settings.prefix}://${this.settings.server_ip}${portStr}`;
  }

  public getImageLogoUrl(image = "logo.png") : string
  {
      const url = `${this.getServerPath()}/assets/images/${image}`;
      return url;
  }

  public getBackgroundImageUrl(image = "background-mobile.jpg") : string
  {
      const url = `url('${this.getServerPath()}/assets/images/${image}')`;
      return url;
  }

  
  public getSettings()
  {
    return this.settings;
  }
  public getSettings2 = () =>{
    return Observable.create((observer) => {
      this.http.get("assets/settings/settings.json").subscribe(data =>{
        observer.next(data);
        // this.settings = data;
      })
    });
  }
  public getSettings3()
  {
      return this.http.get('assets/settings/settings.json')
        .toPromise()
        .then(data => {
          this.logger.info(data, "HTTP read");
      });
  }
  
  // wird nicht verwendet
  public getFromDB(parameters)
  {
    this.socket.emit('getFromDB', parameters);
  }
   // wird nicht verwendet
  public insertInDB(parameters)
  {
    this.socket.emit('insertInDB', parameters);
  }
  public updateInDB(parameters)
  {
    this.socket.emit('updateInDB', parameters);
  }
  public deleteFromDB(parameters)
  {
    this.socket.emit('deleteFromDB', parameters);
  }

  public getVersammlungenFromDB(parameters)
  {
    this.socket.emit('getVersammlungen', parameters);
  }

  // Wird nicht verwendet
  public createNewVersammlung(parameters)
  {
    this.socket.emit('createNewVersammlung',parameters);
  }
  // Wird nicht verwendet
  public deleteVersammlung(parameters)
  {
    this.socket.emit('deleteVersammlung',parameters);
  }
  // Wird nicht verwendet
  public setVersammlungAbgeschlossen(parameters)
  {
    this.socket.emit('setVersammlungAbgeschlossen',parameters);
  }

  public getTagesordnungspunkte(parameters)
  {
    this.socket.emit('getTagesordnungspunkte',parameters);
  }

  public deleteTagesordnungspunkt(parameters)
  {
    this.socket.emit('deleteTagesordnungspunkt',parameters);
  }
  // wird nicht verwendet
  public createNewTagesordnungspunkt(parameters)
  {
    this.socket.emit('createNewTagesordnungspunkt',parameters);
  }
 
  //Teilnehmer
  public getTeilnehmer(parameters)
  {
    this.socket.emit('getTeilnehmer' , parameters);
  }

  // Wird nicht verwendet
  public setTeilnehmer(parameters)
  {
    this.socket.emit('setTeilnehmer' , parameters);
  }

  // Wird nicht verwendet
  public getTagesordnungsPunkteForTeilnehmer(parameters)
  {
    this.socket.emit('getTagesordnungsPunkteForTeilnehmer' , parameters);
  }

  public handleGeschaeftsordnungsbeschluss(parameters)
  {
    this.socket.emit('handleGeschaeftsordnungsbeschluss' , parameters);
  }

  // Email
  public email(parameters)
  {
    this.socket.emit('email' , parameters);
  }
  
  // Abstimmung
  public abstimmung(parameters)
  {
    this.socket.emit('abstimmung' , parameters);
  }

  // Refresh
  public refresh(parameters)
  {
    this.socket.emit('refresh', parameters)
  }

  // Broadcast
  public broadcast(parameters)
  {
    this.socket.emit('broadcast', parameters)
  }

  // cl: Anmeldung
  // Wird nur als Callback (weiter unten) benötigt
  public anmeldung(parameters)
  {
    this.socket.emit('anmeldung' , parameters);
  }
  // cl: Abmeldung
  public abmeldung(parameters)
  {
    this.socket.emit('abmeldung' , parameters);
  }
  // cl: checkValidAnmeldung
  // wird nicht mehr verwendet
  public checkValidAnmeldung(parameters)
  {
    this.socket.emit('checkValidAnmeldung' , parameters);
  }
  // cl: Get Selected and Active Versammlung
  public querySelectedVersammlung(parameters)
  {
    this.socket.emit('querySelectedVersammlung' , parameters);
  }

  // cl: Get active Tagesordnungspunkt and relevant Data for a Versammlung
  public querySelectedTagesordnungspunkt(parameters)
  {
    this.socket.emit('querySelectedTagesordnungspunkt' , parameters);
  }

  // cl: getSettings
  public getSettingsFromDb(parameters)
  {
    this.socket.emit('getSettingsFromDb' , parameters);
  }

  // cl: getSettings from Server
  public getSettingsFromServer(parameters)
  {
    this.socket.emit('getSettingsFromServer' , parameters);
  }

  // Init -/ or disable Barcode Scanner
  public initBarcodeScanner(parameters)
  {
    this.socket.emit('initBarcodeScanner' , parameters);
  }

  // getErklaerungComponentData
  public getErklaerungComponentData(parameters)
  {
    this.socket.emit('getErklaerungComponentData' , parameters);
  }

  // getErklaerungComponentData
  public getErgebnissComponentData(parameters)
  {
    this.socket.emit('getErgebnissComponentData' , parameters);
  }

  public vertretungen(parameters: unknown): void {
    this.socket.emit('vertretungen', parameters);
  }

  initRegisterWebsocketListener()
  {
    this.socket.on('registerSocket', (response) => {        
      const parameters = {cmd: "registerSocket", client_type : this.settings.client_type}
      this.logger.info(parameters, "have to register now");
      this.socket.emit('registerSocket', parameters);
    });
  }

  public initListener()
  {
      // Event Emitter
      // For Anmeldungen über Barcode-Scanner
      this.eventEmitterSubscription = this.eventEmitterService.eventEmitter.subscribe(msg => {
        try
        {
          if(msg.hasOwnProperty("cmd"))
          {
            const cmd = msg.cmd;
            if(cmd === e_event_emitter_enum.connect)
            {           
              this.logger.info(msg, "Connect")
            }
            if(cmd === e_event_emitter_enum.disconnect)
            {           
              this.logger.info(msg, "Disconnect")
            }
          }
        }catch(e){this.logger.error(e);}
      });
  }

  public response_connect = (): Observable<any> => {
    return new Observable((observer) => {
      this.socket.on('connect', (message: any = null): void => {
        observer.next(message);
      });
    });
  };
  
  public response_unauthorized = () =>{
    return Observable.create((observer) => {
      this.socket.on('unauthorized', (message) => {
        observer.next(message);
      });
    });
  }

  public response_authorized = () =>{
    return Observable.create((observer) => {
      this.socket.on('authorized', (message) => {
        observer.next(message);
      });
    });
  }

  // wird nicht verwendet
  public response_callback = () =>{
    return Observable.create((observer) => {
      this.socket.on('callback', (message) => {
        observer.next(message);
      });
    });
  }
  // wird nicht verwendet
  public response_getFromDB = () =>{
    return Observable.create((observer) => {
      this.socket.on('getFromDB', (message) => {
        observer.next(message);
      });
    });
  }
   // wird nicht verwendet
  public response_insertInDB = () =>{
    return Observable.create((observer) => {
      this.socket.on('insertInDB', (message) => {
        observer.next(message);
      });
    });
  }
  public response_updateInDB = () =>{
    return Observable.create((observer) => {
      this.socket.on('updateInDB', (message) => {
        observer.next(message);
      });
    });
  }
  public response_deleteFromDB = () =>{
    return Observable.create((observer) => {
      this.socket.on('deleteFromDB', (message) => {
        observer.next(message);
      });
    });
  }
  public response_getVersammlungen = () =>{
    return Observable.create((observer) => {
      this.socket.on('getVersammlungen', (response) => {
        observer.next(response);
        
      });
    });
  }
  // Wird nicht verwendet
  public response_createNewVersammlung = () =>{
    return Observable.create((observer) => {
      this.socket.on('createNewVersammlung', (response) => {
        observer.next(response);
      });
    });
  }
  // Wird nicht verwendet
  public response_deleteVersammlung = () =>{
    return Observable.create((observer) => {
      this.socket.on('deleteVersammlung', (response) => {
        observer.next(response);
      });
    });
  }
  // Wird nicht verwendet
  public response_setVersammlungAbgeschlossen = () =>{
    return Observable.create((observer) => {
      this.socket.on('setVersammlungAbgeschlossen', (response) => {
        observer.next(response);
      });
    });
  }

  public response_getTagesordnungspunkte = () =>{
    return Observable.create((observer) => {
      this.socket.on('getTagesordnungspunkte', (response) => {
        observer.next(response);
      });
    });
  }

  public response_deleteTagesordnungspunkt = () =>{
    return Observable.create((observer) => {
      this.socket.on('deleteTagesordnungspunkt', (response) => {
        observer.next(response);
      });
    });
  }

  // wird nicht verwendet
  public response_createNewTagesordnungspunkt = () =>{
    return Observable.create((observer) => {
      this.socket.on('createNewTagesordnungspunkt', (response) => {
        observer.next(response);
      });
    });
  }

  // Teilnehmer
  public response_getTeilnehmer = () =>{
    return Observable.create((observer) => {
      this.socket.on('getTeilnehmer', (response) => {
        observer.next(response);
      });
    });
  }

  // Wird nicht verwendet
  public response_setTeilnehmer = () =>{
    return Observable.create((observer) => {
      this.socket.on('setTeilnehmer', (response) => {
        observer.next(response);
      });
    });
  }

  // Wird nicht verwendet
  public response_getTagesordnungsPunkteForTeilnehmer = () =>{
    return Observable.create((observer) => {
      this.socket.on('getTagesordnungsPunkteForTeilnehmer', (response) => {
        observer.next(response);
      });
    });
  }

  //Barcode
  public response_teilnehmerUeberBarcodeAngemeldet = () =>{
    return Observable.create((observer) => {
      this.socket.on('teilnehmerUeberBarcodeAngemeldet', (response) => {
        observer.next(response);
      });
    });
  }

  // Abstimmung
  public response_abstimmung = () =>{
    return Observable.create((observer) => {
      this.socket.on('abstimmung', (response) => {
        observer.next(response);
      });
    });
  }

  // cl: Anmeldung eines clients
  public response_anmeldung = () =>{
    return Observable.create((observer) => {
      this.socket.on('anmeldung', (response) => {
        observer.next(response);
      });
    });
  }

  // cl: Anmeldung eines clients
  public response_abmeldung = () =>{
    return Observable.create((observer) => {
      this.socket.on('abmeldung', (response) => {
        observer.next(response);
      });
    });
  }

  // cl: Validierung eines clients
  // wird nicht mehr verwendet
  public response_checkValidAnmeldung = () =>{
    return Observable.create((observer) => {
      this.socket.on('checkValidAnmeldung', (response) => {
        observer.next(response);
      });
    });
  }
  // cl: querySelectedVersammlung
  public response_querySelectedVersammlung = () =>{
    return Observable.create((observer) => {
      this.socket.on('querySelectedVersammlung', (response) => {
        observer.next(response);
      });
    });
  }
  // 
  
  // cl: Get active Tagesordnungspunkt and relevant Data for a Versammlung
  public response_querySelectedTagesordnungspunkt = () =>{
    return Observable.create((observer) => {
      this.socket.on('querySelectedTagesordnungspunkt', (response) => {
        observer.next(response);
      });
    });
  }

  // cl: Get Settings From Db
  public response_getSettingsFromDb = () =>{
    return Observable.create((observer) => {
      this.socket.on('getSettingsFromDb', (response) => {
        observer.next(response);
      });
    });
  }

  public response_handleGeschaeftsordnungsbeschluss = () =>{
    return Observable.create((observer) => {
      this.socket.on('handleGeschaeftsordnungsbeschluss', (response) => {
        observer.next(response);
      });
    });
  }

  // Handle Email  
  public response_email = () =>{
    return Observable.create((observer) => {
      this.socket.on('email', (response) => {
        observer.next(response);
      });
    });
  }

  // cl: Get Settings From Server
  public response_getSettingsFromServer = () =>{
    return Observable.create((observer) => {
      this.socket.on('getSettingsFromServer', (response) => {
        observer.next(response);
      });
    });
  }

  // refresh
  public response_refresh = () =>{
    return Observable.create((observer) => {
      this.socket.on('refresh', (response) => {
        observer.next(response);
      });
    });
  }

  // Broadcast
  public response_broadcast = () =>{
    return Observable.create((observer) => {
      this.socket.on('broadcast', (response) => {
        observer.next(response);
      });
    });
  }

  // getErklaerungComponentData
  public response_getErklaerungComponentData = () =>{
    return Observable.create((observer) => {
      this.socket.on('getErklaerungComponentData', (response) => {
        observer.next(response);
      });
    });
  }

  // getErklaerungComponentData
  public response_getErgebnissComponentData = () =>{
    return Observable.create((observer) => {
      this.socket.on('getErgebnissComponentData', (response) => {
        observer.next(response);
      });
    });
  }

  public response_vertretungen = (): Observable<any> => {
    return new Observable((observer) => {
      this.socket.on('vertretungen', (response) => {
        observer.next(response);
      });
    });
  };

  public getSocketObservable(cmd: string) : Observable<unknown> {
    return new Observable((observer) => {
      this.socket.on(cmd, (message) => {
        observer.next(message);
      });
    });
  }





  public readSettings()
  {
    this.logger.info("read Settings 1");
    const request = new XMLHttpRequest();
    request.addEventListener('load', configListener);
    request.addEventListener('error', configFailed);
    request.open('GET', './assets/settings/settings.json');
    request.send();
    this.logger.info("socket.service.ts");
    function configListener() {
      try 
      {
        this.logger.info("read Settings 2");
        const configuration = JSON.parse(this.responseText);
        this.settings = configuration; window['settings'] = this.settings;
        this.logger.info(this.settings, "readed Settings in socket.service.ts");
      } catch (error) {
        this.logger.error(error);
      }
    }
    
    function configFailed(evt) {
      this.logger.error('Error: retrieving config.json');
    }
  }
  

  public startConnection(currentUserLoginParameter: loginParameters = null , hausverwalter = null, options: any = {})
  {
    try
    {
      if(Object.keys(window['settings']).length > 0 && window['settings'].hasOwnProperty("server"))
      {
        this.settings = window['settings']['server'];
      }
      if (environment.production && this.settings.prefix === "https") {
        this.settings.http_port = 443;
      }
      const url = this.settings.prefix+"://"+this.settings.server_ip+":"+this.settings.http_port;
      if(this.socket === null)
      {
        this.socket = io(url);
        this.logger.info(url, "socket for Mobile-Client started, connected to");
        this.initOnConnectListener(currentUserLoginParameter , hausverwalter , url , options);
      }
      else
      {
        if(this.socket.connected === false)
        {
          this.logger.info(this.socket, "Socket is disconnected - try to connect again");
          this.socket = io(url);
          this.initOnConnectListener(currentUserLoginParameter , hausverwalter , url , options);
        }
        else
        {
          this.logger.info(this.socket, `Socket already initialized and connected to ${this.settings.server_ip}:${this.settings.http_port}} `);
        }
      }
    } catch(e){this.logger.error(e);}
  }
  
  initOnConnectListener(currentUser : loginParameters = null, hausverwalter = null, url: any = "" , options: any = {})
  {
    const self = this;
    const shortCurrentUser = {
      nachname: currentUser.nachname? currentUser.nachname: '' ,
      passwort: currentUser.passwort? currentUser.passwort: '' ,
      deleteVertretung : currentUser.deleteVertretung,
      client_type : 'client',
      cmd: "clientAnmeldung"
    }
    if(currentUser.hasOwnProperty("vertreter_nachname") && currentUser.vertreter_nachname != "" && currentUser.vertreter_nachname != hausverwalter.name)
    {
      shortCurrentUser.nachname = currentUser.vertreter_nachname;
    }
    this.socket.on('connect', (response: any = null) => {
      
        self.logger.info(url, "[initOnConnectListener] socket for mobile-client started, connected to:")
        self.logger.info(shortCurrentUser, "try to authenticate with credentials");
        self.eventEmitterService.eventEmitter.next({cmd: e_event_emitter_enum.onSocketInit , data: {}});   
        
        if(options && Object.keys(options).length > 0)
        {
          if(options.cmd === e_command_enum.by_checkAuthUser)
          {
            self.eventEmitterService.eventEmitter.next({cmd: e_event_emitter_enum.startNotificationTimer , data: {}});
          }
        }
          this.socket.emit(e_command_enum.authentication , shortCurrentUser);
    });
  }

  public getConnectionState()
  {
    if(this.socket != null)
    {
      return this.socket.connected
    }
    else
    {
      return false;
    }
    
  }
  public stopConnection()
  {
    try
    {
      this.socket.disconnect();
      this.socket = null;
      this.logger.info(this.socket, "socket closed");
    }
    catch(e){this.logger.error(e);}
  }
  
  public closeConnection(options = {})
  {
    this.logger.info("closeConnection")
    this.socket.close();
    this.socket.disconnect();
  }
}
