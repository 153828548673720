/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-prototype-builtins */
import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { DataExchangeService } from 'src/app/_services/data-exchange.service';
import { SocketService } from 'src/app/_services/socket.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { LoggerService } from 'src/app/services/logger.service';
import { abstimmung_next_commands, e_command_enum, e_event_emitter_enum } from 'src/app/_models/enum';
import {EventEmitterService} from '../../../services/event-emitter.service';
import {AuthenticationService} from '../../../_services/authentication.service';
import {AlertService} from '../../../_services/alert.service';
import {SambaService} from "../../../services/samba.service";

declare let HelperFunctions: any;


@Component({
  selector: 'app-next',
  templateUrl: './next.component.html',
  styleUrls: ['./next.component.scss']
})

export class NextComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('textOben', {static: false}) textOben: ElementRef;
  @ViewChild('textUnten', {static: false}) textUnten: ElementRef;
  // Listener
  private eventEmitterSubscription: Subscription;
  private getSelectedVersammlungFromDexListener: Subscription;
  private getTeilnehmerListener: Subscription;
  private getSelectedTagesordnungspunktListener: Subscription;
  // private querySelectedTagesordnungspunkt: Subscription;

  // Vars
  public currentUser: any;
  public buttons: any = {logout: false};
  public selectedVersammlung: any = { text: ''};
  public selectedTagesordnungspunkt: any;
  public abstimmungen : any;
  public einheiten : any;
  public gemeinschaftstypen : any;
  public showUhrIcon  = true;
  public texte = {
    oben : "",
    icon : "",
    unten : "",
    nextAbstimmung : "Nächste Abstimmung",
    vielenDank: "Vielen Dank für Ihre Abstimmung.",
    teilnahme: "Vielen Dank für Ihre Teilnahme.",
    keineEinheiten: "Sie besitzen keine Einheiten für diesen Abstimmungsschritt.",
    unten1: "Bitte haben Sie noch etwas Geduld. Der Hausverwalter verkündet die Ergebnisse in Kürze.",
    unten2: "Bitte haben Sie noch etwas Geduld. Die nächste Abstimmung startet in Kürze.",
    unten3: "Die Versammlung ist nun beendet. Wir freuen uns darauf Sie bei der nächsten Versammlung wieder bei uns begrüßen zu dürfen."
  }
  public autoLogout : any = {
    duration: 1000*60*2,
    remainTime : 1000*60*2,
    interval : 1000,
    timer : null,
    hasStarted : false
  }

constructor(
  private dex: DataExchangeService,
  // private cos: CookieServiceService,
  private _socketService: SocketService,
  private authenticationService: AuthenticationService,
  private router: Router,
  private route: ActivatedRoute,
  private formBuilder: FormBuilder,
  private activatedRoute: ActivatedRoute,
  private alertService: AlertService,
  private logger: LoggerService,
  private eventEmitterService: EventEmitterService,
  public sambaService: SambaService,
  )
  {
    this.currentUser = this.authenticationService.currentUserValue;
    this.showVielenDankFuerDieAbstimmung();
    this.getRouterLinkParameter();
  }

ngOnDestroy(): void
{
  this.eventEmitterSubscription.unsubscribe();
  this.getSelectedVersammlungFromDexListener.unsubscribe();
  this.getTeilnehmerListener.unsubscribe();
  this.getSelectedTagesordnungspunktListener.unsubscribe();
  this.eventEmitterService.eventEmitter.next({cmd: e_command_enum.showHeader , data: {display: "inherit"}});
  // this.dex.stopRefreshTimer();
  if(this.autoLogout.hasStarted == true)
  {
    // console.log("hier teilnehmer abmelden")
    this.destroyLogoutTimer();
    this.eventEmitterService.eventEmitter.next({cmd: e_event_emitter_enum.teilnehmerAbmelden , data: {}});
  }
}

ngAfterViewInit(): void
{
  this.dex.startRefreshTimer();
}

ngOnInit(): void
{
  this.initListener();
  this.loadDataFromSocket();

  // Ablauf
  // 1: querySelectedVersammlung über socket-listener-component => dex.saveSelectedVersammlung
  // 2: hier getSelectedVersammlungFromDexListener => loadDataFromSocketLater
  // 3: querySelectedTagesordnungspunkt => dex.querySelectedTagesordnungspunkt => dex.saveSelectedTagesordnungspunkt
  // 4: getSelectedTagesordnungspunktListener => navigate to
}

getRouterLinkParameter()
{
  const cmd =  this.activatedRoute.snapshot.paramMap.get("cmd");
  this.handleRouterLinkParameter(cmd);
}

showSieHabenKeineEinheiten()
{
  this.texte.oben = this.texte.keineEinheiten;
  this.texte.unten = this.texte.unten2;
  this.showUhrIcon = true;
}

showVielenDankFuerDieAbstimmung()
{
  this.texte.oben = this.texte.vielenDank;
  this.texte.unten = this.texte.unten1;
  this.showUhrIcon = true;
}
showNaechsteAbstimmung()
{
  this.texte.oben = this.texte.nextAbstimmung;
  this.texte.unten = this.texte.unten2;
  this.showUhrIcon = true;
}
showVielenDankFuerDieTeilnahme()
{
  this.texte.oben = this.texte.teilnahme;
  this.texte.unten = this.texte.unten3;
  this.showUhrIcon = false;
  this.startLogoutTimer();
}
loadDataFromSocket()
{
  this.dex.querySelectedVersammlung("next_component")
  this.eventEmitterService.eventEmitter.next({cmd: e_command_enum.showHeader , data: {display: "inherit"}});
}

loadDataFromSocketLater()
{
    this._socketService.querySelectedTagesordnungspunkt({
      cmd: "querySelectedTagesordnungspunkt",
      versammlung_id: this.selectedVersammlung.versammlung_id,
      teilnehmer_id: this.currentUser.teilnehmer_id,
      active: true,
      onlyPreview: true
    });
}

initListener()
{
  // GET Tagesordnungspunkt Daten
    // GET Teilnehmer Daten
    this.getTeilnehmerListener = this.dex.currentSelectedTeinehmerData.subscribe( async (data : any )=>{
      try
      {
        if(HelperFunctions.Misc.isJson2(data) == 'string' && data.length > 0)
        {
            data = JSON.parse(data);
            if(data.response)
            {
                await this.getTeilnehmerHandler(data);
            }
        }
      }
      catch(e) { this.logger.error(e); }
    });

    // Event Emitter
    this.eventEmitterSubscription = this.eventEmitterService.eventEmitter.subscribe(msg => {
      try
      {
        if(msg.hasOwnProperty("cmd"))
        {
          const cmd = msg.cmd;
          if(cmd === e_event_emitter_enum.connect)
          {
            this.logger.info(msg, "Connect")
          }
          if(cmd === e_event_emitter_enum.disconnect)
          {
            this.logger.info(msg, "Disconnect")
          }
          if(cmd === abstimmung_next_commands.showVielenDankFuerDieAbstimmung)
          {
            this.showVielenDankFuerDieAbstimmung();
          }
          if(cmd === abstimmung_next_commands.showNaechsteAbstimmung)
          {
            this.showNaechsteAbstimmung();
          }
          if(cmd === abstimmung_next_commands.showVielenDankFuerDieTeilnahme)
          {
            this.showVielenDankFuerDieTeilnahme();
          }
          if(cmd === abstimmung_next_commands.showSieHabenKeineEinheiten)
          {
            this.showSieHabenKeineEinheiten();
          }
        }
      }catch(e){this.logger.error(e);}

    });

     // getSelectedVersammlung
  this.getSelectedVersammlungFromDexListener = this.dex.currentSelectedVersammlung.subscribe( async (data : any )=>{
    try
    {
      if(data=== null || data === '' || data ==='undefined' || Object.keys(data).length === 0)
      {
        this.dex.querySelectedVersammlung("from next");
      }
      else
      {
        this.selectedVersammlung = data;
      }
    }
    catch(e){this.logger.error(e);}
  });

  // GET Tagesordnungspunkt Daten
  // Code -1 - kein tP | 0 TN hat keine Einheit | 1: valide, hat schon was abgestimmt | 2: TN hat noch nichts abgestimmt
  this.getSelectedTagesordnungspunktListener = this.dex.currentSelectedTagesordnungsPunkt.subscribe( async (data : any )=>{
    try
    {
      if(data && data.code)
      {
        switch(data.code)
        {
          case -1:
            // Kein Tagesordnungspunkt durch das Dashboard angewählt
          break;
          case 0:
            // Teilnehmer besitzt keine Einheiten
          break;
          case 1:
            // Valide, es liegen bereits Abstimmungen vor
            // this.gemeinschaftstypen = data.gemeinschaftstypen;
            // this.einheiten = data.einheiten;
            // this.abstimmungen = data.abstimmungen;
            // this.selectedTagesordnungspunkt = data.tagesordnungspunkt;
          break;
          case 2:
            // Teilnehmer hat noch keine Abstimmung getätigt
            // this.gemeinschaftstypen = data.gemeinschaftstypen;
            // this.einheiten = data.einheiten;
            // this.abstimmungen = data.abstimmungen;
            // this.selectedTagesordnungspunkt = data.tagesordnungspunkt;
          break;
          case 3:
          break;

        }
        if(data.code === 1 || data.code === 2)
        {
          // this.router.navigate(['mobile-client/abstimmung/abstimmung-main']);
        }

      }
    }
    catch(e) { this.logger.error(e); }
  });
}

  logout()
  {
    this.authenticationService.logout();
    this.router.navigate(['/mobile-client/login']);
  }

  deleteUser(id: number)
  {
  }

  async getTeilnehmerHandler(data)
  {
  }

  handleRouterLinkParameter(cmd)
  {
    this.logger.info(cmd, "[next.component] handleRouterLinkParameter");
    if(cmd === abstimmung_next_commands.showNaechsteAbstimmung)
    {
      this.showNaechsteAbstimmung();
    }
    if(cmd === abstimmung_next_commands.showVielenDankFuerDieTeilnahme)
    {
      this.showVielenDankFuerDieTeilnahme();
    }
    if(cmd === abstimmung_next_commands.showVielenDankFuerDieAbstimmung)
    {
      this.showVielenDankFuerDieAbstimmung();
    }
    if(cmd === abstimmung_next_commands.showSieHabenKeineEinheiten)
    {
      this.showSieHabenKeineEinheiten();
    }
  }

  public autoLogoutIntervalFunc(autoLogout : any = {}, evim: EventEmitterService)
  {
    if(autoLogout.remainTime > 999)
      {
        autoLogout.remainTime -= 1000;
      }
      else
      {
          clearInterval(autoLogout.timer);
          evim.eventEmitter.next({cmd: e_event_emitter_enum.teilnehmerAbmelden , data: {}});
      }
  }

  public startLogoutTimer(data : any = {})
  {
    this.autoLogout.hasStarted = true;
    this.autoLogout.remainTime = this.autoLogout.duration;
    this.autoLogout.timer = setInterval(
      this.autoLogoutIntervalFunc
      , this.autoLogout.interval, this.autoLogout, this.eventEmitterService );
  }

  public resetLogoutTimer(data : any = {})
  {
    this.autoLogout.remainTime = this.autoLogout.duration;
  }

  public destroyLogoutTimer()
  {
    if(this.autoLogout.hasStarted === true)
    {
      clearInterval(this.autoLogout.timer);
    }
  }

}
