/* eslint-disable no-prototype-builtins */
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, BehaviorSubject, Observable } from 'rxjs';
import { e_command_enum, e_event_emitter_enum } from '../_models/enum';
import { DataExchangeService } from '../_services/data-exchange.service';
import { SocketService } from '../_services/socket.service';
import { LoggerService } from './logger.service';
import {EventEmitterService} from './event-emitter.service';
import {AuthenticationService} from '../_services/authentication.service';
declare let HelperFunctions: any;

@Injectable({
  providedIn: 'root'
})
export class SambaService implements OnInit, OnDestroy{

  // LISTENERS
  private videoStreamListener : Subscription;
  private eventEmitterSubscription : Subscription ;
  private currentUserListener: Subscription;
  private getSelectedVersammlungFromDexListener: Subscription;
  // VARS


  public currentUser: any = null;
  public selectedVersammlung: any = {};
  public router: Router;
  public use_video_conference  = true;

  // public displayVideoConference : boolean = false;
  public displayVideoConferenceSrc : BehaviorSubject<any>;
  public currentDisplayVideoConference : Observable<any>;
  constructor(
    private logger: LoggerService,
    private authenticationService : AuthenticationService,
    private _socketService : SocketService,
    private dex: DataExchangeService,
    private eventEmitterService: EventEmitterService
  )
  {
    // Disabling Samba Container first
    this.displayVideoConferenceSrc = new BehaviorSubject<any>(false);
    this.currentDisplayVideoConference =  this.displayVideoConferenceSrc.asObservable();

  }
  setDex(dex: DataExchangeService){this.dex = dex;}
  ngOnInit(): void
  {
    this.initListener();
  }


  ngOnDestroy(): void
  {
    this.currentUserListener != undefined ? this.currentUserListener.unsubscribe() : null;
    this.getSelectedVersammlungFromDexListener != undefined ? this.getSelectedVersammlungFromDexListener.unsubscribe() : null;
    this.videoStreamListener != undefined ?  this.videoStreamListener.unsubscribe() : null;
    this.eventEmitterSubscription != undefined ? this.eventEmitterSubscription.unsubscribe() : null;
  }

  initListener()
  {

      this.currentUserListener = this.authenticationService.currentUser.subscribe((userData: any) => {
        try
        {
          this.currentUser = userData;
          this.updateDisplayVideoConference();
        }catch(e){this.logger.error(e);}
      });


      // Event Emitter
      this.eventEmitterSubscription = this.eventEmitterService.eventEmitter.subscribe(msg => {
        try
        {
          // console.log("####### samba-service ",msg)
          if(msg.hasOwnProperty("cmd"))
          {
            const cmd = msg.cmd;

            if(cmd === e_command_enum.login)
            {
              this.initListenerLater();
              this.updateDisplayVideoConference();
            }

            if(cmd === e_event_emitter_enum.getSettingsFromServer)
            {
              if(msg.data.filter(s=>{return s.key == "use_video_conference"}).length > 0 )
              {
                if(HelperFunctions.Misc.castBoolean( msg.data.filter(s=>{return s.key == "use_video_conference"})[0]['value'] ) === true && (this.dex?.selectedVersammlung?.versammlungstyp === 1 || this.dex?.selectedVersammlung?.versammlungstyp === 3)) {
                  this.use_video_conference = true
                } else {
                  this.use_video_conference = false
                }
                this.updateDisplayVideoConference();
              }
            }
          }
        }catch(e){
          this.logger.error(e);
        }
      });
  }

  initListenerLater()
  {


      this.getSelectedVersammlungFromDexListener = this.dex.currentSelectedVersammlung.subscribe( async (data : any )=>{
        try
        {
          if( (data=== null || data === '' || data === {} || data ==='undefined')  && this.authenticationService.currentUserValue)
          {
            this.dex.querySelectedVersammlung("from Lockedscreen");
          }
          else
          {
            this.selectedVersammlung = data;
            this.updateDisplayVideoConference();
            // this.logger.info(data, "dex getselectedVersammlung");
          }
        }
        catch(e){this.logger.error(e);}
      });
  }

  updateDisplayVideoConference()
  {
    let display  = false;
    try
    {
        if( this.currentUser && this.currentUser != null &&
            this.currentUser.hasOwnProperty("personalSessionLink") && this.currentUser.personalSessionLink.length > 5 &&
            this.dex?.selectedVersammlung?.streamingSessionId &&
            this.use_video_conference == true && (this.dex?.selectedVersammlung?.versammlungstyp === 1 || this.dex?.selectedVersammlung?.versammlungstyp === 3)
        )
        {
          display = true;
        }else{
          display = false;
        }
    }
    catch (error)
    {
      console.log(error)
    }
    finally{
      this.displayVideoConferenceSrc.next(display);
    }
  }
}
