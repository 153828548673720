import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Teilnehmer } from '../_models';

@Injectable({ providedIn: 'root' })
export class TeilnehmerService {
    constructor(private http: HttpClient) { }

    getAll() {
        // return this.http.get<User[]>(`/users`);
    }

    register(teilnehmer: Teilnehmer) {
        // return this.http.post(`/users/register`, user);
    }

    delete(teilnehmer_id: number) {
        // return this.http.delete(`/users/${id}`);
    }
}