/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-abstimmung',
  templateUrl: './abstimmung.component.html',
  styleUrls: ['./abstimmung.component.scss']
})
export class AbstimmungComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
