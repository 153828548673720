/* eslint-disable max-len */
import { NgModule, Pipe } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginComponent} from './components/login/login.component';
import {AppComponent} from './app.component';
import { AlertComponent } from './components/alert/alert.component';
import {LockedscreenComponent} from './components/lockedscreen/lockedscreen.component';
import { AuthGuard } from './_helpers/auth.guard';
import { AbstimmungComponent } from './components/abstimmung/abstimmung.component';
import { MainComponent } from './components/abstimmung/main/main.component';
import { FinishComponent } from './components/abstimmung/finish/finish.component';
import { TopbarComponent } from './components/topbar/topbar.component';
import { NextComponent } from './components/abstimmung/next/next.component';
import { AbstimmungGOBComponent } from './components/abstimmung/abstimmung-gob/abstimmung-gob.component';
import { SambaComponent } from './components/samba/samba.component';
import { ErklaerungComponent } from './components/erklaerung/erklaerung.component';
import { ErgebnissComponent } from './components/ergebniss/ergebniss.component';
import { EinfacheMehrheitComponent } from './components/ergebniss/einfache-mehrheit/einfache-mehrheit.component';
import { DoppelteQualifizierungSpezialComponent } from './components/ergebniss/doppelte-qualifizierung-spezial/doppelte-qualifizierung-spezial.component';
import { DoppelteQualifizierungComponent } from './components/ergebniss/doppelte-qualifizierung/doppelte-qualifizierung.component';
import { QualifizierteMehrheitComponent } from './components/ergebniss/qualifizierte-mehrheit/qualifizierte-mehrheit.component';
import {EinfacheMehrheitAtComponent} from './components/ergebniss/einfache-mehrheit-at/einfache-mehrheit-at.component';


export const routes: Routes = [
  {
    path: '',
    component: AppComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'topbar',
    component: TopbarComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'mobile-client/login',
    component: LoginComponent
  },
  {
    path: 'mobile-client/main-app',
    component: AppComponent
  },
  {
    path: 'mobile-client/lockedscreen',
    component: LockedscreenComponent
  },
  {
    path: 'mobile-client/erklaerung',
    component: ErklaerungComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'mobile-client/ergebniss',
    component: ErgebnissComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'mobile-client/abstimmung-next',
    component: NextComponent
    // canActivate: [AuthGuard]
  },
  {
    path: 'mobile-client/abstimmung',
    component: AbstimmungComponent,
    canActivate: [AuthGuard] ,
    children:[
      { path: 'abstimmung-gob', canActivate: [AuthGuard] ,  component: AbstimmungGOBComponent},
      { path: 'abstimmung-main', canActivate: [AuthGuard] ,  component: MainComponent},
      { path: 'abstimmung-next', canActivate: [AuthGuard] ,  component: NextComponent},
      { path: 'abstimmung-finish',canActivate: [AuthGuard] ,  component: FinishComponent},
      { path: '*', redirectTo: '/mobile-client/abstimmung/abstimmung-main', pathMatch: 'full' }

    ]
  },

  // otherwise redirect to home
  // { path: '**', redirectTo: 'mobile-client' }


];
@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule],
  declarations: [

  ]
})
export class AppRoutingModule { }
export const appRoutingProviders: any[] = [];
export const routing = RouterModule.forRoot(routes);

export const routingComponents = [
  LoginComponent,
  AppComponent,
  AlertComponent,
  LockedscreenComponent,
  AbstimmungComponent,
  NextComponent,
  FinishComponent,
  MainComponent,
  TopbarComponent,
  AbstimmungGOBComponent,
  // StandardModalComponent,
  SambaComponent,
  ErklaerungComponent,
  ErgebnissComponent,
  EinfacheMehrheitComponent,
  QualifizierteMehrheitComponent,
  DoppelteQualifizierungComponent,
  DoppelteQualifizierungSpezialComponent,
  EinfacheMehrheitAtComponent
];
