import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { AlertService } from '../../_services/alert.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
  private subscription: Subscription = null;
  message: any;

  constructor(private alertService: AlertService) { }

  ngOnInit() {
      this.subscription != null ? this.subscription.unsubscribe(): null;
      this.subscription = this.alertService.getAlert()
          .subscribe(message => {
              switch (message && message.type) {
                case 'success':
                    message.cssClass = 'alert alert-success';
                    break;
                case 'warning':
                    message.cssClass = 'alert alert-warning';
                    break;
                case 'info':
                    message.cssClass = 'alert alert-info animated--fade-in';
                    break;
                case 'error':
                    message.cssClass = 'alert alert-danger';
                    break;
              }

              this.message = message;
          });
  }

  ngOnDestroy() 
  {
      try 
      {
        this.subscription != null ? this.subscription.unsubscribe() : null;
      } 
      catch (error) 
      {
        console.log(error)
      }
  }
}