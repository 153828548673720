<ng-container *ngIf="selectedTagesordnungspunkt != null">
    <ng-container [ngSwitch]="selectedTagesordnungspunkt?.qualifizierungstyp?.stimmgeber?.s1?.key">
        <!-- Alle Stimmgeber / Unterscheidung findet in ts file statt -->
        <div class="row justify-content-center">
            <div class="col col-12 abstimmung-container" *ngIf="abstimmErgebnisse.meaStimmenGesamt">
                <ng-container>
                    <!-- Project Card Example -->
                    <div class="row pb-1 justify-content-start ft-150">
                        <!-- <div class="col justify-content-center  align-self-center">   
                        </div> -->
                        <div class="col col-8 offset-md-4 align-self-center legend-top">
                            <span class=""></span>
                        </div>
                        <!-- <div class="col col-3 justify-content-start align-self-center">
                            <span [innerHTML]="this.abstimmErgebnisse_s1.legend_side"></span>
                        </div> -->
                    </div>

                    <!-- JA -->
                    <div class="row justify-content-end ja ft-150 mt-2">
                        <div class="col align-self-center">
                            <div class="row justify-content-end prefix-label ">
                                <span class="text-right">{{this.abstimmErgebnisse_s1.ja.geteilt}}<small>  Ja</small></span>
                            </div>
                        </div>
                        <div class="col col-8">
                            <ngb-progressbar class="vulcavo-abstimmung-chart ft-100" [showValue]="false" type="info" [value]="abstimmErgebnisse_s1.ja.mea" [max]="this.abstimmErgebnisse_s1.meaStimmenGesamt">{{globals.germanDecimal2(abstimmErgebnisse_s1.ja.mea)}}</ngb-progressbar>
                        </div>
                        <!-- <div class="col col-3 align-self-center max-value prefix-label">
                            <span>{{this.abstimmErgebnisse_s1.ja.mea}}</span>
                        </div> -->
                    </div>

                    <!-- NEIN -->
                    <div class="row nein justify-content-end mt-1 ft-150">
                        <div class="col align-self-center">
                            <div class="row justify-content-end prefix-label">
                                <span class="text-right">{{this.abstimmErgebnisse_s1.nein.geteilt}} <small>Nein</small> </span>
                            </div>
                        </div>
                        <div class="col col-8">
                            <ngb-progressbar class="vulcavo-abstimmung-chart ft-100" [showValue]="false" type="info" [value]="abstimmErgebnisse_s1.nein.mea" [max]="this.abstimmErgebnisse_s1.meaStimmenGesamt">{{globals.germanDecimal2(abstimmErgebnisse_s1.nein.mea)}}</ngb-progressbar>
                        </div>
                        <!-- <div class="col col-3 align-self-center max-value prefix-label">
                            <span>{{abstimmErgebnisse_s1.nein.mea}}</span>
                        </div> -->
                    </div>
                    <ng-container>
<!--                        *ngIf="selectedTagesordnungspunkt?.qualifizierungstyp?.stimmgeber?.s1?.relation == 1">-->
                        <!-- ENTHALTUNG -->
                        <div class="row enthaltung justify-content-end mt-1 ft-150">
                            <div class="col align-self-center">
                                <div class="row justify-content-end prefix-label">
                                    <span class="text-right">{{this.abstimmErgebnisse_s1.enthaltung.geteilt}} <small>Enthaltung</small></span>
                                </div>
                            </div>
                            <div class="col col-8">
                                <ngb-progressbar class="vulcavo-abstimmung-chart ft-100" [showValue]="false" type="info" [value]="abstimmErgebnisse_s1.enthaltung.mea" [max]="this.abstimmErgebnisse_s1.meaStimmenGesamt">{{globals.germanDecimal2(abstimmErgebnisse_s1.enthaltung.mea)}}</ngb-progressbar>
                            </div>
                            <!-- <div class="col col-3 align-self-center max-value prefix-label">
                                <span>{{abstimmErgebnisse_s1.enthaltung.mea}}</span>
                            </div> -->
                        </div>

                        <!-- ANWESEND -->
                        <!-- Aufsplittung nicht abgestimmt anwesend und abwesend -->
                        <!-- <div class="row nicht-abgestimmt-anwesend justify-content-end mt-1">
                            <div class="col align-self-center">
                                <div class="row justify-content-end prefix-label">
                                    <span class="text-right"><small>{{this.abstimmErgebnisse_s1.anwesend.geteilt}} <i class="fas fas fa-circle anwesend"></i><i class="fas fas fa-circle pl-1 angemeldet"></i></small> Nicht abgestimmt anwesend </span>
                                </div>                    
                            </div>
                            <div class="col">
                                <ngb-progressbar class="vulcavo-abstimmung-chart" [showValue]="false" type="info" [value]="abstimmErgebnisse_s1.anwesend.mea" [max]="abstimmErgebnisse_s1.meaStimmenGesamt">{{abstimmErgebnisse_s1.anwesend.mea}}</ngb-progressbar>
                            </div>
                            <div class="col col-3 align-self-center max-value prefix-label">           
                                <span>{{abstimmErgebnisse_s1.meaStimmenGesamt}}</span>
                            </div>
                        </div> -->

                        <!-- ABWESEND -->
                        <div class="row nicht-abgestimmt-abwesend justify-content-end mt-1 ft-150">
                            <div class="col align-self-center">
                                <div class="row justify-content-end prefix-label">
                                    <span class="text-right">{{this.abstimmErgebnisse_s1.abwesend.geteilt}} <small>  Abwesend</small></span>
                                </div>
                            </div>
                            <div class="col col-8">
                                <ngb-progressbar class="vulcavo-abstimmung-chart ft-100" [showValue]="false" type="info" [value]="abstimmErgebnisse_s1.abwesend.mea" [max]="abstimmErgebnisse_s1.meaStimmenGesamt">{{globals.germanDecimal2(abstimmErgebnisse_s1.abwesend.mea)}}</ngb-progressbar>
                            </div>
                            <!-- <div class="col col-3 align-self-center max-value prefix-label">           
                                <span>{{abstimmErgebnisse_s1.meaStimmenGesamt}}</span>
                            </div> -->
                        </div>

                    </ng-container>

                    <!-- BOTTOM -->
                    <ng-container>
                        <div class="row bottom_legend justify-content-end ft-125 mt-2">
                            <div class="col align-self-center">
                            </div>
                            <div class="col col-8">
                                <!-- <hr> -->
                                <div class="row justify-content-end">
                                    <div class="col-xs-auto"><span [innerHTML]="this.abstimmErgebnisse_s1.legend_bottom"></span></div>
                                </div>
                            </div>
                        </div>
                    </ng-container>


                    <!-- ANWESEND -->
                    <!-- Aufsplittung nicht abgestimmt anwesend und abwesend -->
                    <!-- <div class="row nicht-abgestimmt-anwesend justify-content-end mt-1">
                        <div class="col align-self-center">
                            <div class="row justify-content-end prefix-label">
                                <span class="text-right"><small>{{this.abstimmErgebnisse_s1.anwesend.geteilt}} <i class="fas fas fa-circle anwesend"></i><i class="fas fas fa-circle pl-1 angemeldet"></i></small> Nicht abgestimmt anwesend </span>
                            </div>                    
                        </div>
                        <div class="col">
                            <ngb-progressbar class="vulcavo-abstimmung-chart" [showValue]="false" type="info" [value]="abstimmErgebnisse_s1.anwesend.mea" [max]="abstimmErgebnisse_s1.meaStimmenGesamt">{{abstimmErgebnisse_s1.anwesend.mea}}</ngb-progressbar>
                        </div>
                        <div class="col col-3 align-self-center max-value prefix-label">           
                            <span>{{abstimmErgebnisse_s1.meaStimmenGesamt}}</span>
                        </div>
                    </div> -->
                    <!-- ABWESEND -->
                    <!-- <div class="row nicht-abgestimmt-abwesend justify-content-end mt-1">
                        <div class="col align-self-center">
                            <div class="row justify-content-end prefix-label">
                                <span class="text-right"><small>{{this.abstimmErgebnisse_s1.abwesend.geteilt}} </small>  Abwesend</span>
                            </div>                    
                        </div>
                        <div class="col">
                            <ngb-progressbar class="vulcavo-abstimmung-chart" [showValue]="false" type="info" [value]="abstimmErgebnisse_s1.abwesend.mea" [max]="abstimmErgebnisse_s1.meaStimmenGesamt">{{abstimmErgebnisse_s1.abwesend.mea}}</ngb-progressbar>
                        </div>
                        <div class="col col-3 align-self-center max-value prefix-label">           
                            <span>{{abstimmErgebnisse_s1.meaStimmenGesamt}}</span>
                        </div>
                    </div> -->
                </ng-container>
            </div>
        </div>
        <!-- Ende alle Stimmgeber -->



    </ng-container>
</ng-container>

    
    