/* eslint-disable no-prototype-builtins */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { LoggerService } from 'src/app/services/logger.service';
import { AnmeldungsStatus, e_command_enum, e_event_emitter_enum } from 'src/app/_models/enum';
import { DataExchangeService } from 'src/app/_services/data-exchange.service';
import { SocketService } from 'src/app/_services/socket.service';
import {EventEmitterService} from '../../services/event-emitter.service';
import {AuthenticationService} from '../../_services/authentication.service';
import {SambaService} from "../../services/samba.service";

declare let HelperFunctions: any;


@Component({
  selector: 'app-ergebniss',
  templateUrl: './ergebniss.component.html',
  styleUrls: ['./ergebniss.component.scss']
})

export class ErgebnissComponent extends AnmeldungsStatus implements OnInit, OnDestroy {

  // LISTENER
  private eventEmitterSubscription: Subscription;
  private getSelectedVersammlungFromDexListener: Subscription;
  private updateInDbListener: Subscription;

  // VARS
  // public tagesordnungspunkteIdString : string;
  public ergebnissData : any = null;
  public routerLinkParameter: any = {};
  public deadReload : number;
  public versammlungLoaded  = false;
  public currentUser: any;
  public selectedVersammlung: any = { text: ''};
  public selectedTagesordnungspunkt: any = {abstimmung_erforderlich: 0};
  public elternTagesordnungspunkt: any = {rangText: ""};
  public displayOptions = {
    fertigAbgestimmt: true,
    anwesendNichtAbgestimmtZuEnthaltung : true
  }
  public panelOpenState: any = false;
  public abstimmErgebnisse : any = {
    ja: {
      value: 10,
      max: 100
    },
    nein: {
      value: 25,
      max: 100
    },
    enthaltung: {
      value: 50,
      max: 100
    },
    nicht_abgestimmt: {
      value: 175,
      max: 4500
    },
    text: ''
  }


  constructor(
    public dex: DataExchangeService,
    private _socketService: SocketService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private logger: LoggerService,
    private eventEmitterService: EventEmitterService,
    public sambaService: SambaService,
  )
  {
    super();
    this.versammlungLoaded === false;
    this.initEventEmitterListener();
    this.getRouterLinkParameter();
  }



  ngOnInit(): void
  {
    this.currentUser = this.authenticationService.currentUserValue;
    this.initListener();
    this.initListenerLater();
    // this.loadDataFromSocket();
    if( (this.currentUser !== null || this.currentUser) && this._socketService.getConnectionState() === true)
    {
      // this.loadDataFromSocketLater();
    }

  }


  ngOnDestroy(): void
  {
    this.eventEmitterSubscription.unsubscribe();
    this.getSelectedVersammlungFromDexListener.unsubscribe();
    this.updateInDbListener.unsubscribe();
    this.versammlungLoaded ? this.versammlungLoaded = false : null;
  }

  getRouterLinkParameter()
  {
    try
    {
      if(this.activatedRoute.snapshot.paramMap.get("data"))
      {
        this.routerLinkParameter = JSON.parse(this.activatedRoute.snapshot.paramMap.get("data"));
        // console.log("map data ",this.routerLinkParameter)
        this.getRouterLinkParameterHandler(this.routerLinkParameter);
      }
    }
    catch(e)    {      this.logger.error(e)    }
  }



  getRouterLinkParameterHandler(routerLinkParameter_ : any = {})
  {
    // console.log("routerLinkParameter_ ", routerLinkParameter_)
    this.logger.info(routerLinkParameter_ , "routerLinkParameter_ ");
    if(this.routerLinkParameter.hasOwnProperty("tagesordnungspunkt_id"))
    {
      this.ergebnissData = this.routerLinkParameter
    }
  }

  public loadDataFromSocketLater(text  = "")
  {
    this.getAbstimmErgebnisse(text);
  }

  initEventEmitterListener()
  {
    // Event Emitter
    this.eventEmitterSubscription = this.eventEmitterService.eventEmitter.subscribe(msg => {
      try
      {
        if(msg.hasOwnProperty("cmd"))
        {
          const cmd = msg.cmd;
          if(cmd === e_event_emitter_enum.connect)
          {
            this.logger.info(msg, "Connect")
          }
          if(cmd === e_event_emitter_enum.disconnect)
          {
            this.logger.info(msg, "Disconnect")
          }
          // if(cmd === e_event_emitter_enum.onSocketInit)
          // {
          // }
          if(cmd === e_event_emitter_enum.onLogin)
          {
            // this.loadDataFromSocketLater();
          }
          if(cmd === e_event_emitter_enum.reloadComponentData)
          {
            this.logger.info(msg, "reload Data");
            this.loadDataFromSocketLater("mobile - e_event_emitter_enum.reloadComponentData");
          }
          if(cmd === e_event_emitter_enum.setErgebnissData)
          {
            this.ergebnissData = msg;
            this.logger.info(this.ergebnissData, "ergebnissdata");
          }
          if(cmd === e_event_emitter_enum.getErgebnissComponentData)
          {
            // console.log(`>>> EM ErgebnissComponentData ! ${msg.data?.submitted?.data?.notice} `,msg)
            this.logger.info(msg , `>>> EM ErgebnissComponentData ! ${msg.data?.submitted?.data?.notice} `);
            // if(msg.data.response.abstimmErgebnisse)
            // {
            //   this.abstimmErgebnisse = Object.assign(this.abstimmErgebnisse, msg.data.response.abstimmErgebnisse);
            //   if(msg.data.response.abstimmErgebnisse.hasOwnProperty("verkuendungsErgebnis"))
            //   {
            //     this.selectedTagesordnungspunkt.verkuendungsErgebnis = msg.data.response.abstimmErgebnisse.verkuendungsErgebnis;
            //   }

            // }
            this.response_getErgebnissComponentDataHandler(msg.data);
          }
        }
      }catch(e){this.logger.error(e);}
    });
  }

  initListener()
  {

    // getSelectedVersammlung
    this.getSelectedVersammlungFromDexListener = this.dex.currentSelectedVersammlung.subscribe( async (data : any )=>{
      try
      {
        if(data=== null || data === '' || data ==='undefined' || Object.keys(data).length === 0)
        {
          if(this.deadReload < 3)
          {
            this.logger.info(data, "getSelectedVersammlung");
            const parameters = {cmd: 'getSelectedVersammlung', data:{}}
            this._socketService.querySelectedVersammlung(parameters);
            this.selectedVersammlung = {};
            this.deadReload++;
          }
        }
        else
        {
          if(this.versammlungLoaded === false)
          {
            this.versammlungLoaded = true;
            this.selectedVersammlung = data;
            this.loadDataFromSocketLater("[client on getSelectedVersammlungFromDexListener ]");
            // this.logger.info(data, "[ergebniss.component] getSelectedVersammlungFromDexListener");
          }
          else
          {
            this.logger.info(this.versammlungLoaded , `>>>> No Data loading possible - this.versammlungLoaded `)
          }
        }
      }
      catch(e){this.logger.error(e);}
    });
  }

  initListenerLater()
  {
    // UpdateInDbListener
    this.updateInDbListener = this._socketService.response_updateInDB().subscribe( async (data : any )=>{
      try
      {
        if(HelperFunctions.Misc.isJson2(data) == 'string')
        {
            data = JSON.parse(data);
            const response = data.response;
            this.logger.info(data, "response_updateInDB");
            if(data.response && data.submitted && data.submitted.hasOwnProperty("cmd"))
            {
              const cmd = data.submitted.cmd;
              if(cmd === e_command_enum.updateBeschlussvorschlag)
              {
                if(data.response.hasOwnProperty("tagesordnungspunkt"))
                {
                  this.selectedTagesordnungspunkt.beschlussvorschlag = data.response.tagesordnungspunkt[0].beschlussvorschlag;
                  // this.displayErgebnisse();
                }
              }
              if(cmd === e_command_enum.updateBeschlussgegenstand)
              {
                if(data.response.hasOwnProperty("tagesordnungspunkt"))
                {
                  this.selectedTagesordnungspunkt.beschlussgegenstand = data.response.tagesordnungspunkt[0].beschlussgegenstand;
                  // this.displayErgebnisse();
                }
              }
              if(cmd === e_command_enum.updateErlaeuterung)
              {
                if(data.response.hasOwnProperty("tagesordnungspunkt"))
                {
                  this.selectedTagesordnungspunkt.erlaeuterung = data.response.tagesordnungspunkt[0].erlaeuterung;
                  // this.displayErgebnisse();
                }
              }

              if(cmd === e_command_enum.updateProtokollnotiz)
              {
                if(data.response.hasOwnProperty("tagesordnungspunkt"))
                {
                  this.selectedTagesordnungspunkt.protokollnotiz = data.response.tagesordnungspunkt[0].protokollnotiz;
                  // this.displayErgebnisse();
                }
              }

            }
        }
      }
      catch(e) { this.logger.error(e);}
    });



  }


  public response_getErgebnissComponentDataHandler(data)
  {
    try
    {
      const response = data.response;
      this.logger.info(data, "[response_getErgebnissComponentDataHandler] ");

      if(response.selectedTagesordnungspunkt)
      {
        this.selectedTagesordnungspunkt = response.selectedTagesordnungspunkt[0];
        if(this.selectedTagesordnungspunkt.ist_gob_von > 0 && response.hasOwnProperty("elternTagesordnungspunkt"))
        {
          this.elternTagesordnungspunkt = response.elternTagesordnungspunkt[0];
        }

      }
      if(response.selectedVersammlung)
      {
        this.selectedVersammlung = response.selectedVersammlung[0];
        // this.dex.saveSelectedVersammlung(response.selectedVersammlung[0])
      }
      if(response.abstimmErgebnisse)
      {
        this.abstimmErgebnisse = response.abstimmErgebnisse;
        this.selectedTagesordnungspunkt.verkuendungsErgebnis = this.abstimmErgebnisse.verkuendungsErgebnis;
      }
      // this.selectedTagesordnungspunkt = response.selectedTagesordnungspunkt[0];


    } catch (error) {      this.logger.error(error);    }

  }



  // public displayErgebnisse(data: any = {})
  // {

  // }

  public getAbstimmErgebnisse(text  = "")
  {
    try
    {
      const parameters : any = {
        cmd: e_event_emitter_enum.getErgebnissComponentData,
        data: {
          versammlung_id : this.selectedVersammlung.versammlung_id,
          active: true,
          notice : text
        }
      }
      if(this.ergebnissData != null && this.ergebnissData?.hasOwnProperty("tagesordnungspunkt_id"))
      {
        if(this.ergebnissData?.hasOwnProperty("tagesordnungspunkt_id"))
        {
          parameters.data.tagesordnungspunkt_id = this.ergebnissData?.tagesordnungspunkt_id;
          parameters.data.ist_gob = this.ergebnissData.ist_gob;
        }
        if(this.ergebnissData?.hasOwnProperty("ist_gob_von"))
        {
          parameters.data.tagesordnungspunkt_id = this.ergebnissData?.ist_gob_von;
          parameters.data.ist_gob = true;
        }
      }
      else
      {
        if(this.selectedVersammlung?.navigateClient && HelperFunctions.Misc.isJson2(this.selectedVersammlung.navigateClient) == 'string')
        {
          const navigateClient = JSON.parse(this.selectedVersammlung?.navigateClient);
          // console.log("HIER !!!!!!!!!!!!!!!!!!!!",navigateClient)
          if(navigateClient?.data?.tagesordnungspunkt?.tagesordnungspunkt_id)
          {
            parameters.data.tagesordnungspunkt_id = navigateClient.data.tagesordnungspunkt.tagesordnungspunkt_id;
          }
          if(navigateClient?.data?.tagesordnungspunkt?.ist_gob)
          {
            parameters.data.ist_gob = navigateClient.data.tagesordnungspunkt.ist_gob;
          }
        }
      }

      parameters.data.queryObjects = ['selectedVersammlung' , 'abstimmErgebnisse' , 'selectedTagesordnungspunkt']
      this._socketService.getErgebnissComponentData(parameters);

    } catch (error) {
      this.logger.error(error)
    }
  }

}
