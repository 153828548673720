<div class="justify-content-center animated--grow-in justify-content-center row pl-3 pl-lg-0 pr-lg-0">
  <div class="align-self-center" [ngClass]="{'col-12 col-xs-12 col-md-10 col-lg-6 col-xl-4': sambaService?.displayVideoConferenceSrc.value === false, 'col-12': sambaService?.displayVideoConferenceSrc.value === true}">

    <div class="row d-flex justify-content-center mb-4">
        <div class="col-xs-auto">
            <!-- <p class="h1" style="margin-bottom: 0px;">Erläuterung</p> -->
            <h1 class="h1" style="margin-bottom: 0px;">Ergebnisse</h1>
        </div>
    </div>

    <div class="row d-flex justify-content-start">
        <div class="col col-12 col-xs-12 col-md-12 col-lg-12 col-xl-12">
            <ul class="list-inline mb-0">
                <li class="list-inline-item">
                    <p class="mb-0">Objekt: </p>
                </li>
                <li class="list-inline-item">
                    <p class="mb-0"><strong>{{this.selectedVersammlung?.objekt_name}} </strong></p>
                </li>
            </ul>
        </div>
    </div>
    <div class="row d-flex justify-content-start">
        <div class="col col-12 col-xs-12 col-md-12 col-lg-12 col-xl-12">
            <ul class="list-inline mb-0">
                <li class="list-inline-item">
                    <p class="mb-0">Versammlung: <strong>{{this.selectedVersammlung?.name}} </strong></p>
                </li>
            </ul>
        </div>
    </div>

    <div class="row d-flex justify-content-start">
        <div class="col col-12">
            {{selectedTagesordnungspunkt?.ist_gob_von ? "Geschäftsordnungsbeschluss" : "Tagesordnungspunkt"}} <b>{{this.selectedTagesordnungspunkt?.rangText}}</b> {{(selectedTagesordnungspunkt?.abstimmung_erforderlich == 1 ) ? "mit Abstimmung": selectedTagesordnungspunkt?.ist_gob_von? "" : "ohne Abstimmung"}}
        </div>
        <ng-container *ngIf="selectedTagesordnungspunkt?.abstimmung_erforderlich > 0 || selectedTagesordnungspunkt?.ist_gob_von">
            <div class="col col-12">
                Gemeinschaftstyp: <b>{{selectedTagesordnungspunkt?.gemeinschaftstyp_text.length  ? selectedTagesordnungspunkt.gemeinschaftstyp_text : ''}}</b>
            </div>
            <div class="col col-12">
                Qualifizierung: <b>{{selectedTagesordnungspunkt?.qualifizierungstyp?.id >= 0? this.dex.top_helper.getStimmgeberNamenByTopId(selectedTagesordnungspunkt , true, false) : "" }} </b>
            </div>
            <div class="col col-12">
                {{this.selectedTagesordnungspunkt?.qualifizierungstyp.id == 2 ? "Berücksichtigung 1. Qualifizierung:":"Berücksichtigung:"}} <b>{{this.dex.top_helper.getBeruecksichtigung(selectedTagesordnungspunkt?.qualifizierungstyp , "s1") }}</b>
            </div>
            <ng-container *ngIf="this.selectedTagesordnungspunkt?.qualifizierungstyp.id == 2">
                <div class="col col-12">
                    Berücksichtigung 2. Qualifizierung: <b>{{this.dex.top_helper.getBeruecksichtigung(this.selectedTagesordnungspunkt?.qualifizierungstyp , "s2") }}</b>
                </div>
            </ng-container>
            <div class="col col-12">
                Beschluss:<strong> {{selectedTagesordnungspunkt?.verkuendungsErgebnis}}</strong>
            </div>
        </ng-container>
    </div>


    <div class="row animated--grow-in mt-4" *ngIf="selectedTagesordnungspunkt.abstimmung_erforderlich > 0 || selectedTagesordnungspunkt.ist_gob_von">
        <div class="" [ngClass]="{'col-10 offset-0': selectedTagesordnungspunkt?.qualifizierungstyp?.id != 2, 'col-12': selectedTagesordnungspunkt?.qualifizierungstyp?.id == 2 }">
            <ng-container [ngSwitch]="selectedTagesordnungspunkt.qualifizierungstyp.id">
                <ng-container *ngSwitchCase="0">
                    <ng-container *ngTemplateOutlet="EinfacheMehrheit2"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="1" >
                    <ng-container *ngTemplateOutlet="QualifizierteMehrheit2"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="2">
                    <ng-container *ngTemplateOutlet="DoppelteQualifizierung2"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="3">
                    <ng-container *ngTemplateOutlet="EinfacheMehrheitAT"></ng-container>
                </ng-container>
            </ng-container>
        </div>
    </div>
    <!-- Neuer Ansatz der Template Aufteilung -->

    <ng-template #EinfacheMehrheit2>
        <app-einfache-mehrheit
            [abstimmErgebnisse]="abstimmErgebnisse"
            [selectedVersammlung]="selectedVersammlung"
            [selectedTagesordnungspunkt]="selectedTagesordnungspunkt"
            [displayOptions]="displayOptions"
            [changes]="abstimmErgebnisse"
        ></app-einfache-mehrheit>
    </ng-template>

    <ng-template #QualifizierteMehrheit2>
        <app-qualifizierte-mehrheit
            [abstimmErgebnisse]="abstimmErgebnisse"
            [selectedVersammlung]="selectedVersammlung"
            [selectedTagesordnungspunkt]="selectedTagesordnungspunkt"
            [displayOptions]="displayOptions"
            [changes]="abstimmErgebnisse"
        ></app-qualifizierte-mehrheit>
    </ng-template>

    <ng-template #DoppelteQualifizierung2>
        <app-doppelte-qualifizierung
            [abstimmErgebnisse]="abstimmErgebnisse"
            [selectedVersammlung]="selectedVersammlung"
            [selectedTagesordnungspunkt]="selectedTagesordnungspunkt"
            [displayOptions]="displayOptions"
            [changes]="abstimmErgebnisse"
        ></app-doppelte-qualifizierung>
    </ng-template>

      <ng-template #EinfacheMehrheitAT>
          <app-einfache-mehrheit-at
                  [abstimmErgebnisse]="abstimmErgebnisse"
                  [selectedVersammlung]="selectedVersammlung"
                  [selectedTagesordnungspunkt]="selectedTagesordnungspunkt"
                  [displayOptions]="displayOptions"
                  [changes]="abstimmErgebnisse"
          ></app-einfache-mehrheit-at>
      </ng-template>

    <ng-container>
        <div class="row justify-content-center mt-4" *ngIf="displayOptions.fertigAbgestimmt === true || (this.selectedTagesordnungspunkt.abstimmung_beendet > 0)">
            <div class="col col-12 align-self-center">
                <ng-container *ngIf="selectedTagesordnungspunkt.abstimmung_erforderlich > 0 || selectedTagesordnungspunkt.ist_gob_von">
                    <p class = "abstimmung_beendet_text text-center"><strong>Diese Abstimmung ist beendet.</strong></p>
                </ng-container>
                <ng-container *ngIf="selectedTagesordnungspunkt.abstimmung_erforderlich == 0">
                    <p class = "abstimmung_beendet_text text-center"><strong>Dieser Tagesordnungspunkt wurde behandelt.</strong></p>
                </ng-container>
            </div>
        </div>
    </ng-container>
  </div>
</div>
