import {Injectable, OnDestroy, OnInit} from '@angular/core';
import {Teilnehmer} from '../_models';
import {DataExchangeService} from '../_services/data-exchange.service';
import {BehaviorSubject, firstValueFrom, map, Observable, Subscription} from 'rxjs';
import {SocketService} from '../_services/socket.service';
import {e_event_emitter_enum} from '../_models/enum';
import {LoggerService} from './logger.service';
import {EventEmitterService} from './event-emitter.service';
import {AuthenticationService} from '../_services/authentication.service';

declare let HelperFunctions: any;

export enum vertretungCMDs {
  vertretungen = 'vertretungen',
  vertretungSpeichern = 'vertretungSpeichern',
  vertretungLoeschen = 'vertretungLoeschen'
}
export enum fakeTeilnehmerStates {
  InternerVertreter = -5,
  ExternerVertreter = 0,
  HausverwalterMitWeistung = -1,
  HausverwalterOhneWeisung = -2,
  KeinVertreter = -3,
  BitteAuswaehlen = -4
  // -1 = Hausverwalter mit Weistung , -2 = Hausverwalter ohne Weisung, -3 = kein Vertreter
}

export interface parameters__vertretung_speichern{
  cmd: string,
  versammlung_id: number,
  teilnehmer_id: number,
  vertreter_id : number,
  vertreter_vorname : string,
  vertreter_nachname : string,
  vertreter_typ : number,
  vertreter_typ_of_teilnehmer: number,
  email? : string,
  tagesordnungspunkt_id?: number,
}
export interface parameters__vertretung_loeschen {
  cmd: string,
  teilnehmer_id: number,
  versammlung_id: number
}

export interface callback__VertretungLoeschen {
  response: any,
  submitted: any
}

export interface callback__VertretungSpeichern {
  response: { 
    status: number 
  },
  submitted: parameters__vertretung_speichern | parameters__vertretung_loeschen
}


@Injectable({
  providedIn: 'root'
})
export class VertretungenService implements  OnDestroy {

  private eventEmitterSubscription: Subscription;
  public vertretungenResponse$: BehaviorSubject<any> = new BehaviorSubject(null);
  // public vertretungenListener: Observable<unknown> = new Observable<unknown>(null);
  public vertretungenListener: Subscription = new Subscription(null);
  
  constructor(
      private socketService: SocketService,
      private dex: DataExchangeService,
      private eventEmitterService: EventEmitterService,
      private logger: LoggerService,
      private authenticationService: AuthenticationService
  ) {
    this.initListener();
    // this.initVertretungenListener();
  }

  ngOnDestroy(): void {
        this.eventEmitterSubscription.unsubscribe();
    }
  


  private initListener() {
    // Event Emitter
    this.eventEmitterSubscription = this.eventEmitterService.eventEmitter.subscribe(msg => {
      try
      {
        // eslint-disable-next-line no-prototype-builtins
        if(msg.hasOwnProperty("cmd"))
        {
          const cmd = msg.cmd;
     
          if(cmd === e_event_emitter_enum.onLogin)
          {
            // this.unsubscribeAll();
            // this.onSocketInit();
            // console.log("@@@@ [vertretungen.service] onLogin",msg);
            this.initVertretungenListener();
          }
        }
      }catch(e){this.logger.error(e);}
    });
  }
  
  public initVertretungenListener = () => {
    // callback.submitted.vertreter_typ
    // Interner Vertreter -5
    // Bitte Auswählen -4
    // Kein Vertreter -3
    // Hausverwalter ohne Weisung -2
    // Hausverwalter mit Weisung -1
    // Externer Vertreter 0
    console.log("[vertretungService initVertretungenListener]!!!!");
    const cmd = vertretungCMDs.vertretungen;
    this.vertretungenListener.unsubscribe();
    this.vertretungenListener = this.socketService.getSocketObservable(cmd)
      //   .pipe(
      //     map( (callback:string) =>{
      //       console.log("@@@@ test callback1: ", callback);
      //       const callback2 : callback__VertretungLoeschen = JSON.parse(callback);
      //       return callback2;
      //     } )
      // )
    .subscribe((callback: callback__VertretungSpeichern | callback__VertretungLoeschen)=>{
      console.log("@@@@[vertretung.service.ts] test callback2 ",callback);
      // InternerVertreter = -5,
      // ExternerVertreter = 0,
      // HausverwalterMitWeistung = -1,
      // HausverwalterOhneWeisung = -2,
      // KeinVertreter = -3,
      // BitteAuswaehlen = -4
      // VUL-844
      if(callback.submitted.teilnehmer_id === this.authenticationService.currentUserValue.teilnehmer_id && callback.submitted.vertreter_typ_of_teilnehmer === 0 && callback.submitted.vertreter_typ === -3) {
        //  Ausloggen
        this.authenticationService.logoutOnEM(true);
      }else{
        this.vertretungenResponse$.next(callback);
      }

      // subscriber.unsubscribe();
    })
    // return subscriber;
    // const callback = await firstValueFrom(this.vertretungenListener);
    // console.log("@@@@ test callback3: ", callback);
    // this.vertretungenResponse$.next(callback);

    //---------------------------
    // this.vertretungenResponse$.subscribe((callback: callback__VertretungSpeichern) => {
    //   console.log("@@@@[vertretung.service.ts] vertretungenResponse$ ", callback);
    // });
  }
  
  
  public vertretungloeschen = async (parameters:parameters__vertretung_loeschen = null):  Promise<callback__VertretungLoeschen> => {
    try
    {
      const cmd = vertretungCMDs.vertretungen;
      const subscriber = this.socketService.getSocketObservable(cmd).pipe(
          map( (callback:string) =>{
            const callback2 : callback__VertretungLoeschen = JSON.parse(callback);
            return callback2;
          } )
      )
      // .subscribe((einheiten: Array<Einheit>)=>{
      //   console.log("this.einheiten_src.next ",einheiten)
      //   this.einheiten_src.next(einheiten)
      //   subscriber.unsubscribe();
      // })
      this.socketService.socket.emit(cmd , parameters);
      // return subscriber;
      const callback = await firstValueFrom(subscriber);
      this.vertretungenResponse$.next(callback);
      // return await firstValueFrom(subscriber)
      return callback;
    }
    catch (error)
    {
      console.log(error)
    }
  }
  
  
  
  
  public getVertreterTyp(teilnehmer: Teilnehmer[] = null, vertreter: Teilnehmer): fakeTeilnehmerStates {
    const internerVertreter = teilnehmer.filter(tn => tn.vorname.trim() === vertreter.vorname.trim() && tn.nachname.trim() === vertreter.nachname.trim() );
    return internerVertreter.length > 0 ? fakeTeilnehmerStates.InternerVertreter : vertreter.teilnehmer_id;
  }
  public getVertreterTypOfTeilnehmer(teilnehmerlist: Teilnehmer[], teilnehmer: Teilnehmer): fakeTeilnehmerStates {
    if (teilnehmer.vertreter_vorname.length < 1 && teilnehmer.vertreter_nachname.length < 1) {
      return fakeTeilnehmerStates.KeinVertreter;
    } else {
      if (teilnehmer.vertreter_vorname.trim() === this.dex.hausverwalter.name.trim()) {
        return teilnehmer.vertreter_nachname.trim().toLowerCase() === "Mit Weisung".toLowerCase() ? fakeTeilnehmerStates.HausverwalterMitWeistung : fakeTeilnehmerStates.HausverwalterOhneWeisung;
      } else {
        const ret = teilnehmerlist.slice().filter(tn => tn.vorname === teilnehmer.vertreter_vorname && tn.nachname === teilnehmer.vertreter_nachname);
        if (ret.length > 0) {
          return fakeTeilnehmerStates.InternerVertreter;
        } else {
          return fakeTeilnehmerStates.ExternerVertreter;
        }
      }
    }
  }

}
