<div class="containerContent justify-content-center animated--grow-in">
    <div class="align-self-center" [ngClass]="{'col-12 col-xs-12 col-md-10 col-lg-6 col-xl-4': sambaService?.displayVideoConferenceSrc.value === false, 'col-12': sambaService?.displayVideoConferenceSrc.value === true}">
		<div class="row d-flex justify-content-center mt-2 mb-2">
			<div class="col col-12">
				<div class="d-flex align-items-center justify-content-center text-center">
					<div class="h2" #textOben>{{texte.oben}}    </div>
				</div>
			</div>
		</div>

		<div class="row justify-content-center mt-3" *ngIf="showUhrIcon === true">
			<div class="d-flex align-items-center justify-content-center">
				<div class="col col-6">
					<img src="../../../assets/images/icons/uhr-warten-fortschritt.png" class="uhr-icon" />
				</div>
			</div>
		</div>
		<div class="row justify-content-center mt-3" *ngIf="showUhrIcon === false">
			<div class="d-flex align-items-center justify-content-center">
				<div class="col col-6">
					<img src="../../../assets/images/icons/flagge-ziel.png" class="flagge-icon" />
				</div>
			</div>
		</div>

		<app-alert></app-alert>

		<div class="row d-flex justify-content-center mt-3">
			<div class="col col-12">
				<div class="d-flex align-items-center justify-content-center text-center">
					<div class='h4'><div [innerHTML]="texte.unten"> </div></div>
				</div>
			</div>
		</div>

		<ng-container *ngIf="this.autoLogout.timer != null">
			<div class="row d-flex align-items-center justify-content-center mt-4">
				<div class="col col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
					<button type="submit" class="btn button vulcavo-btn-primary w-100" (click)="resetLogoutTimer()">
					Hier Klicken um ein Ausloggen in {{this.autoLogout.remainTime/1000}} Sec zu verhindern
					</button>
				</div>
			</div>
		</ng-container>
	</div>
</div>


