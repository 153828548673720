/* eslint-disable no-prototype-builtins */
import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { DataExchangeService } from 'src/app/_services/data-exchange.service';
import { SocketService } from 'src/app/_services/socket.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { LoggerService } from 'src/app/services/logger.service';
import { e_event_emitter_enum } from 'src/app/_models/enum';
import { use_abstimmung_greater } from 'src/app/_services/globals.service';
import {EventEmitterService} from '../../../services/event-emitter.service';
import {AuthenticationService} from '../../../_services/authentication.service';
import {TeilnehmerService} from '../../../_services/user.service';
import {AlertService} from '../../../_services/alert.service';

declare let HelperFunctions: any;

@Component({
  selector: 'app-finish',
  templateUrl: './finish.component.html',
  styleUrls: ['./finish.component.scss']
})
export class FinishComponent  implements OnInit, OnDestroy, AfterViewInit {
  // Vars
  public currentUser: any;
  public buttons: any = {logout: false};
  public selectedVersammlung: any = { text: ''};
  public selectedTagesordnungspunkt: any;
  public abstimmungen : any;
  public einheiten : any;
  public gemeinschaftstypen : any;
  public code: number;
  public _use_abstimmung_greater = -2;

  // Listener
  private eventEmitterSubscription: Subscription;
  private getSelectedVersammlungFromDexListener: Subscription;
  private getSelectedTagesordnungspunktListener: Subscription;

  constructor(
    private dex: DataExchangeService,
    private _socketService: SocketService,
    private authenticationService: AuthenticationService,
    private userService: TeilnehmerService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private logger: LoggerService,
    private eventemitterservice: EventEmitterService
  )
  { 
    this._use_abstimmung_greater = use_abstimmung_greater;
  }

  ngOnDestroy(): void 
  {
    this.eventEmitterSubscription.unsubscribe();
    this.getSelectedVersammlungFromDexListener.unsubscribe();
    this.getSelectedTagesordnungspunktListener.unsubscribe();
    this.dex.stopRefreshTimer();
  }

  ngAfterViewInit(): void 
  {
    this.dex.startRefreshTimer();
  }

  ngOnInit(): void 
  {
    this.currentUser = this.authenticationService.currentUserValue;
    this.initListener();
  }

  loadDataFromSocket()
  {
    this.dex.querySelectedVersammlung("von finish");
  }

  loadDataFromSocketLater()
  {
      this._socketService.querySelectedTagesordnungspunkt({
        cmd: "querySelectedTagesordnungspunkt",
        versammlung_id: this.selectedVersammlung.versammlung_id, 
        teilnehmer_id: this.currentUser.teilnehmer_id,
        active: true,
        onlyPreview: false
      });
  }

  initListener()
  {
    // GET Tagesordnungspunkt Daten
      // Code -1 - kein tP | 0 TN hat keine Einheit | 1: valide, hat schon was abgestimmt | 2: TN hat noch nichts abgestimmt
      this.getSelectedTagesordnungspunktListener = this.dex.currentSelectedTagesordnungsPunkt.subscribe( async (data : any )=>{
        try
        {             
          if(data && data.code)
          {
            this.code = data.code;
            // -2 -3 fake Abstimmungen entfernen
            data.abstimmungen = HelperFunctions.Sorting.filterAbstimmungen(data.abstimmungen , this._use_abstimmung_greater);
            switch(data.code)
            {
              case -1:
                // Kein Tagesordnungspunkt durch das Dashboard angewählt
              break;
              case 0:
                // Teilnehmer besitzt keine Einheiten
              break;
              case 1:
                // Valide, es liegen bereits Abstimmungen vor
                this.gemeinschaftstypen = data.gemeinschaftstypen;
                this.einheiten = data.einheiten;
                this.abstimmungen = data.abstimmungen;
                this.selectedTagesordnungspunkt = data.tagesordnungspunkt;
              break;
              case 2: 
                // Teilnehmer hat noch keine Abstimmung getätigt
                this.gemeinschaftstypen = data.gemeinschaftstypen;
                this.einheiten = data.einheiten;
                this.abstimmungen = data.abstimmungen;
                this.selectedTagesordnungspunkt = data.tagesordnungspunkt;
              break;
              case 3:
              break;
            }
            if(data.code === 1 || data.code === 2)
            {
              this.router.navigate(['mobile-client/abstimmung/abstimmung-main']);
            }
          }
        }
        catch(e) { this.logger.error(e); }
      });

    // Event Emitter
    this.eventEmitterSubscription = this.eventemitterservice.eventEmitter.subscribe(msg => {
      try
      {
        if(msg.hasOwnProperty("cmd"))
        {
          const cmd = msg.cmd;
          if(cmd === e_event_emitter_enum.connect)
          {           
            this.logger.info(msg, "Connect")
          }
          if(cmd === e_event_emitter_enum.disconnect)
          {           
            this.logger.info(msg, "Disconnect")
          }
        }
      }catch(e){this.logger.error(e);}
    });

    // getSelectedVersammlung
    this.getSelectedVersammlungFromDexListener = this.dex.currentSelectedVersammlung.subscribe( async (data : any )=>{
    try
    {
      if(data=== null || data === '' || data ==='undefined' || Object.keys(data).length === 0)
      {
        this.dex.querySelectedVersammlung("from finish comp");
      }
      else
      {
        this.selectedVersammlung = data;   
        this.loadDataFromSocketLater();
        this.logger.info(data, "[finish.component] getSelectedVersammlungFromDexListener ");
      }
    }
    catch(e){this.logger.error(e);}
    });
  }

  async getTeilnehmerHandler(data)
  {
    this.logger.info(data, "response_getTeilnehmer Teilnehmer ");
  }

  logout()
  {
    this.logger.info("logout");
  }
}
