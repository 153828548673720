/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Teilnehmer } from '../_models';
import { SocketService } from './socket.service';
import { DataExchangeService } from './data-exchange.service';
import { Router } from '@angular/router';
import { LoggerService } from '../services/logger.service';
import { e_command_enum, e_event_emitter_enum } from '../_models/enum';
import {EventEmitterService} from '../services/event-emitter.service';

declare let HelperFunctions: any;

@Injectable({ providedIn: 'root' })
export class AuthenticationService implements OnInit , OnDestroy {
    private dex: DataExchangeService = null;
    // LISTENER    
    private eventEmitterSubscription : Subscription;
    private anmeldungClientListener : Subscription;
    
    // VARS
    private currentUserSubject: BehaviorSubject<Teilnehmer>;
    public currentUser: Observable<Teilnehmer>;

    
    public browserRefresh  = false;
    public vertreterAnmeldungNotificationTime = 2000;

    constructor(
      private socketService: SocketService,
      private logger: LoggerService,
      public router: Router,
      private eventEmitterService: EventEmitterService
    ) 
    {
        this.socketService = socketService
        this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem("currentUserMobileClient")));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    setDex(dex: DataExchangeService) {this.dex = dex;}
    ngOnInit(): void {
        
    }

    ngOnDestroy(): void 
    {
        this.eventEmitterSubscription.unsubscribe();
        this.unsubscribeAll();
    }

    unsubscribeAll()
    {
        this.anmeldungClientListener != undefined ? this.anmeldungClientListener.unsubscribe() : null;
    }

    public get currentUserValue(): Teilnehmer 
    {
        return this.currentUserSubject.value;
    }

    login2(user)
    {
        localStorage.setItem("currentUserMobileClient", JSON.stringify(user));
        this.currentUserSubject.next(user);
        // console.log("@@@@ [authentication.service] onLogin",user);
        this.eventEmitterService.eventEmitter.next({cmd: e_event_emitter_enum.onLogin , data: {user: user}});
    }

    public logout() 
    {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUserMobileClient');
        this.dex.deleteSelectedVersammlung();
        this.dex.deleteSelectedTagesodnungspunkt();
        this.currentUserSubject.next(null);
        this.eventEmitterService.eventEmitter.next({cmd: e_command_enum.logout});
    }

    public logoutOnEM(backToLogin  = true)
    {
        try
        {
        this.logout();
        this.socketService.closeConnection();
        if(backToLogin === true)
        {
            this.router.navigate(['/mobile-client/login']);
        }
        
        }catch(e){this.logger.error(e);}
    }

    public initListener()
    {
        // Event Emitter
        this.eventEmitterSubscription = this.eventEmitterService.eventEmitter.subscribe(msg => {
            try
            {
            if(msg.hasOwnProperty("cmd"))
            {
                const cmd = msg.cmd;
            
                if(cmd === e_event_emitter_enum.onSocketInit)
                {
                    this.logger.info("EM onSocketInit")
                    this.unsubscribeAll();
                    this.onSocketInit();
                }
                if(cmd === e_event_emitter_enum.teilnehmerSperren)
                {
                  this.logger.info(msg, "TeilnehmerSperren");    
                  this.logoutOnEM(true);
                }
                if(cmd === e_event_emitter_enum.teilnehmerUeberBarcodeAngemeldet)
                {
                    this.logoutOnEM(true);
                }
                if(cmd === e_event_emitter_enum.teilnehmerAbmelden)
                {
                    this.logoutOnEM(true)
                }
            }
            }catch(e){this.logger.error(e);}
        });
    }

    onSocketInit()
    {
        this.initListenerLater()
    }

    initListenerLater()
    {
        // Anmeldungs Listener
        this.anmeldungClientListener = this.socketService.response_anmeldung().subscribe( async (data : any )=>{
            try
            {
            if(HelperFunctions.Misc.isJson2(data) == 'string')
            {
                data = JSON.parse(data);
                if(data.response)
                {
                    this.response_anmeldungClient_handler(data);
                }
            }   
            }catch(e){this.logger.error(e);}
        });
    }

    // Handler for user anmeldung
    response_anmeldungClient_handler(data)
    {
        const response = data.response;
        let versammlung_given = false;
        this.logger.info(data, "[authentication.service] response_anmeldungClient");     
        
        if((data.resultnotice.code === 1 || data.resultnotice.code === 3 ) && data.hasOwnProperty('selectedVersammlung') )
        {
            this.logger.info(data.selectedVersammlung, '[authentication.service] save SelectedVersammlung');
            this.dex.saveSelectedVersammlung(data.selectedVersammlung);
            versammlung_given = true;
        } 
        if(data.hasOwnProperty("hausverwalter") && Object.keys(data.hausverwalter).length > 0)
        {
            this.dex.hausverwalter = data.hausverwalter;
        }
        if(data.hasOwnProperty("qualifizierungsTypen") && Object.keys(data.qualifizierungsTypen).length > 0)
        {
            this.dex.qualifizierungsTypen = data.qualifizierungsTypen;
        }
        
        switch(data.resultnotice.code)
        {
            case -4: 
                // Anmeldung nicht gestattet, da Anmeldung über TN mit internen Vertreter unzulässig
            break;
            case -3:
                // Anmeldung nicht gestattet, da keine selektierte aktive Versammlung
                this.logout();
            break;
            case -2:
                // Anmeldung nicht gestattet, da nicht für die Versammlung zugelassen
            break;
            case -1: 
                // Teilnehmer gibts nicht
                this.logoutOnEM(true);
            break;
            case 0:
                // Anmeldung nicht gestattet da der Teilnehmer einen Hausverwalter als Vertreter hat
            break;
            case 1:
                // user ist korrekt anmeldung erfolgt
                this.socketService.getSettingsFromServer({client_type: 'all', readDbSettings: true});
                response[0].code = data.resultnotice.code;
                this.login2(response[0]);
                if(versammlung_given === true)
                {
                    this.dex.navigateDecider({code: data.resultnotice.code});
                }
                else
                {
                    this.logger.info('> no versammlung given -> 1 querySelectedVersammlung');
                    this.dex.querySelectedVersammlung('login_code_1');
                }               
                
            break;
            case 2:
                // this.alertService.error(this.dex.error.user_has_vertreter_error);
            break;
            case 3:
                // User ist extern vertreten
                response[0].code = data.resultnotice.code;   
                this.socketService.getSettingsFromServer({client_type: 'all', readDbSettings: true});         
                this.login2(response[0]);
                setTimeout(() =>
                {
                    if(versammlung_given === true)
                    {
                        this.dex.navigateDecider({code: data.resultnotice.code});
                    }
                    else
                    {
                        this.logger.info('> no versammlung given -> 3 querySelectedVersammlung');
                        this.dex.querySelectedVersammlung('login_code_3');
                    }

                }, this.vertreterAnmeldungNotificationTime);
            break;
            default:
                this.logoutOnEM(true);
            break;
        }
    }
}