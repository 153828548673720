<!-- neue Optik -->
<ng-container>
    <div class="topbar" *ngIf="displayHeader">
        <div class="container-fluid">
            <div class="d-flex topbarHeight row">
                <!-- https://getbootstrap.com/docs/4.0/layout/grid/ -->
                <ng-container *ngIf="showOptions.topbarLogo === true">
                    <div class="col col-6 col-sm-6 col-xs-6 col-lg-6 col-xl-6  align-self-center btn-z-index" (click)="showAppInfo()">
                        <!-- <img src="../../../assets/images/logo.png" class="vulcavo-sidenav-logo" /> -->
                        <img src="{{this._socketService.getImageLogoUrl('logo.png')}}" class="vulcavo-sidenav-logo img-fluid" />
                    </div>
                </ng-container>
                
                <div class="col col-6 p-0 align-self-center btn-z-index d-flex justify-content-end" *ngIf="currentUser">
                    <button type="button" class="form-control" (click)="this.authenticationService.logoutOnEM(true)" style="width: auto">
                        <div class="btn-abmelden"><strong class="d-none d-lg-inline-block d-xl-inline-block pr-1">{{currentUser? currentUser.vorname+' '+currentUser.nachname+'' : ''}}</strong>Abmelden<i class="fas fa-sign-out-alt pl-2"></i></div>
                    </button>
                </div>
            </div>
        </div>
    </div>

</ng-container>