/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable radix */
/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { SocketService } from 'src/app/_services/socket.service';
import { DataExchangeService } from 'src/app/_services/data-exchange.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoggerService } from 'src/app/services/logger.service';
import { MatDialog } from '@angular/material/dialog';
import { DisclaimerModal } from '../modals/modal-disclaimer-modal';
import { e_command_enum, e_event_emitter_enum } from 'src/app/_models/enum';
import { AppComponent } from 'src/app/app.component';
import { environment } from 'src/environments/environment';
import {StandardBestaetigenModal} from '../modals/modal-standard-bestaetigung';
import {parameters__vertretung_loeschen, parameters__vertretung_speichern, vertretungCMDs, VertretungenService} from '../../services/vertretungen.service';
import {EventEmitterService} from '../../services/event-emitter.service';
import {AuthenticationService} from '../../_services/authentication.service';
import {alert_message_type, AlertService} from '../../_services/alert.service';
// import {  } from '@fortawesome/angular-fontawesome';
// import { timingSafeEqual } from 'crypto';

// eslint-disable-next-line @typescript-eslint/naming-convention
declare let HelperFunctions: any;

export interface loginParameters {
    passwort: number,
    nachname: string,
    deleteVertretung: boolean,
    vertreter_vorname?: string,
    vertreter_nachname?: string
}
export enum formControlNames{
  nachname = "nachname",
  password = "password"
}
export class numberValidator{
  // Number only validation
  static numeric(control: AbstractControl) {
    let val = control.value;

    if (val === null || val === '') return null;

    if (!val.toString().match(/^[0-9]+(\.?[0-9]+)?$/)) return { 'invalidNumber': true };

    return null;
  }
}

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    animations: [
        trigger('changeDivSize', [
            state('initial', style({
                backgroundColor: 'green',
                width: '100px',
                height: '100px'
            })),
            state('final', style({
                backgroundColor: 'red',
                width: '200px',
                height: '200px'
            })),
            transition('initial=>final', animate('1500ms')),
            transition('final=>initial', animate('1000ms'))
        ]),

        trigger('fadeInOut', [
            state('void', style({
                opacity: 0
            })),
            transition('void <=> *', animate(1000)),
        ]),

        trigger('EnterLeave', [
            state('flyIn', style({ transform: 'translateX(0)' })),
            transition(':enter', [
                style({ transform: 'translateX(-100%)' }),
                animate('0.5s 300ms ease-in')
            ]),
            transition(':leave', [
                animate('0.3s ease-out', style({ transform: 'translateX(100%)' }))
            ])
        ]),

        trigger('fadeInAnimation', [
        // route 'enter' transition
            transition(':enter', [
            // css styles at start of transition
                style({ opacity: 0 }),

                // animation and styles at end of transition
                animate('.3s', style({ opacity: 1 }))
            ]),
        ])

    ]
})


export class LoginComponent implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild('input_password',{static: false}) input_password: ElementRef;
  public model = {
      left: true,
      middle: false,
      right: false
  };
  // Listener
  // private registerWebsocketListener: Subscription;
  private anmeldungListener: Subscription;
  private eventEmitterSubscription: Subscription;
  private authorizedListener: Subscription;
  private selectedVersammlungListener: Subscription;
  private selectedVersammlungFromDbListener: Subscription;
  // private alertSubscription: Subscription;
  // Vars & DATA
  public alerts: any = null;
  private alertSetTimeout: any = null;
  public postId = '';
  public errorMessage = '';
  public loginForm: FormGroup;
  private listenerInited = false;
  public loading = false;
  public submitted = false;
  // private returnUrl: string;
  public title: any = 'Client App';
  private currentUser = null;
  public login: any;
  public selectedVersammlung: any = {name : ''};
  public vertreterAnmeldungNotificationTime = 2000;
  private animations: any = {
      currentState : 'initial'
  };
  public settingsFromDb: any = {singleLogin : 'true', foo: 'bar'};
  public disclaimer: any = {
      checkboxes: {
          check1: false,
          check2: false,
          check3: false
      }
  };

  //  faCoffee = faCoffee;


  // https://stackblitz.com/edit/angular-8-registration-login-example?file=app%2F_helpers%2Fauth.guard.ts
  constructor(
    private dex: DataExchangeService,
    public _socketService: SocketService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private http: HttpClient,
    private dialog: MatDialog,
    private logger: LoggerService,
    private apc: AppComponent,
    private vertretungService: VertretungenService,
    private eventEmitterService: EventEmitterService
  )
  {
      this.login = {
          formactive: false,
          passwort: '',
          nachname: '',
          resetTimer: null
      };

  }

  ngOnInit(): void
  {
      this.currentUser = this.authenticationService.currentUserValue;
      this.initEventEmitter();
      this.initForm();
      // get return url from route parameters or default to '/'
      // this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/mobile-client/lockedscreen';
      if (this.currentUser)
      {
          setTimeout(() =>
          {
          // this.router.navigate(['/mobile-client/lockedscreen']);
          }, 10);
      }
      // this.dex.eventEmitter.next({cmd: e_command_enum.disableTopBarLogo ,data : {}});
      this.eventEmitterService.eventEmitter.next({cmd: e_command_enum.showHeader , data: {display: 'none'}});
      this.apc.contentWrapper.nativeElement.classList.add('background-vulcavo');
      this.loginBtn();
  }

  private initForm()
  {
      this.loginForm = this.formBuilder.group({
          nachname: ['', [Validators.required, Validators.minLength(2)]], //Validators.pattern("^[0-9]*$")
          password: ['', [Validators.required, Validators.min(100000), Validators.max(999999), numberValidator.numeric]]
      });
  }

  public checkAuthUser()
  {
      if(this.currentUser != null || this.currentUser)
      {
          this.onSocketInit();
      }
      else
      {
          this.dex.deleteSelectedVersammlung();
      }
  }

  public onSocketInit()
  {
      this.logger.info('onSocketInit');
      this.initListener();
      this.loadDataFromSocket();
      // init refresh Checker
      this.logger.info(this.dex.refreshTimer);
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void
  {
      this.apc.contentWrapper.nativeElement.classList.remove('background-vulcavo');
      // this.alertSubscription.unsubscribe();
      // this.dex.eventEmitter.next({cmd: e_command_enum.showTopBarLogo ,data : {}});
      this.eventEmitterService.eventEmitter.next({cmd: e_command_enum.showHeader , data: {display: 'inherit'}});
      if(this.listenerInited === true)
      {
          this.eventEmitterSubscription.unsubscribe();
          this.unsubscribeAll();
      }
  }

  unsubscribeAll()
  {
      this.anmeldungListener.unsubscribe();
      this.selectedVersammlungListener.unsubscribe();
      this.listenerInited = false;
      this.selectedVersammlungFromDbListener.unsubscribe();
  }
  // convenience getter for easy access to form fields
  get f()
  {
      return this.loginForm.controls;
  }
  loadDataFromSocket(){}

  changeState()
  {
      this.animations.currentState = this.animations.currentState === 'initial' ? 'final' : 'initial';
  }

  initEventEmitter()
  {
      // Event Emitter
      // For Anmeldungen über Barcode-Scanner
      this.eventEmitterSubscription = this.eventEmitterService.eventEmitter.subscribe(msg =>
      {
          try
          {
              if(msg.hasOwnProperty('cmd'))
              {
                  const cmd = msg.cmd;
                  if(cmd === e_event_emitter_enum.connect)
                  {
                      this.logger.info(msg, 'Connect');
                  }
                  if(cmd === e_event_emitter_enum.disconnect)
                  {
                      this.logger.info(msg, 'Disconnect');
                  }
                  if(cmd === e_event_emitter_enum.startNotificationTimer)
                  {
                      this.logger.info('startNotificationTimer');
                      this.vertreterAnmeldungNotificationTime = 0;
                      this.startNotificationTimer();
                  }
                  if(cmd === e_event_emitter_enum.onSocketInit )
                  {
                      this.logger.info('> EM onSocketInit');
                      if(this.listenerInited === true)
                      {
                          this.unsubscribeAll();
                      }
                      this.onSocketInit();
                  }
              }
          }
          catch(e)
          {
              this.logger.error(e);
          }
      });
  }

  initListener()
  {
      this.listenerInited = true;
      // this.alertSubscription = this.alertService.getAlert()
      // .subscribe(alert => {
      //   this.logger.info(alert, "alert");
      //     this.alerts = alert;
      // });
      // For Anmeldungen über Barcode-Scanner
      this.selectedVersammlungListener = this.dex.currentSelectedVersammlung.subscribe(vs =>
      {
          try
          {
              this.selectedVersammlung = vs;
          }
          catch(e)
          {
              this.logger.error(e);
          }
      });

      this.selectedVersammlungFromDbListener = this._socketService.response_querySelectedVersammlung().subscribe( async (data: any )=>
      {
          try
          {
              if(HelperFunctions.Misc.isJson2(data) === 'string')
              {
                  data = JSON.parse(data);
                  const response = data.response;
                  const submitted = data.submitted;
                  this.logger.info(data, '[querySelectedVersammlung From Server ]');
                //   if(submitted.data.text === 'login_code_1')
                //   {
                //   }
                //   if(submitted.data.text === 'login_code_3')
                //   {
                //   }
              }
          }
          catch(e)
          {
              this.logger.error(e);
          }
      });



      // Anmeldungslistener
      this.anmeldungListener = this._socketService.response_anmeldung().subscribe( async (data: any )=>
      {
          try
          {
              if(HelperFunctions.Misc.isJson2(data) === 'string')
              {
                  data = JSON.parse(data);
                  if(data.response)
                  {
                      this.response_anmeldung_handler(data);
                  }
              }
          }
          catch(e)
          {
              this.logger.error(e);
          }
      });
  }

  loginBtn()
  {
      this.eventEmitterService.eventEmitter.next({cmd: e_command_enum.showTopBarLogo ,data : {}});
      this.login.formactive = true;
  }

  disconnectClient()
  {
      this._socketService.stopConnection();
      this.logger.info('disconnect');
  }



  startNotificationTimer()
  {
      this.login.resetTimer = setTimeout(() =>
      {
          this.loading = false;
          this.submitted= false;
          this.alertService.error(this.dex.error.no_socket_callback);
          this.alertService.clearAfterTime(5000);
      }, 3000);
  }
    anmelden(deleteVertretung = false)
    {
        // Socket Starten für Verbindung zum Server
        this.authenticationService.logout();
        const currentUserLoginData: loginParameters = {
            passwort: this.f.password.value,
            nachname: this.f.nachname.value,
            deleteVertretung: deleteVertretung
        };

        //   currentUserLoginData.passwort = currentUserLoginData.passwort;
        this._socketService.startConnection(currentUserLoginData, this.dex.hausverwalter);

        this.submitted = true;
        // stop here if form is invalid
        if (this.loginForm.invalid)
        {
            return;
        }
        this.loading = true;
        // Show Loginerror
        this.startNotificationTimer();
    }
  // Handler for user anmeldung
  response_anmeldung_handler(data)
  {
      const response = data.response;
      this.logger.info(data, '[login.component]  response_anmeldung');

      window.clearTimeout(this.login.resetTimer);
      this.alertService.clearAfterTime(5000);

    //   if((data.resultnotice.code === 1 || data.resultnotice.code === 3 ) && data.hasOwnProperty('selectedVersammlung') )
    //   {
    //       this.logger.info(data.selectedVersammlung, 'setSelectedVersammlung in response_anmeldung_handler');
    //       this.dex.saveSelectedVersammlung(data.selectedVersammlung);
    //   }
      this.loading = false;

      switch(data.resultnotice.code)
      {
      case -5: // Anmeldung nicht gestattet, da keine selektierte aktive Versammlung
        // this.alertService.error(this.dex.error.keine_versammlung);
        this.alertService.messageAfterTime(0,8000,{type: alert_message_type.error, text:  this.dex.error.keine_versammlung_durchgefuehrt},true);
        this.authenticationService.logout();
        break;
      case -4: // Anmeldung nicht gestattet, da Anmeldung über TN mit internen Vertreter unzulässig
          // this.alertService.error(this.dex.error.teilnehmer_ist_gesperrt);
          this.alertService.messageAfterTime(0,8000,{type: alert_message_type.error, text:  this.dex.error.teilnehmer_ist_gesperrt}, true);
          break;
      case -3: // Anmeldung nicht gestattet, da keine selektierte aktive Versammlung
          // this.alertService.error(this.dex.error.keine_versammlung);
          this.alertService.messageAfterTime(0,8000,{type: alert_message_type.error, text:  this.dex.error.keine_versammlung},true);
          this.authenticationService.logout();
          break;
      case -2: // Anmeldung nicht gestattet, da nicht für die Versammlung zugelassen
          // this.alertService.error(this.dex.error.teilnehmer_nicht_vorgesehen);
          this.alertService.messageAfterTime(0,8000,{type: alert_message_type.error, text:  this.dex.error.teilnehmer_nicht_vorgesehen},true);
          break;
      case -1: // Teilnehmer gibts nicht
          // this.alertService.error(this.dex.error.anmeldung_invalid);
          this.alertService.messageAfterTime(0,8000,{type: alert_message_type.error, text:  this.dex.error.anmeldung_invalid},true);

          break;
      case 0: // Anmeldung nicht gestattet da der Teilnehmer einen Hausverwalter als Vertreter hat
          // this.alertService.messageAfterTime(0,8000,{type: alert_message_type.error, text:  this.dex.error.user_has_hausverwalter_vertreter},true);
          this.openVertretungLoeschenModal(data);
          break;
      case 1:
          // user ist korrekt anmeldung erfolgt
          this.submitted = false;
          break;
      case 2:
          // this.alertService.messageAfterTime(0,8000,{type: alert_message_type.error, text:  this.dex.error.user_has_vertreter_error},true);
          this.openVertretungLoeschenModal(data);
          break;
      case 3: // Teilnehmer hat einen Vertreter
          this.submitted = false;
          this.alertService.messageAfterTime(0,8000,{type: alert_message_type.success, text:  this.dex.error.user_has_vertreter_success},true);
          response[0].code = data.resultnotice.code;
          break;
      default:
          break;
      }

      // Timeout wegen DDOS Security
      setTimeout(() =>
      {
          this.submitted = false;
          this.loading = false;
      }, 3000);
  }

    openVertretungLoeschenModal(anmeldungResponseData: any = null)
    {
        // open Modal StandardBestaetigenModal
        const modalRef = this.dialog.open(StandardBestaetigenModal, {
            width: "85vw",
            ariaLabelledBy: 'modal-basic-title',

        });
        modalRef.componentInstance.data = anmeldungResponseData;
        modalRef.componentInstance.afterViewinit.subscribe((modal : StandardBestaetigenModal) => {
            modal.content.nativeElement.innerHTML =
                `Möchten Sie die Vertretung durch <b>${anmeldungResponseData.resultnotice.vertreter.vorname.trim()} ${anmeldungResponseData.resultnotice.vertreter.nachname === "" ? "" : anmeldungResponseData.resultnotice.vertreter.nachname.trim()+" "}</b>entfernen und die Anmeldung fortsetzen, um an der Versammlung teilzunehmen?`;
            modal.okBtn.nativeElement.innerHTML = `Vertretung entfernen und anmelden  <i class="pl-2 fas fa-caret-right"></i>`;
            modal.okBtn.nativeElement.classList.add(`ml-auto`);
            // modal.closeBtn.nativeElement.style.display = 'none';
            // modal.zusatzBtn.nativeElement.style.display = 'inherit';
        });
        modalRef.disableClose = true;

        modalRef.componentInstance.onInit.subscribe((modal: StandardBestaetigenModal) => {
            // modal.data = {selectedVersammlung: this.selectedVersammlung};
            modal.header = `Sie werden bereits vertreten`;
            // modal.cancel_function = null;
        });

        modalRef.componentInstance.afterOkpressed.subscribe(async (data: any) => {
            console.log("anmeldungResponseData ",anmeldungResponseData);
            // const parameters: parameters__vertretung_loeschen = {
            //     cmd: vertretungCMDs.vertretungLoeschen,
            //     versammlung_id: anmeldungResponseData.selectedVersammlung.versammlung_id,
            //     teilnehmer_id: anmeldungResponseData.response[0].teilnehmer_id,
            // }
            // // this._socketService.vertretungen(parameters);
            // let callback = await this.vertretungService.vertretungloeschen(parameters);
            // console.log("anmeldungResponseData ",callback);
            this.anmelden(true);
            modalRef.close();
            // modalRef = null;
        })

        modalRef.componentInstance.afterClosepressed.subscribe((data: any) => {
            modalRef.close();
        })

        modalRef.componentInstance.ok_btn = function(text, router){
        };

    }
  // Error Messages
  getErrorMessage(formControlName: string)
  {
      // console.log(formControlName,"\n",this.loginForm, '\nhasErrors ',this.loginForm.controls.nachname.errors);
      let error_text : string = "";
      if (this.loginForm.controls[formControlName].hasError('required'))
      {
          switch(formControlName){
            case formControlNames.nachname:
              error_text = `Bitte tragen Sie Ihren Nachnamen ein`;
            break;
            case formControlNames.password:
              error_text = `Bitte tragen Sie Ihren Zugangscode ein`;
            break;
          }
          return error_text;
      }

      this.loginForm.controls.nachname.hasError('minlength') ? error_text = 'Bitte tragen Sie einen plausiblen Namen ein' : '';
      // this.loginForm.controls.nachname.hasError('min') ? error_text = '2Bitte 6 Zahlen eingeben' : '';
      this.loginForm.controls.password.hasError('min') ? error_text = 'Bitte geben Sie Ihren 6 stelligen Zugangscode ein' : '';
      this.loginForm.controls.password.hasError('max') ? error_text = 'Diese Eingabe ist nicht gestattet' : '';
      this.loginForm.controls.password.hasError('invalidNumber') ? error_text = 'Bitte ausschließlich Zahlen eingeben' : '';
      // return this.loginForm.controls.nachname.hasError('email') ? 'Falscher Nachname' : '';
      return error_text;
  }

  passwortVergessen()
  {
      const self = this;
      const parameters = {
          cmd: 'passwortAnfordern',
          data: {nachname: this.loginForm.value.nachname, passwort: this.loginForm.value.password}
      };

      this.logger.info(parameters, 'passwortVergessen');
      this.logger.info(this._socketService.getSettings());
      const headers= new HttpHeaders({
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Headers': 'Content-Type',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
          key: 'x-api-key',
          value: 'NNctr6Tjrw9794gFXf3fi6zWBZ78j6Gv3UCb3y0x',
      });

      const body = { title: 'Angular POST Request Example' };

      // eslint-disable-next-line @typescript-eslint/dot-notation
      let port = this._socketService.getSettings().http_port;
      if (environment.production == true || this._socketService.getSettings().prefix === "https") {} else {port = 88 }
      const url = this._socketService.getSettings().prefix+`://${this._socketService.getSettings().server_ip}`+':'+this._socketService.getSettings().http_port+'/mobile-client/passwort-anfordern';
      this.logger.info(url);

      callServer();
      this.alertService.clear();
      this.alertService.info(this.dex.info.email_versand_gestartet);

      this.alertSetTimeout = setTimeout(() =>
      {
          this.alertService.clear();
          this.alertService.error(this.dex.error.email_versand_fehlgeschlagen);
          this.alertService.clearAfterTime(4000);
      }, 10000);

      function callServer()
      {
      // let self = this;
          try
          {
              const headers_ = new HttpHeaders()
                  .set('Authorization', 'my-auth-token')
                  .set('Content-Type', 'application/json');

              self.http.post(url, JSON.stringify({nachname: self.loginForm.value.nachname, passwort: self.loginForm.value.password}), {
                  headers: headers_
              })
                  .subscribe((data: any) =>
                  {
                      try
                      {
                          self.logger.info(data);
                          clearTimeout(self.alertSetTimeout);
                          if(data[0].code === 1)
                          {
                              // success
                              self.alertService.success(self.dex.info.email_erfolgreich_verschickt+`. Bitte prüfen Sie ihr Email Postfach ${data[0]?.callbacks[0]?.accepted[0]}`);

                          }
                          if(data[0].code === -1)
                          {
                              // no user
                              self.alertService.warning(self.dex.warning.email_kein_teilnehmer);
                          }
                          self.alertService.clearAfterTime(8000);
                      }
                      catch (error)
                      {
                          self.logger.error(error);
                      }
                  });
          }
          catch (error)
          {
              self.logger.error(error);
          }
      }


  }


  public openDisclaimerModal()
  {
      // open Modal DisclaimerModal
      const data: any = {
          selectedVersammlung: this.selectedVersammlung,
          checkboxes: this.disclaimer.checkboxes
      };
      const modalRef = this.dialog.open(DisclaimerModal, {
          // width: "85vw",
          ariaLabelledBy: 'modal-basic-title',

      });
      modalRef.componentInstance.onInit.subscribe((modal: DisclaimerModal) =>
      {
          modal.data = data;
          modal.header = 'Teilnahmebedingungen';
      });

      modalRef.componentInstance.afterViewinit.subscribe((modal: DisclaimerModal) =>
      {

          modal.okBtn.nativeElement.innerHTML = 'Einverstanden';

      });

      modalRef.componentInstance.afterHandlerFunctionOneClick.subscribe((element: any ) =>
      {
          setTimeout(() =>
          {
          // console.log("click ",element , " - ",modalRef.componentInstance.data.checkboxes)
          // console.log(element.id);
              if(element.id)
              {
                  let chk_number = 0;
                  if(element.id.includes('text'))
                  {
                      chk_number = parseInt(element.id.split('text')[1]);
                  }

                  if(element.id.includes('checkbox'))
                  {
                      chk_number = parseInt(element.id.split('checkbox')[1]);
                  }
                  const chk_elem = `check`+chk_number;
                  // Switch Checkbox
                  if(element.id.includes('text'))
                  {
                      modalRef.componentInstance.data.checkboxes[chk_elem] === true ? modalRef.componentInstance.data.checkboxes[chk_elem] = false : modalRef.componentInstance.data.checkboxes[chk_elem] = true;
                  }
              }
          }, 2);
      });

      modalRef.disableClose = true;


      modalRef.componentInstance.afterOkpressed.subscribe((data: any) =>
      {
          this.disclaimer.checkboxes = modalRef.componentInstance.data.checkboxes;
          modalRef.close();
      });

      modalRef.componentInstance.ok_btn = function(text, router)
      {

      };
      // modalRef.componentInstance.cancel_function = null;
  }

  // Neues Anmelde-Design
  handlerFunctionOne(element)
  {
      setTimeout(() =>
      {
      // eslint-disable-next-line @typescript-eslint/quotes
          // console.log(element.id);
          if(element.id)
          {
              let chk_number = 0;
              if(element.id.includes('text'))
              {
                  chk_number = parseInt(element.id.split('text')[1]);
              }

              if(element.id.includes('checkbox'))
              {
                  chk_number = parseInt(element.id.split('checkbox')[1]);
              }
              const chk_elem = `check`+chk_number;
              // Switch Checkbox
              if(element.id.includes('text'))
              {
                  this.disclaimer.checkboxes[chk_elem] === true ? this.disclaimer.checkboxes[chk_elem] = false : this.disclaimer.checkboxes[chk_elem] = true;
              }
          }
      }, 2);

  }


}
