
<ng-container *ngIf="selectedTagesordnungspunkt != null">
    <ng-container [ngSwitch]="selectedTagesordnungspunkt?.qualifizierungstyp?.stimmgeber?.s1?.key">
        <!-- Alle Stimmgeber / Unterscheidung findet in ts file statt -->
        <div class="row abstimmung-container">
            <!-- 1er Stimmgeber -->
            <div class="col col-6" *ngIf="abstimmErgebnisse.meaStimmenGesamt" [ngClass]="{'col-12': this.displayOptions.anwesendNichtAbgestimmtZuEnthaltung___ == true}">
                <div class="row pb-1 justify-content-start legend-top">
                    <div class="col col-8 offset-md-4 align-self-center">
                        <span class="msg-legende-live-ergebnisse"></span>
                    </div>
                </div> 
    
                <!-- JA -->
                <div class="row justify-content-end ja">
                    <div class="col col-4 align-self-center">
                        <div class="row justify-content-end prefix-label ">
                            <span class="text-right">{{this.abstimmErgebnisse_s1.ja.geteilt}}<small>  Ja</small></span>
                        </div>                                        
                    </div>
                    <div class="col col-8">
                        <ngb-progressbar class="vulcavo-abstimmung-chart" [showValue]="false" type="info" [value]="abstimmErgebnisse_s1.ja.mea" [max]="this.abstimmErgebnisse_s1.meaStimmenGesamt">{{this.globals.germanDecimal2(abstimmErgebnisse_s1.ja.mea)}}</ngb-progressbar>
                    </div>
                </div>

                <!-- NEIN -->
                <div class="row nein justify-content-end mt-1">
                    <div class="col col-4 align-self-center">
                        <div class="row justify-content-end prefix-label">
                            <span class="text-right">{{this.abstimmErgebnisse_s1.nein.geteilt}} <small>Nein</small> </span>
                        </div>                                        
                    </div>
                    <div class="col col-8">
                        <ngb-progressbar class="vulcavo-abstimmung-chart" [showValue]="false" type="info" [value]="abstimmErgebnisse_s1.nein.mea" [max]="this.abstimmErgebnisse_s1.meaStimmenGesamt">{{this.globals.germanDecimal2(abstimmErgebnisse_s1.nein.mea)}}</ngb-progressbar>
                    </div>
                </div>
                <ng-container *ngIf="selectedTagesordnungspunkt?.qualifizierungstyp?.stimmgeber?.s1?.relation == 1">
                    <!-- ENTHALTUNG -->
                    <div class="row enthaltung justify-content-end mt-1">
                        <div class="col col-4 align-self-center">
                            <div class="row justify-content-end prefix-label">
                                <span class="text-right">{{this.abstimmErgebnisse_s1.enthaltung.geteilt}} <small>Enthaltung</small></span>
                            </div>                    
                        </div>
                        <div class="col col-8">
                            <ngb-progressbar class="vulcavo-abstimmung-chart" [showValue]="false" type="info" [value]="abstimmErgebnisse_s1.enthaltung.mea" [max]="this.abstimmErgebnisse_s1.meaStimmenGesamt">{{this.globals.germanDecimal2(abstimmErgebnisse_s1.enthaltung.mea)}}</ngb-progressbar>
                        </div>
                    </div>
                    <!-- ANWESEND -->
                        <!-- Aufsplittung nicht abgestimmt anwesend und abwesend -->
                        <!-- <div class="row nicht-abgestimmt-anwesend justify-content-end mt-1">
                            <div class="col col-4 align-self-center">
                                <div class="row justify-content-end prefix-label">
                                    <span class="text-right"><small>{{this.abstimmErgebnisse_s1.anwesend.geteilt}} <i class="fas fas fa-circle anwesend"></i><i class="fas fas fa-circle pl-1 angemeldet"></i></small> Nicht abgestimmt anwesend </span>
                                </div>                    
                            </div>
                            <div class="col col-4">
                                <ngb-progressbar class="vulcavo-abstimmung-chart" [showValue]="false" type="info" [value]="abstimmErgebnisse_s1.anwesend.mea" [max]="abstimmErgebnisse_s1.meaStimmenGesamt">{{abstimmErgebnisse_s1.anwesend.mea}}</ngb-progressbar>
                            </div>
                            <div class="col col-4 align-self-center max-value prefix-label">           
                                <span>{{abstimmErgebnisse_s1.meaStimmenGesamt}}</span>
                            </div>
                        </div> -->
                        <!-- ABWESEND -->
                        <div class="row nicht-abgestimmt-abwesend justify-content-end mt-1">
                            <div class="col col-4 align-self-center">
                                <div class="row justify-content-end prefix-label">
                                    <span class="text-right">{{this.abstimmErgebnisse_s1.abwesend.geteilt}} <small>  Abwesend</small></span>
                                </div>                    
                            </div>
                            <div class="col col-8">
                                <ngb-progressbar class="vulcavo-abstimmung-chart" [showValue]="false" type="info" [value]="abstimmErgebnisse_s1.abwesend.mea" [max]="abstimmErgebnisse_s1.meaStimmenGesamt">{{this.globals.germanDecimal2(abstimmErgebnisse_s1.abwesend.mea)}}</ngb-progressbar>
                            </div>
                        </div>      
                    </ng-container>
                    <!-- BOTTOM -->
                    <!-- <div class="row bottom_legend justify-content-end">
                        <div class="col col-4 align-self-center">
                        </div>
                        <div class="col col-8">
                            <hr>
                            <div class="row justify-content-end mr-2">
                                <div class="col-xs-auto"><span [innerHTML]="this.abstimmErgebnisse_s1.legend_bottom"></span></div>
                            </div>                        
                        </div>
                    </div> -->
            </div> 

            <!-- 2er Stimmgeber -->
            <div class="col col-6" *ngIf="abstimmErgebnisse.meaStimmenGesamt" [ngClass]="{'col-12 mt-3': this.displayOptions.anwesendNichtAbgestimmtZuEnthaltung___ == true}">
                <div class="row pb-1 justify-content-start legend-top">
                    <div class="col col-8 offset-md-4 align-self-center">
                        <span class="msg-legende-live-ergebnisse"></span>
                    </div>
                </div> 
    
                <!-- JA -->
                <div class="row justify-content-end ja">
                    <div class="col col-4 align-self-center">
                        <div class="row justify-content-end prefix-label ">
                            <span class="text-right">{{this.abstimmErgebnisse_s2.ja.geteilt}}<small>  Ja</small></span>
                        </div>                                        
                    </div>
                    <div class="col col-8">
                        <ngb-progressbar class="vulcavo-abstimmung-chart" [showValue]="false" type="info" [value]="abstimmErgebnisse_s2.ja.mea" [max]="this.abstimmErgebnisse_s2.meaStimmenGesamt">{{this.globals.germanDecimal2(abstimmErgebnisse_s2.ja.mea)}}</ngb-progressbar>
                    </div>
                </div>

                <!-- NEIN -->
                <div class="row nein justify-content-end mt-1">
                    <div class="col col-4 align-self-center">
                        <div class="row justify-content-end prefix-label">
                            <span class="text-right">{{this.abstimmErgebnisse_s2.nein.geteilt}} <small>Nein</small> </span>
                        </div>                                        
                    </div>
                    <div class="col col-8">
                        <ngb-progressbar class="vulcavo-abstimmung-chart" [showValue]="false" type="info" [value]="abstimmErgebnisse_s2.nein.mea" [max]="this.abstimmErgebnisse_s2.meaStimmenGesamt">{{this.globals.germanDecimal2(abstimmErgebnisse_s2.nein.mea)}}</ngb-progressbar>
                    </div>
                </div>
                <ng-container *ngIf="selectedTagesordnungspunkt?.qualifizierungstyp?.stimmgeber?.s2?.relation == 1">
                    <!-- ENTHALTUNG -->
                    <div class="row enthaltung justify-content-end mt-1">
                        <div class="col col-4 align-self-center">
                            <div class="row justify-content-end prefix-label">
                                <span class="text-right">{{this.abstimmErgebnisse_s2.enthaltung.geteilt}} <small>Enthaltung</small></span>
                            </div>                    
                        </div>
                        <div class="col col-8">
                            <ngb-progressbar class="vulcavo-abstimmung-chart" [showValue]="false" type="info" [value]="abstimmErgebnisse_s2.enthaltung.mea" [max]="this.abstimmErgebnisse_s2.meaStimmenGesamt">{{this.globals.germanDecimal2(abstimmErgebnisse_s2.enthaltung.mea)}}</ngb-progressbar>
                        </div>
                    </div>
                    
                    <!-- ANWESEND -->
                        <!-- Aufsplittung nicht abgestimmt anwesend und abwesend -->
                        <!-- <div class="row nicht-abgestimmt-anwesend justify-content-end mt-1">
                            <div class="col col-4 align-self-center">
                                <div class="row justify-content-end prefix-label">
                                    <span class="text-right"><small>{{this.abstimmErgebnisse_s2.anwesend.geteilt}} <i class="fas fas fa-circle anwesend"></i><i class="fas fas fa-circle pl-1 angemeldet"></i></small> Nicht abgestimmt anwesend </span>
                                </div>                    
                            </div>
                            <div class="col col-4">
                                <ngb-progressbar class="vulcavo-abstimmung-chart" [showValue]="false" type="info" [value]="abstimmErgebnisse_s2.anwesend.mea" [max]="abstimmErgebnisse_s2.meaStimmenGesamt">{{abstimmErgebnisse_s2.anwesend.mea}}</ngb-progressbar>
                            </div>
                            <div class="col col-4 align-self-center max-value prefix-label">           
                                <span>{{abstimmErgebnisse_s2.meaStimmenGesamt}}</span>
                            </div>
                        </div> -->
                        <!-- ABWESEND -->
                        <div class="row nicht-abgestimmt-abwesend justify-content-end mt-1">
                            <div class="col col-4 align-self-center">
                                <div class="row justify-content-end prefix-label">
                                    <span class="text-right">{{this.abstimmErgebnisse_s2.abwesend.geteilt}} <small>  Abwesend</small></span>
                                </div>                    
                            </div>
                            <div class="col col-8">
                                <ngb-progressbar class="vulcavo-abstimmung-chart" [showValue]="false" type="info" [value]="abstimmErgebnisse_s2.abwesend.mea" [max]="abstimmErgebnisse_s2.meaStimmenGesamt">{{this.globals.germanDecimal2(abstimmErgebnisse_s2.abwesend.mea)}}</ngb-progressbar>
                            </div>
                        </div>  
                </ng-container>                
            </div>  
        </div>  
        <ng-container>
            <hr class="mt-1 mb-1">
            <div class="row bottom_legend ft-125 mt-2"> 
                <div class="col text-right">
                    <div [innerHTML]="this.abstimmErgebnisse_s1.legend_bottom" class=""></div>
                </div>
                <div class="col text-right">
                    <div [innerHTML]="this.abstimmErgebnisse_s2.legend_bottom" class=""></div>
                </div>
            </div>  
        </ng-container>  
    </ng-container> 
</ng-container>







