<!-- neue Optik -->
<div class="lockedscreenContent animated--grow-in justify-content-center row">
    <div class="align-self-center"
    [ngClass]="{'col-12 col-xs-12 col-md-10 col-lg-6 col-xl-4': sambaService?.displayVideoConferenceSrc.value === false, 'col-12': sambaService?.displayVideoConferenceSrc.value === true}">
        <div class="mt-0">
            <app-alert></app-alert>
        </div>

        <div class="row justify-content-center mt-0">
            <div class="col col-12">
                <div class="align-items-center justify-content-center">
                    <p class="mb-0">
                        <strong>Herzlich Willkommen</strong>
                    </p>
                </div>
            </div>
        </div>

        <div class="row justify-content-between">
            <div class="col col-12">
                <div class="d-flex align-items-center justify-content-center">
                    <p class="h3">{{currentUser?.vorname}} {{currentUser?.nachname}}
                    </p>
                </div>
            </div>
        </div>
        <div class="row justify-content-between" *ngIf="currentUser?.vertreter_vorname">
            <div class="col col-12">
                <div class="d-flex align-items-center justify-content-center">
                    <p class="h5">
                        Vertreten durch
                    </p>
                </div>
            </div>
        </div>
        <div class="row justify-content-between" *ngIf="currentUser?.vertreter_vorname">
            <div class="col col-12">
                <div class="d-flex align-items-center justify-content-center">
                    <h2 class="h4">
                        {{currentUser?.vertreter_vorname+" "+currentUser?.vertreter_nachname}}
                    </h2>
                </div>
            </div>
        </div>

        <div class="row justify-content-center mt-3">
            <div class="d-flex align-items-center justify-content-center">
                <div class="col col-6">
                    <img src="../../../assets/images/icons/sanduhr-warten.png" class="uhr-icon" />
                </div>
            </div>
        </div>

        <!-- ungenutzt?  -->
        <div class="row justify-content-between mt-4" *ngIf="buttons.logout === true">
            <div class="col col-12">
                <div class="d-flex align-items-center justify-content-center">
                    <div class="col col-4">
                        <button type="submit" class="btn btn-primary vulcavo-btn form-control mt-2 " style="white-space: inherit ! important;" (click)="logout()">
                        Logout
                            <span class="pull-right float-right">
                                <i class="fas fa-caret-right"></i>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="justify-content-center mt-3 mb-2">
            <div class="col col-12">
                <div class="d-flex align-items-center justify-content-center">
                    <p>Bitte haben Sie noch etwas Geduld. Die Versammlung <strong>"{{selectedVersammlung?.name}}"</strong> beginnt in Kürze.</p>
                </div>
            </div>
        </div>
    </div>
</div>
