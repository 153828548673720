<div class="modal-header">
    <h2 class="modal-title" id="modal-basic-title">{{header}}</h2>
    <!-- <h2 class="modal-title" id="modal-basic-title">Teilnahmebedingungen</h2> -->
    <!-- <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click'), this.dialogRef.close()"> -->
    <button type="button" class="close" aria-label="Close" (click)="this.dialogRef.close()">
      <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body angular-with-newlines" #content>
    <!-- <p>Ich bestätige hiermit folgende Teilnahmebedingungen:</p> -->
    <div class="row justify-content-center mb-3">

        <div class="col-12 pl-2">
          Ich bestätige hiermit folgende Teilnahmebedingungen:
        </div>
    </div>
    <div class="w-100"></div>
    <div class="row d-flex align-items-start justify-content-start disclaimer-row">
      <div class="col-xs-auto ml-2">
        <mat-checkbox id="checkbox1" 
              (ngModelChange)="handlerFunctionOne($event)"   
              [(ngModel)]="this.data.checkboxes.check1"
          >            
        </mat-checkbox>
      </div>
      <div class="col col-10 col-xs-auto" (click)="handlerFunctionOne($event.target)" id="text1" >
        Es handelt sich bei der anzumeldenden Person um mich persönlich oder um einen vom Hausverwalter genehmigten Vertreter bzw. Bevollmächtigten.
      </div>
    </div>

    <div class="row d-flex align-items-start justify-content-start disclaimer-row mt-2">
      <div class="col-xs-auto ml-2">
        <mat-checkbox class="" id="checkbox2" 
              (ngModelChange)="handlerFunctionOne($event)"   
              [(ngModel)]="this.data.checkboxes.check2"
          >            
        </mat-checkbox>
      </div>
      <div class="col col-10 col-xs-auto" (click)="handlerFunctionOne($event.target)" id="text2" >
        Die Versammlung findet unter Ausschluss Dritter statt, jegliche in der Versammlung genannten Daten & Informationen werden nicht an Dritte weitergeleitet.
      </div>
    </div>

    <div class="row d-flex align-items-start justify-content-start disclaimer-row mt-2">
      <div class="col-xs-auto ml-2">
        <mat-checkbox class="" id="checkbox3" 
              (ngModelChange)="handlerFunctionOne($event)"   
              [(ngModel)]="this.data.checkboxes.check3"
          >           
        </mat-checkbox>
      </div>
      <div class="col col-10 col-xs-auto" (click)="handlerFunctionOne($event.target)" id="text3" > 
        Ton- oder Video-Aufnahmen sind allgemein nicht gestattet.
      </div>
      
    </div>

</div>
<div class="modal-footer">   
    <ng-container *ngIf="cancel_function">
      <button type="button" #cancelBtn class="btn btn-outline-dark" (click)="cancel_function()">Abbrechen</button>
    </ng-container>
         
    <button type="button" #okBtn ngbAutofocus class="btn btn-info" (click)="ok_function()">Weiter</button>
    
</div>