/* eslint-disable indent */
/* eslint-disable @typescript-eslint/naming-convention */
import {CommonModule, DatePipe, DecimalPipe, registerLocaleData} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {
  NgbAccordionModule,
  NgbActiveModal,
  NgbAlertModule,
  NgbButtonsModule,
  NgbCarouselModule,
  NgbCollapseModule,
  NgbDatepickerModule,
  NgbDropdownModule,
  NgbModalModule,
  NgbModule,
  NgbNavModule,
  NgbPaginationModule,
  NgbPopoverModule,
  NgbProgressbarModule,
  NgbRatingModule,
  NgbTimepickerModule,
  NgbToastModule,
  NgbTooltipModule,
  NgbTypeaheadModule
} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AppRoutingModule, appRoutingProviders, routingComponents} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CdkTableModule} from '@angular/cdk/table';
import {MatIconModule} from '@angular/material/icon';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatCardModule,} from '@angular/material/card';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatDividerModule} from '@angular/material/divider';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatButtonModule} from '@angular/material/button';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import {MatSelectModule} from '@angular/material/select';
import {MatRadioModule} from '@angular/material/radio';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTreeModule} from '@angular/material/tree';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSortModule} from '@angular/material/sort';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatDialogModule} from '@angular/material/dialog';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatBadgeModule} from '@angular/material/badge';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatCommonModule, MatNativeDateModule} from '@angular/material/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {ErrorInterceptor, fakeBackendProvider, JwtInterceptor} from './_helpers';
import {SocketService} from './_services/socket.service';
import {TopbarComponent} from './components/topbar/topbar.component';
import {NgbdModalFocusModule} from './components/modals/modalModule';
import {Router} from "@angular/router";
import {ToastrModule} from 'ngx-toastr';
import * as Sentry from "@sentry/angular";

import localeDE from "@angular/common/locales/de";
import localeDEExtra from '@angular/common/locales/extra/de';
import {LocalNumberPipe} from './services/numberFormatter';
import { EinfacheMehrheitAtComponent } from './components/ergebniss/einfache-mehrheit-at/einfache-mehrheit-at.component';

registerLocaleData(localeDE, localeDEExtra);


const animations = {
  trigger,
  state,
  style,
  animate,
  transition
};

const chartModules = [
  // ChartsModule
];

const networkModules = [
  HttpClientModule,
];

const genericModules = [
  CommonModule,
  BrowserAnimationsModule,
  BrowserModule,
  // NgModule,
  FormsModule

];

const routingModules = [
  AppRoutingModule,
];

const bootstrapModules = [
  NgbModule,
  ReactiveFormsModule,
  NgbAccordionModule,
  NgbAlertModule,
  NgbModalModule,
  NgbButtonsModule,
  NgbCarouselModule,
  NgbCollapseModule,
  NgbDatepickerModule,
  NgbDropdownModule,
  NgbNavModule,
  NgbPaginationModule,
  NgbRatingModule,
  NgbPopoverModule,
  NgbProgressbarModule,
  NgbTimepickerModule,
  NgbToastModule,
  NgbTooltipModule,
  NgbTypeaheadModule,
  NgbdModalFocusModule
];

const ngMaterialModules = [
  CdkTableModule,
  MatIconModule,
  MatButtonModule,
  MatFormFieldModule,
  MatInputModule,
  MatProgressBarModule,
  MatSliderModule,
  MatCommonModule,
  MatDividerModule,
  MatAutocompleteModule,
  MatCardModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatIconModule,
  MatSidenavModule,
  MatToolbarModule,
  MatListModule,
  MatSelectModule,
  MatRadioModule,
  MatGridListModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatTooltipModule,
  MatTreeModule,
  MatSlideToggleModule,
  MatSortModule,
  MatSnackBarModule,
  MatProgressSpinnerModule,
  MatDialogModule,
  MatCheckboxModule,
  MatButtonToggleModule,
  MatBottomSheetModule,
  MatBadgeModule,
  MatPaginatorModule,
  MatExpansionModule
];

const authenticationProviders = [
  {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
  {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
  fakeBackendProvider
];

const providers_collection = [
  SocketService,
  appRoutingProviders,
  DatePipe,
  DecimalPipe,
  authenticationProviders,
  NgbActiveModal,
  TopbarComponent,
  {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {floatLabel: 'always'}},
  // {provide: DecimalPipe, useValue: "1.0-2"},
  // {provide: DecimalPipe, useValue: "de"},
  {provide: LOCALE_ID, useValue: 'de' },
  {
    provide: ErrorHandler,
    useValue: Sentry.createErrorHandler({
      showDialog: false,
    }),
  },
  {
    provide: Sentry.TraceService,
    deps: [Router],
  },
  {
    provide: APP_INITIALIZER,
    useFactory: () => () => {
    },
    deps: [Sentry.TraceService],
    multi: true,
  }
];

// let settings = {foo:"bar"}
@NgModule({
  imports: [
    genericModules,
    routingModules,
    networkModules,
    bootstrapModules,
    ngMaterialModules,
    BrowserAnimationsModule,
    NgbModule,
    ToastrModule.forRoot({maxOpened: 2, autoDismiss: true})
  ],
  declarations: [
    routingComponents,
    LocalNumberPipe
  ],
  exports: [
    genericModules,
    ngMaterialModules,
    bootstrapModules,
    LocalNumberPipe
  ],
  providers: providers_collection,
  bootstrap: [AppComponent]
})
export class AppModule {
}


