import { Component, Input,  Inject, ElementRef, ChangeDetectorRef } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { SocketService} from '../../_services/socket.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DataExchangeService } from '../../_services/data-exchange.service';
import { Subscription } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LoggerService } from 'src/app/services/logger.service';
import {NewalertService} from "../../_services/newalert.service";

declare let HelperFunctions: any;

// @Directive({
//   selector : '[focus]'
// })
@Component({
  selector: 'app-standard-modal',
  templateUrl: './templates/standardModal.html',
  styleUrls: ['./css/standardModal.scss']
})

export class StandardModalComponent{
  @Input() header;
  @Input() text;
  @Input() focus : boolean;
  // Vars
  public cancel_btn : any = null;
  public ok_btn : any = null;
  public data: any = null;
  public modalData: any = {};
  public allow_bearbeiten = false;
  public selectedTagesordnungspunkt: any;
  public tagesordnungspunkte: any;
  public selectedVersammlung: any;
  public teilnehmerDropdownData: any;
  public vertreterDropdownData: any;
  public teilnehmerSperrenDropdownData: any;
  public showSecond = false;
  public vertreterName: any = '';
  public anwesenheitsstatusForm : FormGroup;
  public teilnehmerBearbeitenForm : FormGroup;


  //Listener
  private getTagesordnungspunkteListener: Subscription;
  private deleteTagesordnungspunktListener: Subscription;
  constructor(
    public dialogRef: MatDialogRef<StandardModalComponent>,
    // @Inject(MAT_DIALOG_DATA) public data: DialogData,
    @Inject(MAT_DIALOG_DATA) public data_: any,
    private _socketService: SocketService,
    private activeModal: NgbActiveModal,
    private router : Router,
    private fb: FormBuilder,
    private _modalService: NgbModal,
    private dex: DataExchangeService,
    private element: ElementRef,
    private cdr: ChangeDetectorRef,
    private logger: LoggerService,
    private newalertService: NewalertService,
    )
    {
      this.data = {};
      this.modalData = {modalBody: `This is a test`};
      this.header = `Abstimmen`;
      this.cancel_btn = function()
      {
        dialogRef.close();
      }

      document.getElementsByTagName('html')[0].classList.remove('cdk-global-scrollblock');
      this.initListener();
    }
    protected ngOnChanges() {
      if (this.focus)
        this.element.nativeElement.focus();
    }

    ngOnDestroy(): void
    {
      this.getTagesordnungspunkteListener.unsubscribe();
      this.deleteTagesordnungspunktListener.unsubscribe();
    }
    ngAfterContentInit(): void {
      this.cdr.detectChanges();
    }

    public x_function()
    {
      this.dialogRef.close();
    }
    public ok_function()
    {
        if(typeof(this.ok_btn) == 'function')
        {
            this.ok_btn();
        }
    }
    public cancel_function()
    {
        const modal = this.activeModal.close(name);
        if(typeof(this.cancel_btn) == 'function')
        {
            this.cancel_btn();
        }
    }
    onNoClick(): void
    {
      this.dialogRef.close();
    }

    loadDataFromSocket()
    {
      this._socketService.getTagesordnungspunkte({
        versammlung_ids: [this.selectedVersammlung.versammlung_id],
        active: 1
      } );
    }
    initListener()
    {
      // Delete a Tagesordnungspunkte => Update because only set active = 0;
      this.deleteTagesordnungspunktListener = this._socketService.response_deleteTagesordnungspunkt().subscribe((data: string ) => {
        if(HelperFunctions.Misc.isJson2(data) == 'string')
        {
          try
          {
            data = JSON.parse(data);
            this.loadDataFromSocket();
          }catch(e){this.logger.error(e);}
        }
      });

      // Get Tagesordnugnspunkte
      this.getTagesordnungspunkteListener = this._socketService.response_getTagesordnungspunkte().subscribe( (data : any )=>{
        try
        {
          if(HelperFunctions.Misc.isJson2(data) == 'string')
          {
              data = JSON.parse(data);
              if(data.response)
              {
                this.logger.info(data, "response_getTagesordnungspunkte");
                this.tagesordnungspunkte = data.response.tagesordnungspunkte;
              }
          }
        }catch(e){this.logger.error(e);}
      });
    }
}
