
<!-- neue Optik -->
<div class="row mt-3 ml-1 animated--grow-in">
    <div class="col col-6">
      <div class="d-flex align-items-start justify-content-start">
        <img src="../../../assets/images/Pandion_Service2.png" class="vulcavo-sidenav-logo"/>
      </div>
    </div>
  </div>

  <div class="row justify-content-between mt-4">
    <div class="col col-12">
      <div class="d-flex align-items-center justify-content-center">
        <p class="h3" style="margin-bottom: 0px;">Abstimmung Beendet Code: {{code}}</p>       
      </div>
    </div>
  </div>

  <div class="row justify-content-between">
    <div class="col col-12">
      <div class="d-flex align-items-center justify-content-center">
        <div class="h3">von: {{currentUser.vorname}} {{currentUser.nachname}}</div>
      </div>
    </div>
  </div>

<div class="row justify-content-between mt-2 mb-2">
    <div class="col col-12">
        <div class="d-flex align-items-center justify-content-center">
              <div class="h3">{{selectedVersammlung.name}} </div>
        </div>
    </div>
</div>

<app-alert></app-alert>
  
<div class="row justify-content-between mt-4" *ngIf="buttons.logout === true">
    <div class="col col-12">
      <div class="d-flex align-items-center justify-content-center">
        <div class="col col-4">

          <button type="submit" class="btn btn-primary vulcavo-btn form-control mt-2 " style="white-space: inherit ! important;" (click)="logout()"> 
            Logout
              <span class="pull-right float-right">
                  <i class="fas fa-caret-right"></i>
              </span>
          </button>   
        </div>
      </div>
    </div>
</div>



  
