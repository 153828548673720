/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-unsafe-finally */
/* eslint-disable no-prototype-builtins */
import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Inject } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { SocketService } from './_services/socket.service';
import { DataExchangeService } from './_services/data-exchange.service';
import { AuthenticationService } from './_services/authentication.service';
import { LoggerService } from './services/logger.service';
import { e_event_emitter_enum } from './_models/enum';
import { SambaService } from './services/samba.service';
import { DOCUMENT } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { CssModifierService } from './services/css-modifier.service';
import { MatDialog } from '@angular/material/dialog';
import { StandardBestaetigenModal } from './components/modals/modal-standard-bestaetigung';
import { GlobalsService } from './_services/globals.service';
import {EventEmitterService} from './services/event-emitter.service';

export let browserRefresh = false;

declare let $:any;
declare let HelperFunctions: any;


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
   // Listener
   private eventEmitterSubscription: Subscription;
   private browserRefreshListener :Subscription;
   private currentUserListener: Subscription;
  //  private displayVideoConferenceListener: Subscription;
  //  private getCurrentSettingsFromDbListener: Subscription;

  // Vars
  public title = 'mobile-client';
  public currentUser: any = null;
  public defaultPage ='/mobile-client/login';
  public allow_video_conference_here  = true;
  // public cssUrl: string;
  // public displayVideoConference: boolean = true;
  @ViewChild('wrapper',{static: false}) wrapper: ElementRef;
  @ViewChild('contentWrapper',{static: false}) contentWrapper: ElementRef;
  @ViewChild('outletWrapper',{static: false}) outletWrapper: ElementRef;

  constructor(
    private router: Router,
    private dex: DataExchangeService,
    private http: HttpClient,
    public _socketService : SocketService,
    private logger: LoggerService,
    public sambaService: SambaService,
    public dialog: MatDialog,
    public sanitizer: DomSanitizer,
    @Inject(DOCUMENT) private document: Document,
    private cssModifier: CssModifierService,
    private globals: GlobalsService,
    private eventEmitterService: EventEmitterService,
    private authenticationService: AuthenticationService
    
  )
  {
    this.initBrowserRefreshListener();
    this.dex.router = router;
    this.dex.initListener();
    this.authenticationService.setDex(this.dex);
    this.authenticationService.initListener();


    // this.authenticationService.initListener();
    // this.sambaService.setDex(this.dex);
    this.sambaService.router = router;
    this.sambaService.initListener();


    this.initListener();
    this.initReadSettingsFromDbListener();
    this.initEventEmitterListener();

  }

  ngOnInit(): void
  {
    this.dex.checkAuthUser();
    this.cssModifier.loadStyle();
    this.router.navigate([this.defaultPage]);
  }

  ngOnDestroy(): void
  {
    this.eventEmitterSubscription.unsubscribe();
    this.currentUserListener != undefined ? this.currentUserListener.unsubscribe() : null;
  }


  initListener()
  {
    // Event Emitter
    this.eventEmitterSubscription = this.eventEmitterService.eventEmitter.subscribe(msg => {
      try
      {
        if(msg.hasOwnProperty("cmd"))
        {
          const cmd = msg.cmd;

          if(cmd === e_event_emitter_enum.setDefaultPage)
          {
            this.defaultPage = msg.data.defaultPage;
          }
        }
      }catch(e){this.logger.error(e);}
    });

    // CurrentUser Subscriber
    this.currentUserListener = this.authenticationService.currentUser.subscribe(userData => {
      try
      {
        this.currentUser = userData;
      }catch(e){this.logger.error(e);}
    });

    // this.displayVideoConferenceListener = this.sambaService.currentDisplayVideoConference.subscribe((displayVideoConference: boolean) => {
    //   try
    //   {
    //     console.log("[app.component] displayVideoConference ",displayVideoConference)
    //     this.displayVideoConference = displayVideoConference;
    //   }catch(e){this.logger.error(e);}
    // });
  }

  initEventEmitterListener()
  {
      // Event Emitter
      this.eventEmitterSubscription = this.eventEmitterService.eventEmitter.subscribe(msg => {
        try
        {
          if(msg.hasOwnProperty("cmd"))
          {
              const cmd = msg.cmd;

              if(cmd === e_event_emitter_enum.displaySambaContainer)
              {
                if(msg.data.hasOwnProperty("displayMainSamba"))
                {
                    setTimeout(() => {
                      msg.data.displayMainSamba === true ? this.allow_video_conference_here = true : null;
                      msg.data.displayMainSamba === false ? this.allow_video_conference_here = false : null;
                      // this.sambaService.starteVideoStream(this.dex.selectedVersammlung);

                    }, 0);
                }
              }
              if(cmd === e_event_emitter_enum.newVersionDetected)
              {
                // console.log("@@@@@@@@@@@ settings vorhanden , old Version: ",msg.data.old_version, " new Version: ",msg.data.new_version);
                this.openVersionChangedModal();
              }
          }
        }catch(e){this.logger.error(e);}
    });
  }

  public initReadSettingsFromDbListener()
  {

    // this.getCurrentSettingsFromDbListener = this.dex.currentSettingsFromDb.subscribe(settings => {
    //   if(Object.keys(settings).length > 0)
    //   {
    //     // this.settingsFromDb = settings;
    //     // this.logger.info(settings, "settings !!!!");
    //     // console.log("!!!!!!!! getSettingsFromDbListener 2  !!!! ",settings);
    //     if(settings.filter(s=>{return s.key == "use_video_conference"}).length > 0 )
    //     {
    //       this.allow_video_conference_here = HelperFunctions.Misc.castBoolean( settings.filter(s=>{return s.key == "use_video_conference"})[0]['value'] );
    //     }

    // }})
  }

  initBrowserRefreshListener()
  {
    this.browserRefreshListener = this.router.events.subscribe((event) => {
        if (event instanceof NavigationStart) {
          browserRefresh  = !this.router.navigated;
        }
    });
  }

  enableContentScroll()
  {
    let enable_content_scroll  = true;
    try
    {
      if(this.router.url.includes("/login"))
      {
        enable_content_scroll = false;
      }
    }
    catch (error)
    {
      console.log(error)
    }
    finally
    {
      return enable_content_scroll;
    }
  }



  openVersionChangedModal()
  {
     // open Modal StandardBestaetigenModal
     const modalRef = this.dialog.open(StandardBestaetigenModal, {
      width: "85vw",
      ariaLabelledBy: 'modal-basic-title',

    });
    modalRef.componentInstance.afterViewinit.subscribe((modal : StandardBestaetigenModal) => {
      modal.content.nativeElement.innerHTML =
      `Bitte laden Sie die Webseite neu.`;
      modal.okBtn.nativeElement.innerHTML = `Reload  <i class="pl-2 fas fa-caret-right"></i>`;
      modal.okBtn.nativeElement.classList.add(`ml-auto`);
      // modal.closeBtn.nativeElement.style.display = 'none';
      // modal.zusatzBtn.nativeElement.style.display = 'inherit';
    });
    modalRef.disableClose = true;

    modalRef.componentInstance.onInit.subscribe((modal: StandardBestaetigenModal) => {
      // modal.data = {selectedVersammlung: this.selectedVersammlung};
      modal.header = `Vulcavo wurde auf Version ${this.dex.dbsettings.filter(s=>s.key === "version").shift().value} geupdated!`;
      modal.cancel_function = null;
    });

    modalRef.componentInstance.afterOkpressed.subscribe((data: any) => {
      const url = new URL(window.location.href);
      url.searchParams.set('reloadTime', Date.now().toString());
      window.location.href = url.toString();
      modalRef.close();
      // modalRef = null;
    })

    modalRef.componentInstance.afterClosepressed.subscribe((data: any) => {
      // modalRef = null;
    })

    modalRef.componentInstance.ok_btn = function(text, router){
    };
    // modalRef.componentInstance.cancel_function = null;
  }
}
