<!-- neue Optik -->

<div class="mt-4">
    <app-alert></app-alert>
</div>
<div class="justify-content-center animated--grow-in justify-content-center row">
  <div class="align-self-center" [ngClass]="{'col-12 col-xs-12 col-md-10 col-lg-6 col-xl-4': sambaService?.displayVideoConferenceSrc.value === false, 'col-12': sambaService?.displayVideoConferenceSrc.value === true}">
<div *ngIf="versammlungFertigAbgestimmt===false" class="animated--grow-in no-user-select">
<div class="pl-3 pl-lg-0 pr-lg-0">
    <div class="row d-flex justify-content-center mt-4">
        <div class="col col-12 col-xs-12 col-md-12 col-lg-12 col-xl-12">
            <h1 style="margin-bottom: 0px;">Abstimmung</h1>
        </div>
    </div>
    <div class="row d-flex justify-content-center">
        <div class="col col-12 col-xs-12 col-md-12 col-lg-12 col-xl-12">

            <div class="d-flex justify-content-start">
                <div class="bd-highlight">
                    <p class="mb-0">von:</p>
                </div>
                <div class="pl-2 bd-highlight align-self-center">
                    <p class="mb-0"><strong>{{currentUser?.vorname}} {{currentUser?.nachname}}</strong></p>
                </div>
            </div>
        </div>
    </div>
    <div class="row d-flex justify-content-center align-items-stretch">
        <div class="col col-12 col-xs-12 col-md-12 col-lg-12 col-xl-12">
            <div class="d-flex justify-content-start">
                <div class="bd-highlight">
                    <span class="material-icons">
                        apartment
                    </span>
                </div>
                <div class="pl-2 bd-highlight align-self-center">
                    <p class="mb-0"><strong>{{selectedVersammlung?.name}}</strong></p>
                </div>
            </div>
        </div>
    </div>

    <ng-container>
        <div class="row d-flex justify-content-center mt-2 no-user-select" *ngIf="currentUser?.vertreter_vorname != ''">
            <div class="col col-12 col-xs-12 col-md-12 col-lg-12 col-xl-12">
                <h2 class="h4 thick mb-0">Abstimmung mit Vollmacht </h2>
            </div>
        </div>

        <div class="row d-flex justify-content-center mt-0 no-user-select" *ngIf="currentUser?.vertreter_vorname != ''">
            <div class="col col-12 col-xs-12 col-md-12 col-lg-12 col-xl-12">
                <ul class="list-inline mb-0">
                    <li class="list-inline-item">
                        <p class="mb-0">Vertreter:</p>
                    </li>
                    <li class="list-inline-item">
                        <p class="mb-0"><strong>{{currentUser?.vertreter_vorname}} {{currentUser?.vertreter_nachname}}</strong></p>
                    </li>
                </ul>
            </div>
        </div>

        <div class="row d-flex justify-content-center">
            <div class="col col-12 col-xs-12 col-md-12 col-lg-12 col-xl-12">
                <ul class="list-inline mb-0">
                    <li class="list-inline-item">
                        <p class="mb-0">Gemeinschaftstyp(en): </p>
                    </li>
                    <li class="list-inline-item">
                        <p class="mb-0"><strong>{{gemeinschaftstypenIdString? gemeinschaftstypenIdString: ''}} </strong></p>
                    </li>
                </ul>
            </div>
        </div>
    </ng-container>

    <ng-container>
        <div class="row d-flex justify-content-center mt-3">
            <div class="col col-12 col-xs-12 col-md-12 col-lg-12 col-xl-12 ml-3 ml-lg-0" [ngClass]="{'pb-4': (this.einheiten?.length === 0 && this.vertreterdaten?.vertretereinheiten?.length === 0)}">
                <mat-accordion>
                    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header class="pl-1 text-nowrap no-user-select">
                            <mat-panel-title>
                                Informationen
                            </mat-panel-title>
                            <mat-panel-description class="">
                                <small>{{panelOpenState ? 'einklappen' : 'ausklappen'}}</small>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <div class="row">
                            <div class="col col-12 pl-1">
                                <p class="no-padding-bottom no-margin-bottom"><strong>Beschlussgegenstand:</strong></p>
                                <p class="pre-wrap">{{selectedTagesordnungspunkt?.beschlussgegenstand}}</p>
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col col-12 pl-1 pr-1">
                                <p class="no-padding-bottom no-margin-bottom"><strong>Erläuterung:</strong></p>
                                <p class="pre-wrap">{{selectedTagesordnungspunkt?.erlaeuterung}}</p>
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col col-12 pl-1 pr-1">
                                <p class="no-padding-bottom no-margin-bottom"><strong>Beschlussvorschlag:</strong></p>
                                <p class="pre-wrap">{{selectedTagesordnungspunkt?.beschlussvorschlag}}</p>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>
    </ng-container>


    <!-- Begin Inhalt wenn Abzustimmende Einheiten vorhanden -->
    <ng-container *ngIf="this.einheiten?.length > 0 || this.vertreterdaten?.vertretereinheiten?.length > 0">
        <ng-container>
            <div class="row d-flex justify-content-center mt-3 no-user-select">
                <div class="col col-12 col-xs-12 col-md-12 col-lg-12 col-xl-12">
                    <ul class="list-inline mb-0">
                        <li class="list-inline-item">
                            <p class="mb-0">Qualifizierung: </p>
                        </li>
                        <li class="list-inline-item">
                            <p class="mb-0"><strong>{{this.dex.top_helper.getStimmgeberNamenByTopId(selectedTagesordnungspunkt , true, false) }} </strong></p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row d-flex justify-content-center no-user-select">
                <div class="col col-12 col-xs-12 col-md-12 col-lg-12 col-xl-12">
                    <ul class="list-inline mb-0">
                        <li class="list-inline-item">
                            <p class="mb-0">{{selectedTagesordnungspunkt?.qualifizierungstyp?.id == 2? "Berücksichtigung 1. Qualifizierung:" : "Berücksichtigung:"}} </p>
                        </li>
                        <li class="list-inline-item">
                            <p class="mb-0"><strong>{{this.dex.top_helper.getBeruecksichtigung(selectedTagesordnungspunkt?.qualifizierungstyp , "s1") }} </strong></p>
                        </li>
                    </ul>
                </div>
            </div>
            <ng-container *ngIf="selectedTagesordnungspunkt?.qualifizierungstyp?.id == 2">
                <div class="row d-flex justify-content-center no-user-select">
                    <div class="col col-12 col-xs-12 col-md-12 col-lg-12 col-xl-12">
                        <ul class="list-inline mb-0">
                            <li class="list-inline-item">
                                <p class="mb-0">Berücksichtigung 2. Qualifizierung:</p>
                            </li>
                            <li class="list-inline-item">
                                <p class="mb-0"><strong>{{this.dex.top_helper.getBeruecksichtigung(selectedTagesordnungspunkt?.qualifizierungstyp , "s2") }} </strong></p>
                            </li>
                        </ul>
                    </div>
                </div>
            </ng-container>

            <div class="row d-flex justify-content-center mt-2 no-user-select">
                <div class="col col-12 col-xs-12 col-md-12 col-lg-12 col-xl-12">
                    <p class="mb-0 text-bitte-abstimmen" [innerHTML]="anweisungstext"><strong>
                        Bitte wählen Sie die Einheiten aus, für die Sie abstimmen möchten.
                    </strong></p>
                </div>
            </div>

            <div class="row d-flex justify-content-center mt-2 no-user-select">
                <div class="col col-12 col-xs-12 col-md-12 col-lg-12 col-xl-12">
                    <p class="mb-0 text-legende" [innerHTML]="anweisungstext2">
<!--                        Nachdem Sie für die ausgewählten Einheiten abgestimmt haben, können Sie für die verbleibenden Einheiten separat abstimmen.-->
                    </p>
                </div>
            </div>

             <!-- Button alle abwhälen / anwhälen -->
             <div class="row d-flex justify-content-center no-user-select mt-3 mb-0">
                <div class="col-xs-12 col-md-12 col-lg-12 col-xl-12">
                    <button type="button" class="btn btn-selection" (click)="selectDeselect('toggle')">
                        Alle Einheiten {{einheitenSelectiert == false ? 'auswählen' : 'abwählen'}}
                        <i class="pl-2 fa-check btn-icon" [ngClass]="{'fas fa-check': einheitenSelectiert == false , 'far fa-window-close': einheitenSelectiert == true  }"></i>
                    </button>
                </div>
            </div>

            <div class="row d-flex justify-content-center no-user-select mt-2">
                <div class="col col-12 col-xs-12 col-md-12 col-lg-12 col-xl-12">
                    <ul class="list-inline mb-0">
                        <li class="list-inline-item">
                            <p class="mb-0">Abstimmen für {{currentUser?.vorname}} {{currentUser?.nachname}}:</p>
                        </li>
                    </ul>
                </div>
            </div>
        </ng-container>

        <ng-container>
            <ng-container *ngFor="let einheit of einheiten; index as i; first as isFirst">
                <div class="row d-flex justify-content-center" [ngClass]="{'mt-2': !isFirst}">
                    <div class="col col-12 col-xs-12 col-md-12 col-lg-12 col-xl-12">
                        <mat-card id="voteMatCard" class="example-card einheit-btn no-user-select" (click)="selectEinheit(einheit,false)"
                                  [ngClass]="{'yesVote': einheit.abstimmungsText == abstimmungsTexteEnum.Ja, 'noVote': einheit.abstimmungsText == abstimmungsTexteEnum.Nein, 'abstentionVote': einheit.abstimmungsText == abstimmungsTexteEnum.Enthaltung}">
                            <mat-card-header>
                            </mat-card-header>
                            <mat-card-content [ngClass]="{'whiteColor': einheit.abstimmungsText == abstimmungsTexteEnum.Ja || einheit.abstimmungsText == abstimmungsTexteEnum.Nein || einheit.abstimmungsText == abstimmungsTexteEnum.Enthaltung}">
                                <div class="row justify-content-between">
                                    <div class="col col-6 col-sm-auto pl-1 pr-1">
                                        {{einheit.name}}
                                    </div>
                                    <div class="col col-6 col-sm-auto">
                                        <div class="row">
                                            <div class="col-auto pr-1 pl-0">
                                                <div class="abgestimmt-text2"><small class="text-muted" [ngClass]="{'whiteColor': einheit.abstimmungsText == abstimmungsTexteEnum.Ja || einheit.abstimmungsText == abstimmungsTexteEnum.Nein || einheit.abstimmungsText == abstimmungsTexteEnum.Enthaltung}">Abstimmung:</small></div>
                                            </div>
                                            <div class="col col-xs-auto col-sm-auto col-md-auto align-self-start pl-1 pr-1">
                                                <div class="abgestimmt-value pl-0" [ngClass]="{'ja': einheit.abstimmungsText == abstimmungsTexteEnum.Ja, 'nein': einheit.abstimmungsText == abstimmungsTexteEnum.Nein, 'Enhaltung': einheit.abstimmungsText == abstimmungsTexteEnum.Enthaltung}">{{einheit.abstimmungsText[0].toUpperCase() + einheit.abstimmungsText.substring(1)}}</div>
                                            </div>
                                            <div class="col col-xs-auto col-sm-auto col-md-auto col-lg-auto pl-0 pr-1">
<!--                                       VUL-45         <mat-checkbox *ngIf="einheit.abstimmungsText === abstimmungsTexteEnum.keineAbstimmung" (ngModelChange)="selectEinheit(einheit, false)" class="right example-margin pl-2" [disabled]="einheit.disable_selection === true" [(ngModel)]="einheit.checked">-->
                                                <mat-checkbox [ngClass]="{'whiteColor': einheit.abstimmungsText == abstimmungsTexteEnum.Ja || einheit.abstimmungsText == abstimmungsTexteEnum.Nein || einheit.abstimmungsText == abstimmungsTexteEnum.Enthaltung}" (ngModelChange)="selectEinheit(einheit, false)" class="right example-margin pl-2" [disabled]="einheit.disable_selection === true" [(ngModel)]="einheit.checked">
                                                </mat-checkbox>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="vertreterdaten.vertreter?.length">
                <div class="row d-flex justify-content-center mt-2">
                    <div class="col col-12 col-xs-12 col-md-12 col-lg-12 col-xl-12">
                        <ul class="list-inline mb-0">
                            <li class="list-inline-item">
                                <p class="mb-0">Abstimmung als Vertreter für </p>
                            </li>
                        </ul>
                    </div>
                </div>
                <ng-container *ngFor="let vertreter of vertreterdaten.vertreter; index as ii; first as isFirst2">
                    <div class="row d-flex justify-content-center mt-2" [ngClass]="{'mt-2': !isFirst2}">
                        <div class="col col-12 col-xs-12 col-md-12 col-lg-12 col-xl-12">
                            {{vertreter.vorname}} {{vertreter.nachname}}
                            <mat-card id="voteMatCardVertreter" class="example-card einheit-btn no-user-select">
                                <mat-card-header>
                                </mat-card-header>
                                <mat-card-content>
                                    <ng-container *ngFor="let einheit of vertreter.einheiten; index as iii; first as isFirst3">
                                        <div class="row justify-content-between" [ngClass]="{'yesVote': einheit.abstimmungsText == abstimmungsTexteEnum.Ja, 'noVote': einheit.abstimmungsText == abstimmungsTexteEnum.Nein, 'abstentionVote': einheit.abstimmungsText == abstimmungsTexteEnum.Enthaltung}" (click)="selectEinheit(einheit, true)">
                                            <div class="col col-6 col-sm-auto pl-1 pr-1 pt-1" [ngClass]="{'whiteColor': einheit.abstimmungsText == abstimmungsTexteEnum.Ja || einheit.abstimmungsText == abstimmungsTexteEnum.Nein || einheit.abstimmungsText == abstimmungsTexteEnum.Enthaltung}">
                                                {{einheit.name}}
                                            </div>
                                            <div class="col col-6 col-sm-auto">
                                                <div class="row">
                                                    <div class="col-auto pr-1 pl-1 pt-1">
                                                        <div class="abgestimmt-text" [ngClass]="{'whiteColor': einheit.abstimmungsText == abstimmungsTexteEnum.Ja || einheit.abstimmungsText == abstimmungsTexteEnum.Nein || einheit.abstimmungsText == abstimmungsTexteEnum.Enthaltung}">Abstimmung:</div>
                                                    </div>
                                                    <div class="col col-xs-auto col-sm-auto col-md-auto align-self-start pl-1 pr-1 pt-1">
                                                        <div class="abgestimmt-value pl-0" [ngClass]="{'whiteColor': einheit.abstimmungsText == abstimmungsTexteEnum.Ja || einheit.abstimmungsText == abstimmungsTexteEnum.Nein || einheit.abstimmungsText == abstimmungsTexteEnum.Enthaltung, 'ja': einheit.abstimmungsText == abstimmungsTexteEnum.Ja, 'nein': einheit.abstimmungsText == abstimmungsTexteEnum.Nein, 'Enhaltung': einheit.abstimmungsText == abstimmungsTexteEnum.Enthaltung}">{{einheit.abstimmungsText[0].toUpperCase() + einheit.abstimmungsText.substring(1)}}</div>
                                                    </div>
                                                    <div class="col col-xs-auto col-sm-auto col-md-auto col-lg-auto pl-0 pr-1 pt-1">
<!--                                                    VUL-45    <mat-checkbox *ngIf="einheit.abstimmungsText === abstimmungsTexteEnum.keineAbstimmung" class="right example-margin pl-2" (ngModelChange)="selectEinheit(einheit, true)" [disabled]="einheit.disable_selection === true" [(ngModel)]="einheit.checked">-->
                                                        <mat-checkbox [ngClass]="{'whiteColor': einheit.abstimmungsText == abstimmungsTexteEnum.Ja || einheit.abstimmungsText == abstimmungsTexteEnum.Nein || einheit.abstimmungsText == abstimmungsTexteEnum.Enthaltung}" class="right example-margin pl-2" (ngModelChange)="selectEinheit(einheit, true)" [disabled]="einheit.disable_selection === true" [(ngModel)]="einheit.checked">
                                                        </mat-checkbox>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </mat-card-content>
                            </mat-card>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
        <ng-container>
            <div [ngClass]="{'submitButtons': true}">

<!--          VUL-45      <ng-container *ngIf="this.showAbgestimmt === false">-->
                <ng-container >
                    <div class="row d-flex justify-content-center mt-3">
                        <div class="col col-12 col-xs-12 col-md-12 col-lg-12 col-xl-12">
                            <button type="button" mat-raised-button [disabled]="einheitenSelectiert === false" class="btn btn-abstimmen-ja" (click)="abstimmen(abstimmungsTexteEnum.ja)">
                                <i class="fas fa-check btn-icon"></i>&nbsp;
                                Ja
                            </button>
                        </div>
                    </div>
                    <div class="row d-flex justify-content-center mt-1">
                        <div class="col col-12 col-xs-12 col-md-12 col-lg-12 col-xl-12">
                            <button type="button" mat-raised-button [disabled]="einheitenSelectiert === false" class="btn btn-abstimmen-nein" (click)="abstimmen(abstimmungsTexteEnum.nein)">
                                <i class="fas fa-times btn-icon"></i>&nbsp;
                                Nein
                            </button>
                        </div>
                    </div>
                    <div class="row d-flex justify-content-center mt-1 pb-4">
                        <div class="col col-12 col-xs-12 col-md-12 col-lg-12 col-xl-12">
                            <button type="button" mat-raised-button [disabled]="einheitenSelectiert === false" class="btn btn-abstimmen-enthaltung" (click)="abstimmen(abstimmungsTexteEnum.enthaltung)">
                                <i class="fas fa-square btn-icon"></i>&nbsp;
                                Enthaltung
                            </button>
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-container>
      <ng-container>
        <div class="hinweis text-legende">Hinweis: Wenn Sie während der Abstimmphase nicht abstimmen, wird Ihre Stimme automatisch als "Enthaltung" gewertet.</div>
      </ng-container>
    </ng-container>
    <!-- Ende Inhalt wenn Abzustimmende Einheiten vorhanden -->
</div>

<!-- <div *ngIf="versammlungFertigAbgestimmt === true" class="mt-5">
    <div class="row mt-5"></div>
    <div class="row mt-5"></div>
    <div class="row d-flex justify-content-center mt-5">
        <div class="col col-12 col-xs-12 col-md-12 col-lg-12 col-xl-12">
            <h1 class="h1" style="margin-bottom: 0px;">Vielen Dank für Ihre Teilnahme.</h1>
        </div>
    </div>

    <div class="row d-flex justify-content-center mt-4">
        <div class="col col-12 col-xs-12 col-md-12 col-lg-12 col-xl-12">
            <p class="mb-0">
                Die Versammlung ist nun beendet. Wir freuen uns darauf Sie bei der nächsten Versammlung wieder bei uns begrüßen zu dürfen.
            </p>
        </div>
    </div>
</div> -->

</div>
  </div></div>
