/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-prototype-builtins */
import { AfterContentInit, AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoggerService } from 'src/app/services/logger.service';
import { SambaService } from 'src/app/services/samba.service';
import { e_event_emitter_enum } from 'src/app/_models/enum';
import { DataExchangeService } from 'src/app/_services/data-exchange.service';
import { SocketService } from 'src/app/_services/socket.service';
import {EventEmitterService} from '../../services/event-emitter.service';
import {AuthenticationService} from '../../_services/authentication.service';

@Component({
  selector: 'app-samba',
  templateUrl: './samba.component.html',
  styleUrls: ['./samba.component.scss']
})
export class SambaComponent implements OnInit, OnDestroy, AfterViewInit, AfterContentInit {
  // Listener
  private currentUserListener: Subscription;
  private eventEmitterSubscription: Subscription;
  private getSelectedVersammlungFromDexListener: Subscription;
  private displayVideoConferenceListener: Subscription;

  // VARS
  @ViewChild('sambaContainer',{static: false}) sambaContainer: ElementRef;
  @ViewChild('sambaContainer2',{static: false}) sambaContainer2: ElementRef;
  @ViewChild('sambaFrame',{static: false}) sambaFrame: ElementRef;
  public currentUser: any = null;
  public buttons: any = {logout: false};
  public selectedVersammlung: any = { text: ''};
  public personalSessionLink : any = "";
  public displayVideoConference = false;
  
  constructor
  (
    private dex: DataExchangeService,
    private _socketService: SocketService,
    private authenticationService: AuthenticationService,
    private logger: LoggerService,
    public sambaService: SambaService,
    private eventEmitterService: EventEmitterService
  ) 
  { }
 
  ngOnInit(): void 
  {
    this.initListenerFirst();
  }
  
  onSocketInit() {}

  ngAfterViewInit(): void 
  {
    if(this.currentUser && this.currentUser != null)
    {
      if(this.currentUser.hasOwnProperty("personalSessionLink"))
      {
        this.sambaFrame.nativeElement.src = this.currentUser.personalSessionLink;
      }
    }
  }

  ngAfterContentInit(): void {}

  ngOnDestroy(): void 
  {
    this.currentUserListener != undefined ? this.currentUserListener.unsubscribe() : null;
    this.eventEmitterSubscription != undefined ? this.eventEmitterSubscription.unsubscribe() : null;
    this.getSelectedVersammlungFromDexListener != undefined ? this.getSelectedVersammlungFromDexListener.unsubscribe() : null;
    this.displayVideoConferenceListener != undefined ? this.displayVideoConferenceListener.unsubscribe() : null;
  }

  initListenerFirst()
  {
    // Event Emitter
    this.eventEmitterSubscription = this.eventEmitterService.eventEmitter.subscribe(msg => {
      try
      {
        if(msg.hasOwnProperty("cmd"))
        {
          const cmd = msg.cmd;
          if(cmd === e_event_emitter_enum.setCssStyle)
          {  
             this.sambaContainer2.nativeElement.setAttribute(`style`, `${msg.data.style}`);
          }
        }
      }catch(e){this.logger.error(e);}
    });

    this.currentUserListener = this.authenticationService.currentUser.subscribe((userData: any) => {
      try
      {
        this.currentUser = userData;
      }catch(e){this.logger.error(e);}
    });

    // getSelectedVersammlung
    this.getSelectedVersammlungFromDexListener = this.dex.currentSelectedVersammlung.subscribe( async (data : any )=>{
      try
      {
        if( (data=== null || data === '' || data ==='undefined' || Object.keys(data).length === 0)  && this.authenticationService.currentUserValue)
        {
          this.dex.querySelectedVersammlung("from Lockedscreen");
        }
        else
        {
          this.selectedVersammlung = data; 
          this.logger.info(data, "[samba.component] getSelectedVersammlungFromDexListener ");
        }
      }
      catch(e){this.logger.error(e);}
    });

    this.displayVideoConferenceListener = this.sambaService.currentDisplayVideoConference.subscribe((displayVideoConference: boolean) => {
      try
      {
        // console.log("[samba.component] displayVideoConference ",this.displayVideoConference)
        this.displayVideoConference = displayVideoConference;
      }catch(e){this.logger.error(e);}
    });
  }
  
  initListenerLater(){}


}
