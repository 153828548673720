/* eslint-disable prefer-spread */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-empty */
import { trigger, state, style, transition, animate } from '@angular/animations';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { LoggerService } from 'src/app/services/logger.service';
import { AnmeldungsStatus, e_command_enum, e_event_emitter_enum } from 'src/app/_models/enum';
import { Abstimmung, Einheit, Import_Gemeinschaftstyp, Tagesordnungspunkt, Tagesordnungspunkt_Gob } from 'src/app/_models/interfaces';
import { Teilnehmer } from 'src/app/_models/teilnehmer';
import { DataExchangeService } from 'src/app/_services/data-exchange.service';
import { GlobalsService, use_abstimmung_greater } from 'src/app/_services/globals.service';
import { SocketService } from 'src/app/_services/socket.service';
import {EventEmitterService} from '../../services/event-emitter.service';
import {AlertService} from '../../_services/alert.service';
import {SambaService} from "../../services/samba.service";

declare let HelperFunctions: any;

export interface TeilnehmerData{
  abstimmungen: Array<Abstimmung>,
  abstimmungen_gob: Array<Abstimmung>,
  einheiten: Array<Einheit>
  gemeinschaftstypen: Array<Gemeinschaftstyp_enhanced>,
  handlingData: any,
  tagesordnungspunkte: Array<Tagesordnungspunkt>,
  tagesordnungspunkte_gob: Array<Tagesordnungspunkt_Gob>,
  teilnehmer: Array<Teilnehmer>,
  // teilnehmer_filtered?: Array<Teilnehmer_filtered>
}
export interface Gemeinschaftstyp_enhanced extends Import_Gemeinschaftstyp{
  mea?: number,
  einheiten_vorhanden?: boolean
}

export interface Tagesordnungspunkt_enhanced extends Tagesordnungspunkt
{
  unterpunkte? : Array<Tagesordnungspunkt_enhanced>,
  rangText?: string,
  tagesordnungspunkteIdString? : string,
  ist_gob_von?: number,
  gemeinschaftstypen_namen?: string
}
export interface Einheit_enhanced extends Einheit{
  abstimmungsText?: string
}
export interface Teilnehmer_reference{
  hatEinheiten : boolean;
  abstimmungen : {
    gesammelt: Array<Abstimmung>,
    summary: {
      abgestimmt: number,
      abstimmung_notwendig: number
    }
  };
  tagesordnungspunkt_id : number;
  teilnehmer_id : number
}

export interface TeilnehmerTableDataRow{
  teilnehmer_id: number,
  name: string,
  abgestimmt: boolean,
  hatEinheiten: boolean,
  status: string,
  anwesend: number,
  vertreter: string,
  vertreter_vorname: string,
  vertreter_nachname: string,
  // abstimmungen: Array<Abstimmung>,
  vorname: string,
  nachname: string,
  versammlung_id: number
}



@Component({
  selector: 'app-erklaerung',
  templateUrl: './erklaerung.component.html',
  styleUrls: ['./erklaerung.component.scss'],
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        // height: '200px',
        display: "inherhit",
        left: "inherit",
        opacity: 1,
        // backgroundColor: 'yellow'
      })),
      state('closed', style({
        display: "fixed",
        left: "-1920px",
        opacity: 0.5,
        // backgroundColor: 'green'
      })),
      transition('open => closed', [
        animate('1s')
      ]),
      transition('closed => open', [
        animate('0.5s')
      ]),
      transition('* => open', [
        animate('0.5s')
      ]),
      transition(':enter', [
        style({ opacity: 0 }),
        animate('250ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate('250ms', style({ opacity: 0 }))
      ])
    ]),
    trigger('fade', [
      transition('void => active', [ // using status here for transition
        style({ opacity: 0 }),
        animate(2000, style({ opacity: 1 }))
      ]),
      transition('* => void', [
        animate(2000, style({ opacity: 0 }))
      ])
    ]),
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0
      })),
      transition('void <=> *', animate(1000)),
    ]),
  ]
})
export class ErklaerungComponent extends AnmeldungsStatus implements OnInit, OnDestroy, AfterViewInit {
  // LISTENER
  private eventEmitterSubscription: Subscription = null;
  private getSelectedVersammlungFromDexListener: Subscription = null;
  private getTeilnehmerListener: Subscription = null;
  private getSelectedTagesordnungspunktListener: Subscription = null;
  private updateInDbListener: Subscription = null;
  private getErklaerungComponentDataListener: Subscription = null;
  // VARS
  @ViewChild('expansionPanel', { static: true }) expansionPanel: MatExpansionPanel;
  @ViewChild('scroll_left', { static: true }) scroll_left_: ElementRef;
  @ViewChild('scroll_right', { static: true }) scroll_right_: ElementRef;
  public erklaerungsData : any = null;
  public routerLinkParameter: any = {};
  public isOpen = false;
  public tagesordnungspunkteIdString: string;
  private getTeilnehmerData : TeilnehmerData;
  public currentTagesordnungspunktState: any = {};
  public buttons: any = {logout: false};
  public qualifizierungsTypen_index = 0
  public selectedVersammlung: any = { text: ''};
  public selectedTagesordnungspunkt: Tagesordnungspunkt_enhanced = null;
  public tagesordnungspunkte: Array<Tagesordnungspunkt_enhanced> = [];
  private tagesordnungspunkte_gob: Array<Tagesordnungspunkt_Gob> = [];
  public abstimmungen : Array<Abstimmung> = [];
  public einheiten :  Array<Einheit_enhanced> = [];
  public teilnehmer : Array<Teilnehmer> = [];
  public teilnehmer_reference : Array<Teilnehmer_reference> = [];
  // public teilnehmer_datatables : Array<TeilnehmerTableDataRow> = [];
  public gemeinschaftstypen: Array<Gemeinschaftstyp_enhanced>;
  public gemeinschaftstypString : string;
  public deadReload : number;
  public versammlungLoaded  = false;
  public force_stay  = false;
  public erlaeuterung : any = {infoTextLeft: "", infoTextRight: "",erlaeuterung: "", protokollnotiz: "",beschlussgegenstand: "", beschlussvorschlag: ""};
  public texte : any = {
    bitte_geduld : ``,
    bitte_geduld1 : `Bitte haben Sie noch etwas Geduld. Die Versammlung:`,
    bitte_geduld2 : `Bitte haben Sie noch etwas Geduld. Die nächste Versammlung beginnt in kürze`,
    unten: ``,
    unten1: `beginnt in Kürze`
  }
  public disableScrollDown = false;
  public intervalObject: any;
  // von main.ts
  public panelOpenState: any = false;
  public vertreterdaten : any = {};
  public _use_abstimmung_greater = -2;

  constructor(
    public dex: DataExchangeService,
    private _socketService: SocketService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    public logger: LoggerService,
    private cdr: ChangeDetectorRef,
    public globals: GlobalsService,
    private eventEmitterService: EventEmitterService,
    public sambaService: SambaService,
  )
  {
    super();
    this._use_abstimmung_greater = use_abstimmung_greater;
    this.deadReload = 0;
    this.initEventEmitterListener();
    this.getRouterLinkParameter();

  }

  ngOnInit(): void
  {
    this.initListener();
    // this.loadDataFromSocket();

  }
  ngAfterViewInit(): void
  {
    this.dex.startRefreshTimer();
    setTimeout(() => {
      this.expansionPanel.open();
      this.cdr.markForCheck();
      this.cdr.detectChanges();
    }, 250);

  }

  ngOnDestroy(): void
  {
    this.eventEmitterSubscription != null ? this.eventEmitterSubscription.unsubscribe() : null;
    this.getSelectedVersammlungFromDexListener != null ? this.getSelectedVersammlungFromDexListener.unsubscribe() : null;
    this.getTeilnehmerListener != null ? this.getTeilnehmerListener.unsubscribe() : null;
    this.getSelectedTagesordnungspunktListener != null ? this.getSelectedTagesordnungspunktListener.unsubscribe() : null;
    this.updateInDbListener != null ? this.updateInDbListener.unsubscribe() : null;
    this.getErklaerungComponentDataListener != null ? this.getErklaerungComponentDataListener.unsubscribe() : null;
  }

  onSocketInit()
  {

  }

  initEventEmitterListener()
  {
     // Event Emitter
    this.eventEmitterSubscription != null ? this.eventEmitterSubscription.unsubscribe() : null;
     this.eventEmitterSubscription = this.eventEmitterService.eventEmitter.subscribe(msg => {
      try
      {
        if(msg.hasOwnProperty("cmd"))
        {
          const cmd = msg.cmd;
          if(cmd === e_event_emitter_enum.connect)
          {
            this.logger.info(msg, "Connect")
          }
          if(cmd === e_event_emitter_enum .disconnect)
          {
            this.logger.info(msg, "Disconnect")
          }
          if(cmd === e_event_emitter_enum.reloadComponentData)
          {
            this.logger.info(msg, "reload Data");
            this.loadDataFromSocketLater();
          }
          if(cmd === e_event_emitter_enum.setErklaerungsData)
          {
            this.erklaerungsData = msg;
            this.logger.info(this.erklaerungsData, "setErklaerungsData ");
          }
        }
      }catch(e){this.logger.error(e);}
    });
  }

  initListener()
  {
      // getSelectedTagesordnungspunktListener to detect if current Abstimmung of Tagesordnungspunkt is valid
    this.getSelectedTagesordnungspunktListener != null ? this.getSelectedTagesordnungspunktListener.unsubscribe() : null;
    this.getSelectedTagesordnungspunktListener = this.dex.currentSelectedTagesordnungsPunkt.subscribe( async (data : any )=>{
        try
        {
          if(HelperFunctions.Misc.isJson2(data) == 'string' && data.length > 0)
          {
              data = JSON.parse(data);
              if(data.response)
              {
                this.currentTagesordnungspunktState = data.response;
                this.logger.info(data.response, "getSelectedTagesordnungspunktListener code");
              }
          }
        }catch(e){this.logger.error(e);}
      });

      // GET Tagesordnungspunkt Daten
      // GET Teilnehmer Daten
      this.getTeilnehmerListener != null ? this.getTeilnehmerListener.unsubscribe() : null;
      this.getTeilnehmerListener = this.dex.currentSelectedTeinehmerData.subscribe( async (data : any )=>{
        try
        {
          if(HelperFunctions.Misc.isJson2(data) == 'string' && data.length > 0)
          {
              data = JSON.parse(data);
              this.logger.info(data, "TEILNEHMER DATA");
              if(data.response)
              {
                  this.getTeilnehmerHandler(data);
              }
          }
        }
        catch(e) { this.logger.error(e); }
      });

      // getSelectedVersammlung
      this.getSelectedVersammlungFromDexListener != null ? this.getSelectedVersammlungFromDexListener.unsubscribe() : null;
      this.getSelectedVersammlungFromDexListener = this.dex.currentSelectedVersammlung.subscribe( async (data : any )=>{
        try
        {
          if(data === null || data === '' || data ==='undefined' || Object.keys(data).length === 0)
          {
            if(this.deadReload < 3)
            {
              this.logger.info(data, "getSelectedVersammlung");
              const parameters = {cmd: 'getSelectedVersammlung', data:{}}
              this._socketService.querySelectedVersammlung(parameters);
              this.selectedVersammlung = {};
              this.deadReload++;
            }
          }
          else
          {
            if(this.versammlungLoaded === false)
            {
              this.versammlungLoaded = true;
              this.selectedVersammlung = data;
              this.loadDataFromSocketLater();
              this.logger.info(data, "[erklaerung.component] getSelectedVersammlungFromDexListener ");
            }
          }
        }
        catch(e){this.logger.error(e);}
      });

      // GET Teilnehmer Daten
      this.getTeilnehmerListener != null ? this.getTeilnehmerListener.unsubscribe() : null;
      this.getTeilnehmerListener = this._socketService.response_getTeilnehmer().subscribe( async (data : any )=>{
        try
        {
          if(HelperFunctions.Misc.isJson2(data) == 'string')
          {
              data = JSON.parse(data);
              if(data.response)
              {
                this.logger.info("this.getTeilnehmerListener");
                await this.getTeilnehmerHandler(data);
              }
          }
        } catch(e) { this.logger.error(e);}
      });

      // GET Tagesordnungspunkt Daten
      // Code -1 - kein tP | 0 TN hat keine Einheit | 1: valide, hat schon was abgestimmt | 2: TN hat noch nichts abgestimmt
      this.getSelectedTagesordnungspunktListener != null ? this.getSelectedTagesordnungspunktListener.unsubscribe() : null;
      this.getSelectedTagesordnungspunktListener = this.dex.currentSelectedTagesordnungsPunkt.subscribe( async (data : any )=>{
        try
        {
          this.logger.info(JSON.parse(JSON.stringify(data)), "[erklaerung.component] TAGESORDNUNGSPUNKT DATA ");
          if(data && data.code)
          {
            switch(data.code)
            {
              case -1:
                // Kein Tagesordnungspunkt durch das Dashboard angewählt
                // this.logger.info("[erklaerung.component] navigate back to Lockedscreen");
                // setTimeout(() => {}, 250);
              break;
              case 0:
                // Teilnehmer besitzt keine Einheiten
              break;
              case 1:
                // Valide, es liegen bereits Abstimmungen vor
                // this.gemeinschaftstypen = data.gemeinschaftstypen;
                // this.einheiten = data.einheiten;
                // this.abstimmungen = data.abstimmungen;
                // this.selectedTagesordnungspunkt = data.tagesordnungspunkt;
              break;
              case 2:
                // Teilnehmer hat noch keine Abstimmung getätigt
                // this.gemeinschaftstypen = data.gemeinschaftstypen;
                // this.einheiten = data.einheiten;
                // this.abstimmungen = data.abstimmungen;
                // this.selectedTagesordnungspunkt = data.tagesordnungspunkt;
              break;
              case 3:
              break;
            }

            // if((data.code === 1 || data.code === 2) && this.force_stay === false && data.hasOwnProperty("tagesordnungspunkt") && data.tagesordnungspunkt != undefined )
            if((data.code === 1 || data.code === 2) && data.hasOwnProperty("tagesordnungspunkt") && data.tagesordnungspunkt != undefined )
            {
              /*
              if(data.tagesordnungspunkt.abstimmung_erforderlich > 0 && data.tagesordnungspunkt.abstimmung_beendet === 0)
                {
                  // alert("navigate");
                  this.router.navigate(['mobile-client/abstimmung/abstimmung-main']);
                }
                if(data.tagesordnungspunkt.abstimmung_erforderlich == 0)
                {
                  this.router.navigate(['mobile-client/erklaerung']);
                }
                */
            }
          }
        }
        catch(e) { this.logger.error(e); }
      });


      // UpdateInDbListener
      this.updateInDbListener != null ? this.updateInDbListener.unsubscribe() : null;
      this.updateInDbListener = this._socketService.response_updateInDB().subscribe( async (data : any )=>{
        try
        {
          if(HelperFunctions.Misc.isJson2(data) == 'string')
          {
              data = JSON.parse(data);
              const response = data.response;
              this.logger.info(data, "response_updateInDB");
              if(data.response && data.submitted && data.submitted.hasOwnProperty("cmd"))
              {
                const cmd = data.submitted.cmd;
                if(cmd === e_command_enum.updateBeschlussvorschlag)
                {
                  if(data.response.hasOwnProperty("tagesordnungspunkt"))
                  {
                    this.selectedTagesordnungspunkt.beschlussvorschlag = data.response.tagesordnungspunkt[0].beschlussvorschlag;
                    this.displayErlaeuterung();
                  }
                }
                if(cmd === e_command_enum.updateBeschlussgegenstand)
                {
                  if(data.response.hasOwnProperty("tagesordnungspunkt"))
                  {
                    this.selectedTagesordnungspunkt.beschlussgegenstand = data.response.tagesordnungspunkt[0].beschlussgegenstand;
                    this.displayErlaeuterung();
                  }
                }
                if(cmd === e_command_enum.updateErlaeuterung)
                {
                  if(data.response.hasOwnProperty("tagesordnungspunkt"))
                  {
                    this.selectedTagesordnungspunkt.erlaeuterung = data.response.tagesordnungspunkt[0].erlaeuterung;
                    this.displayErlaeuterung();
                  }
                }
                if(cmd === e_command_enum.updateTagesordnungspunktEinstellungen)
                {
                  if(data.response.hasOwnProperty("tagesordnungspunkt"))
                  {
                    this.selectedTagesordnungspunkt.qualifizierungstyp = data.response.tagesordnungspunkt[0].qualifizierungstyp;
                    this.displayErlaeuterung();
                  }
                }
                if(cmd === e_command_enum.updateProtokollnotiz)
                {
                  if(data.response.hasOwnProperty("tagesordnungspunkt"))
                  {
                    this.selectedTagesordnungspunkt.protokollnotiz = data.response.tagesordnungspunkt[0].protokollnotiz;
                    this.displayErlaeuterung();
                  }
                }
                // if(cmd === e_command_enum.updateGeschaeftsordnungsbeschluss)
                // {
                // }
                // if(cmd === e_command_enum.updateAnwesenheitsstatus)
                // {

                // }
                // if(cmd === e_command_enum.teilnehmerSperren)
                // {

                // }
                // if(cmd === e_command_enum.abstimmungStarten)
                // {
                // }
                // if(cmd === e_command_enum.abstimmungBeenden)
                // {

                // }
                // if(cmd === e_command_enum.updateErgebnisVerkuendung)
                // {
                // }

              }
          }
        }
        catch(e) { this.logger.error(e);}
      });


      // getErklaerungComponentData
      this.getErklaerungComponentDataListener != null ? this.getErklaerungComponentDataListener.unsubscribe() : null;
      this.getErklaerungComponentDataListener = this._socketService.response_getErklaerungComponentData().subscribe( async (data : any )=>{
        try
        {
          if(HelperFunctions.Misc.isJson2(data) == 'string')
          {
            data = JSON.parse(data);
            const response = data.response;
            this.selectedTagesordnungspunkt = response.selectedTagesordnungspunkt[0];
            this.logger.info(data, "[response_getErklaerungComponentData]");
            // console.log("> [getErklaerungComponentDataListener] ",data);
          }
        } catch (error) { console.log(error)}
      });
  }

  // loadDataFromSocket()
  // {

  // }

  loadDataFromSocketLater()
  {
    this.logger.info("[erklaerung.component] loadDataFromSocketLater");
    if(this.deadReload< 3)
    {
      this.deadReload++;
      this.logger.info("[erklaerung.component] Erklärung call to load data");

      try
      {
        const parameters: any =
        {
          cmd: "getErklaerungComponentData",
          data: {
            versammlung_id: this.selectedVersammlung.versammlung_id,
            active: true
          }
        }
        if(this.erklaerungsData != null && this.erklaerungsData?.hasOwnProperty("tagesordnungspunkt_id"))
        {
          parameters.data.tagesordnungspunkt_id = this.erklaerungsData?.tagesordnungspunkt_id;
          parameters.data.ist_gob = this.erklaerungsData?.ist_gob;
        }
        else
        {
          if(this.selectedVersammlung?.navigateClient && HelperFunctions.Misc.isJson2(this.selectedVersammlung.navigateClient) == 'string')
          {
            const navigateClient = JSON.parse(this.selectedVersammlung?.navigateClient);
            // console.log("HIER !!!!!!!!!!!!!!!!!!!!",navigateClient)
            if(navigateClient?.data?.tagesordnungspunkt?.tagesordnungspunkt_id)
            {
              parameters.data.tagesordnungspunkt_id = navigateClient.data.tagesordnungspunkt.tagesordnungspunkt_id;
            }
            if(navigateClient?.data?.tagesordnungspunkt?.ist_gob)
            {
              parameters.data.ist_gob = navigateClient.data.tagesordnungspunkt.ist_gob;
            }
          }
        }
        this._socketService.getErklaerungComponentData(parameters);
      } catch (error) {        console.log(error)      }
    }
  }

  getRouterLinkParameter()
  {
    try
    {
      // this.activatedRoute.paramMap.subscribe(params => {
      //   if(params.get("data"))
      //   {
      //    this.routerLinkParameter = JSON.parse(params.get("data"));
      //    this.logger.info(this.routerLinkParameter);
      //    this.routerLinkParameter.force? this.force_stay = this.routerLinkParameter.force: false;
      //   }
      // })

      if(this.activatedRoute.snapshot.paramMap.get("data"))
      {
        this.routerLinkParameter = JSON.parse(this.activatedRoute.snapshot.paramMap.get("data"));
        // console.log("map data ",this.routerLinkParameter)
        this.getRouterLinkParameterHandler(this.routerLinkParameter);
      }
    }
    catch(e)    {      this.logger.error(e)    }
  }

  getRouterLinkParameterHandler(routerLinkParameter_ : any = {})
  {
    this.logger.info(routerLinkParameter_ , "routerLinkParameter_ ");
    this.routerLinkParameter.force? this.force_stay = this.routerLinkParameter.force: false;
    if(this.routerLinkParameter.hasOwnProperty("tagesordnungspunkt_id"))
    {
      this.erklaerungsData = this.routerLinkParameter
    }
  }

  async getTeilnehmerHandler(data)
  {
    // console.log("getTeilnehmerHandler ",data)

    const response = data.response;
    this.gemeinschaftstypen = response.gemeinschaftstypen;
    this.einheiten = response.einheiten;
    this.tagesordnungspunkte_gob = response.tagesordnungspunkte_gob;
    await this.convertTeilnehmerFromDbToDatatableFormat(response);
    this.displayErlaeuterung();
    if(this.tagesordnungspunkte.length < 1)
    {
        this.logger.info("> keine Abstimmung")
    }
    else
    {
      // await this.updateAbstimmungen();
      this.getAbstimmErgebnisse();
      // Tagesordnungspunkte über die nicht abgestimmt wird (Einstellung bei Tagesordnungspunkt)
      if(this.selectedTagesordnungspunkt.abstimmung_erforderlich != 1)
      {
          this.logger.info("Über diesen Tagesordnungspunkt wird nicht abgestimmt");
      }
    }
  }

  getAbstimmErgebnisse(text = "")
  {
    try
    {
      const parameters : any =
      {
        cmd: e_event_emitter_enum.getErgebnissComponentData,
        data: {
          versammlung_id : this.selectedVersammlung.versammlung_id,
          active: true,
          // force: true,
          notice : text
        }
      }
      // DEPRECATED force = um Tops mit Abstimmung.beendet > 0 zu bekommen
      parameters.data.tagesordnungspunkt_id = this.selectedTagesordnungspunkt.tagesordnungspunkt_id;
      parameters.data.ist_gob = this.selectedTagesordnungspunkt['ist_gob_von'] > 0 ? true: false;
      parameters.data.queryObjects = [ 'abstimmErgebnisse' ];
      this._socketService.getErgebnissComponentData(parameters);
    } catch (error) {
      this.logger.error(error)
    }
  }

  // Für die Teilnehmer Liste im Punkt Abstimmen (Nur Teilnehmer für diesen Tagesordnungspunkt)
  async convertTeilnehmerFromDbToDatatableFormat(response)
  {
    response.abstimmungen = HelperFunctions.Sorting.filterAbstimmungen(response.abstimmungen, this._use_abstimmung_greater);
    this.teilnehmer = response.teilnehmer;
    // this.einheiten = response.einheiten;
    this.tagesordnungspunkte = response.tagesordnungspunkte;
    this.abstimmungen = response.abstimmungen;

    // Build Unter and Oberpunkte on Tagesordnungspunkte
    const oberpunkte_ = this.tagesordnungspunkte.filter((tp:Tagesordnungspunkt_enhanced) => {return tp.ist_unterpunkt === 0});
    const unterpunkte_ = this.tagesordnungspunkte.filter((tp:Tagesordnungspunkt_enhanced) => { return tp.ist_unterpunkt > 0});
    // 1.2 msec
    for(let i = 0; i < oberpunkte_.length ; i++)
    {
      let matching_unterpunkte = unterpunkte_.filter(u => {return u.ist_unterpunkt === oberpunkte_[i].tagesordnungspunkt_id });
      if(matching_unterpunkte.length > 0)
      {
        matching_unterpunkte = matching_unterpunkte.sort( HelperFunctions.Misc.sortByRang );
        oberpunkte_[i].unterpunkte = matching_unterpunkte;
      }
    }
    // tagesordnungspunkte2 sind jetzt mit abstimmungen und unterpunkten versehen
    this.tagesordnungspunkte = JSON.parse(JSON.stringify(oberpunkte_));
    this.setRangText();
    console.log(">>@@@@@@@@@> ",this.tagesordnungspunkte)
    // 1.2 msec
    // 3 msec
    this.tagesordnungspunkte.sort( HelperFunctions.Misc.sortByRang );
    this.tagesordnungspunkte.sort(HelperFunctions.Misc.sortByUnterpunkte);
    // 3.4 msec

    // Find and set next Tagesordnungspunkt
    if(this.tagesordnungspunkte.filter(top=>{return top.active >= 1 && top.abstimmung_verkuendet_timestamp == ""}).length > 0)
    {
      this.selectedTagesordnungspunkt = this.tagesordnungspunkte.filter(top=>{return top.active >= 1 && top.abstimmung_verkuendet_timestamp == ""}).shift();
      this.tagesordnungspunkteIdString = this.selectedTagesordnungspunkt.rang_unique+"";
    }
    else
    {
      this.selectedTagesordnungspunkt = unterpunkte_.filter(up=>{return up.active >= 1 && up.abstimmung_verkuendet_timestamp == "" }).shift();
      const eltern_top = oberpunkte_.filter(op=> op.tagesordnungspunkt_id === this.selectedTagesordnungspunkt.ist_unterpunkt).shift();
      this.tagesordnungspunkteIdString = eltern_top.rang_unique+"."+this.selectedTagesordnungspunkt.rang_unique;
    }

     // Wenn selectedTagesordnungspunkt gefunden
     let was_gefunden = false;
     for(let t = 0 ; t < this.tagesordnungspunkte.length ; t++ )
     {
       const tp = this.tagesordnungspunkte[t];
       if(tp.abstimmung_verkuendet_timestamp == "" && tp.active >= 1 && !was_gefunden)
       {
         this.selectedTagesordnungspunkt = tp;
         // VUL-594
         this.tagesordnungspunkteIdString = this.selectedTagesordnungspunkt.rang_unique+"";
         was_gefunden = true;
         break;
       }
       else
       {
         if(tp.hasOwnProperty("unterpunkte") && tp.unterpunkte.length)
         {
           for(let u = 0 ; u < tp.unterpunkte.length ; u++)
           {
             const up = tp.unterpunkte[u];
             if(up.abstimmung_verkuendet_timestamp == "" && up.active >= 1 && !was_gefunden)
             {
               this.selectedTagesordnungspunkt = up;
               // VUL-594
               this.tagesordnungspunkteIdString = tp.rang_unique+"."+up.rang_unique;
               was_gefunden = true;
               break;
             }
           }
         }
       }
     }
    // Ende Find and set next Tagesordnungspunkt
    // this.teilnehmer_datatables = [];
    this.teilnehmer_reference = [];
    // Loop through all Teilnehmer
    for(let i = 0; i<this.teilnehmer.length; i++)
    {
      const t_id = this.teilnehmer[i].teilnehmer_id;
      const abstimmungen_des_teilnehmers = this.globals.getAbstimmungenByTeilnehmer(t_id, this.selectedTagesordnungspunkt.tagesordnungspunkt_id, this.abstimmungen, this.einheiten, this.selectedTagesordnungspunkt.gemeinschaftstypen, this._use_abstimmung_greater);
      const einheiten_des_teilnehmers = this.globals.getEinheitenByTeilnehmer(t_id, this.einheiten, this.selectedTagesordnungspunkt.gemeinschaftstypen);
      const _teilnehmer_reference: Teilnehmer_reference = {
        hatEinheiten: einheiten_des_teilnehmers.length > 0,
        abstimmungen: {
          gesammelt: abstimmungen_des_teilnehmers,
          summary: {
            abgestimmt: abstimmungen_des_teilnehmers.length,
            abstimmung_notwendig: einheiten_des_teilnehmers.length
          }
        },
        teilnehmer_id : t_id,
        tagesordnungspunkt_id: this.selectedTagesordnungspunkt.tagesordnungspunkt_id
      };
      this.teilnehmer_reference.push(_teilnehmer_reference);

    }

    this.getTeilnehmerData = JSON.parse(JSON.stringify(response));
    this.gemeinschaftstypString = response.gemeinschaftstypen.filter(gm => {return gm.id === this.selectedTagesordnungspunkt.gemeinschaftstypen })[0].name;

  }


  private setRangText():void
  {
    for(let i = 0; i < this.tagesordnungspunkte.length ; i++)
    {
      const tp = this.tagesordnungspunkte[i];
      // VUL-594
      tp.rangText = tp.rang_unique+"";
      if(tp.hasOwnProperty("unterpunkte") && tp.unterpunkte.length > 0)
      {
        for (let u = 0 ; u < tp.unterpunkte.length ; u++)
        {
          const upunkt = tp.unterpunkte[u];
          // VUL-594
          upunkt.rangText = tp.rang_unique+"."+upunkt.rang_unique;
        }
      }
    }
  }

  displayErlaeuterung()
  {
    this.erlaeuterung.infoTextLeft= `
      <strong>Beschlussgegenstand</strong>
      <p>
      ${this.selectedTagesordnungspunkt.beschlussgegenstand}
      </p>
    `;
    this.erlaeuterung.beschlussgegenstand = this.selectedTagesordnungspunkt.beschlussgegenstand;
    this.erlaeuterung.erlaeuterung = this.selectedTagesordnungspunkt.erlaeuterung;
    this.erlaeuterung.beschlussvorschlag = this.selectedTagesordnungspunkt.beschlussvorschlag;
    this.erlaeuterung.protokollnotiz = this.selectedTagesordnungspunkt.protokollnotiz;
    this.erlaeuterung.infoTextRight = ``;
    if(this.erlaeuterung.erlaeuterung.length > 1)
    {
      this.erlaeuterung.infoTextRight += `
      <strong>Erläuterung</strong><p>
      ${this.selectedTagesordnungspunkt.erlaeuterung}</p>`;
    }
    if(this.erlaeuterung.beschlussvorschlag.length > 1)
    {
      this.erlaeuterung.infoTextRight += `
      <strong>Beschlussvorschlag</strong><p>
      ${this.selectedTagesordnungspunkt.beschlussvorschlag}</p>`;
    }
    if(this.erlaeuterung.protokollnotiz.length > 1)
    {
      // Wird auf der Bühne nicht angezeigt
      // this.erlaeuterung.infoTextRight += `
      // <strong>Protokollnotiz</strong><p>
      // ${this.selectedTagesordnungspunkt.protokollnotiz}</p>`;
    }
  }

}
