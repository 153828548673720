import { Component, Input, OnInit, ViewChild, AfterViewInit, OnDestroy, ElementRef, EventEmitter, Output, AfterContentInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoggerService } from '../../services/logger.service';
import { SocketService } from 'src/app/_services/socket.service';
import { DataExchangeService } from 'src/app/_services/data-exchange.service';
import {AuthenticationService} from '../../_services/authentication.service';
import {AlertService} from '../../_services/alert.service';
declare var HelperFunctions: any;

@Component({
    selector: 'ngbd-disclaimer-modal',
    styleUrls: ['./css/dialog_disclaimer_modal.scss'],
    templateUrl: './templates/dialog_disclaimer_modal.html'
    
  })
  
  // interessant
  // https://medium.com/@izzatnadiri/how-to-pass-data-to-and-receive-from-ng-bootstrap-modals-916f2ad5d66e
  export class DisclaimerModal implements  OnInit, AfterContentInit , AfterViewInit {
    // @Input() header;
    @Input() text;
    @Output() passEntry: EventEmitter<any> = new EventEmitter();
    @Output() onInit: EventEmitter<any> = new EventEmitter();
    @Output() afterViewinit: EventEmitter<any> = new EventEmitter();
    @Output() afterContentInit: EventEmitter<any> = new EventEmitter();
    @Output() afterOkpressed: EventEmitter<any> = new EventEmitter();
    @Output() afterHandlerFunctionOneClick: EventEmitter<any> = new EventEmitter();
    @ViewChild('userInput',{static: false}) userInput: CdkTextareaAutosize;
    @ViewChild('cancelBtn', {static: false}) cancelBtn: ElementRef;
    @ViewChild('okBtn', {static: false}) okBtn: ElementRef;
    @ViewChild('content', {static: false}) content: ElementRef;
    // public ok_function : any;
    public cancel_btn : any;
    public ok_btn : any;
    public ok_btn2 : any;
    // public data: any = {cmd:""};
    public header = ""
    public data : any = {
      selectedVersammlung: {},
      checkboxes: {
        check1: false,
        check2: false,
        check3: false
      }
    }
    constructor(
      // @Inject(MAT_DIALOG_DATA) public data_: any,
      // public dialogRef: MatDialogRef<StandardBestaetigenModal>,
      public activeModal: NgbActiveModal, 
      public router : Router,
      private route: ActivatedRoute,
      private fb: FormBuilder,
      private authenticationService: AuthenticationService,
      private alertService: AlertService,
      private _modalService: NgbModal,
      private _socketService: SocketService,
      private dex: DataExchangeService,
      private logger: LoggerService,
      public dialogRef: MatDialogRef<DisclaimerModal>
    ) 
    {
        this.cancel_btn = function() {}  
    }
    
    ngOnInit(): void 
    {
      this.onInit.emit(this);
    }

    ngAfterContentInit(): void 
    {
      this.afterContentInit.emit(this);
    }
  
    ngAfterViewInit(): void 
    {
      this.afterViewinit.emit(this);
    }
  
    public cancel_function()
    {
        this.activeModal.close(name);
        this.dialogRef.close(name);
        if(typeof(this.cancel_btn) == 'function')
        {
            this.cancel_btn();
        }
    }
    
    handlerFunctionOne(elem)
    {
      this.afterHandlerFunctionOneClick.emit(elem);
    }

    passBack() 
    {
      this.passEntry.emit(this.data);
    }

    // private open(){
    //   this._modalService.open(StandardBestaetigenModal);
    // }
    
    public ok_function()
    {
        this.afterOkpressed.emit(this.ok_btn);
        if(typeof(this.ok_btn) == 'function')
        {
            this.ok_btn();
        }
    }

    private navigateTo(path)
    {
      this.router.navigate([`${path}`]);
    }

    private navigateRelativeTo(path, argument)
    {
      this.router.navigate([`${path}`], argument);
    }
  }

const MODALS = {
  disclaimerModal: DisclaimerModal
};

