import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
// import { MatIconModule, MatNativeDateModule, MatDatepickerModule, MatCardModule, MatAutocompleteModule, MatCommonModule, MatDividerModule, MatSliderModule, MatProgressBarModule, MatRippleModule, MatButtonModule, MatFormFieldModule, MatInputModule, MatSidenavModule, MatToolbarModule, MatListModule, MatSelectModule, MatRadioModule, MatGridListModule, MatStepperModule, MatTableModule, MatTabsModule, MatTooltipModule, MatTreeModule, MatSlideToggleModule, MatSortModule, MatSnackBarModule, MatProgressSpinnerModule, MatDialogModule, MatCheckboxModule, MatButtonToggleModule, MatBottomSheetModule, MatBadgeModule, MatPaginatorModule} from '@angu';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {MatPaginatorModule} from '@angular/material/paginator';
import { MatIconModule} from '@angular/material/icon';
// import { MatNativeDateModule} from '@angular/material/datepicker';
import { MatCardModule} from '@angular/material/card';
import { MatAutocompleteModule} from '@angular/material/autocomplete';
import {  MatCommonModule} from '@angular/material/core';
import {  MatDividerModule} from '@angular/material/divider';
import {  MatSliderModule} from '@angular/material/slider';
import {  MatProgressBarModule} from '@angular/material/progress-bar';
import {   MatRippleModule} from '@angular/material/core';
import {MatButtonModule} from '@angular/material/button';
import { MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {    MatSidenavModule} from '@angular/material/sidenav';
import {    MatToolbarModule} from '@angular/material/toolbar';
import {    MatListModule} from '@angular/material/list';
import {    MatSelectModule} from '@angular/material/select';
import {    MatRadioModule} from '@angular/material/radio';
import {    MatGridListModule} from '@angular/material/grid-list';
import {    MatStepperModule} from '@angular/material/stepper';
import {    MatTableModule} from '@angular/material/table';
import {    MatTabsModule} from '@angular/material/tabs';
import {    MatTooltipModule} from '@angular/material/tooltip';
import {    MatTreeModule} from '@angular/material/tree';
import {    MatSlideToggleModule} from '@angular/material/slide-toggle';
import {    MatSortModule} from '@angular/material/sort';
import {    MatSnackBarModule} from '@angular/material/snack-bar';
import {    MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {    MatDialogModule} from '@angular/material/dialog';
import {    MatCheckboxModule} from '@angular/material/checkbox';
import {    MatButtonToggleModule} from '@angular/material/button-toggle';
import {    MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {    MatBadgeModule} from '@angular/material/badge';
import {    MatNativeDateModule} from '@angular/material/core';
import {    MatDatepickerModule} from '@angular/material/datepicker';

import { CdkTableModule } from '@angular/cdk/table';
// import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AppRoutingModule } from '../../app-routing.module';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
// import { SharedModule } from '../../shared/shared.module';


import {
  StandardModalComponent
} from './modals.component';
import { StandardBestaetigenModal } from './modal-standard-bestaetigung';
import { DisclaimerModal } from './modal-disclaimer-modal';

const customModals = [
    StandardModalComponent,
    StandardBestaetigenModal,
    DisclaimerModal
];

const networkModules = [
  HttpClientModule, 
];
const genericModules = [
  CommonModule,
  BrowserAnimationsModule,
  BrowserModule,
  // NgModule,
  FormsModule,
//   CoreModule,
//   SharedModule,
  
];
const routingModules = [
  AppRoutingModule, 
];
const bootstrapModules = [
  NgbModule, 
  // FontAwesomeModule,
  ReactiveFormsModule,
];
const ngMaterialModules = [
  MatIconModule,
  MatButtonModule,
  MatFormFieldModule,
  MatInputModule,
  MatRippleModule,
  MatProgressBarModule,
  MatSliderModule,
  MatCommonModule,
  MatDividerModule,
  MatAutocompleteModule,
  MatCardModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatIconModule,
  MatSidenavModule,
  MatToolbarModule,
  MatListModule,
  MatSelectModule,
  MatRadioModule,
  MatGridListModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatTooltipModule,
  MatTreeModule,
  MatSlideToggleModule,
  MatSortModule,
  MatSnackBarModule,
  MatProgressSpinnerModule,
  MatDialogModule,
  MatCheckboxModule,
  MatButtonToggleModule,
  MatBottomSheetModule,
  MatBadgeModule,
  MatPaginatorModule,
  CdkTableModule
];

@NgModule({
  imports: [
    BrowserModule, 
    NgbModule,
    ngMaterialModules,
    genericModules,
    routingModules,
    networkModules,
    bootstrapModules
  ],
  declarations: [
    customModals
    ],
  exports: [
    customModals
  ],
  bootstrap: [customModals],
  entryComponents: [
    customModals
  ],
  providers: [
    NgbActiveModal

  ]
})
export class NgbdModalFocusModule {}
