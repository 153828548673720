/* eslint-disable no-unsafe-finally */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-useless-escape */
import { Injectable } from '@angular/core';
import { LoggerService } from '../services/logger.service';
import {  e_command_enum } from '../_models/enum';
import { Einheit, Abstimmung, Qualifizierungskonfiguration } from '../_models/interfaces';
import { abstimmungsTexteEnum } from './data-exchange.service';
import {DecimalPipe} from '@angular/common';
declare let HelperFunctions: any;

@Injectable({
  providedIn: 'root'
})
export class GlobalsService{
  // public dex: DataExchangeService;
  constructor(
    // private logger: LoggerService
      private decimalPipe: DecimalPipe
  ) 
  { 

  }

  public compareEmail(a: boolean | number | string , b: boolean | number | string, isAsc : boolean)
  {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  
  public compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  public emailValidator(email:string): boolean {
    const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!EMAIL_REGEXP.test(email)) {
        return false;
    }
    return true; 
  }


  public qualifzierungstypObjectIsCorrect(qt:any = {})
  {
    let is_correct  = false;
   
    try 
    {
      if(qt.hasOwnProperty("id") && 
         qt.hasOwnProperty("name") &&
         qt.hasOwnProperty("stimmgeber") &&
         qt.stimmgeber.hasOwnProperty("s1") &&
         qt.stimmgeber.s1.hasOwnProperty("key") &&
         qt.stimmgeber.s1.hasOwnProperty("schwellwert") &&
         qt.stimmgeber.hasOwnProperty("s2") &&
         qt.stimmgeber.s2.hasOwnProperty("key") &&
         qt.stimmgeber.s2.hasOwnProperty("schwellwert") 
      )
      {
        is_correct = true;
      }
    } 
    catch (error) 
    {
      console.log(error)
    } 
    finally
    {
      return is_correct
    }
  }

  public getEinheitenByTeilnehmer(teilnehmer_id: number = null, einheiten: Array<Einheit> = null , gemeinschaftstypen: Array<number> = null ): Array<Einheit>
  {
    try {
      if(teilnehmer_id != null && einheiten != null && gemeinschaftstypen != null)
      {
        return einheiten.filter(eh => (eh.teilnehmer_id === teilnehmer_id && gemeinschaftstypen.includes(eh.gemeinschaftstyp_id) ))
      }else {return [];}
    } catch (error) {
      console.log(error);
      return [];
    }

  }

  public getAbstimmungenByTeilnehmer(teilnehmer_id:number = null, tagesordnungspunkt_id: number = null,  abstimmungen: Array<Abstimmung>, einheiten: Array<Einheit> = null, gemeinschaftstypen: Array<number> = null , lower_abstimmung = -2): Array<Abstimmung>
  {
    try {
      if(teilnehmer_id != null && tagesordnungspunkt_id != null && einheiten != null && abstimmungen != null && gemeinschaftstypen != null)
      {
        const einheit_ids_des_tn = einheiten.filter(eh => eh.teilnehmer_id === teilnehmer_id && gemeinschaftstypen.includes(eh.gemeinschaftstyp_id) ).map(eh=>eh.einheit_id);
        let abstimmungen_: Array<Abstimmung> = [] ;
        einheit_ids_des_tn
        // .filter((v,i,a)=>a.findIndex(v2=>(v2===v))===i)
        .map(einheit_id => { 
          abstimmungen_ = abstimmungen_.concat(abstimmungen.filter(abs=> abs.einheit_id === einheit_id && abs.abstimmung > lower_abstimmung && abs.tagesordnungspunkt_id === tagesordnungspunkt_id) );
        });
        return abstimmungen_;
      }else {return [];}
    } catch (error) {
      console.log(error);
      return [];
    }

  }

  public hatTeilnehmerAbgestimmt(teilnehmer_id:number = null, tagesordnungspunkt_id: number = null,  abstimmungen: Array<Abstimmung> = null, einheiten: Array<Einheit> = null , gemeinschaftstypen: Array<number> = null , lower_abstimmung = -2):boolean
  {
    try {
      if(teilnehmer_id != null && abstimmungen != null && einheiten != null && gemeinschaftstypen != null)
      {
        const tn_eh = this.getEinheitenByTeilnehmer(teilnehmer_id, einheiten, gemeinschaftstypen); 
        const tn_abs = this.getAbstimmungenByTeilnehmer(teilnehmer_id, tagesordnungspunkt_id , abstimmungen, einheiten, gemeinschaftstypen, lower_abstimmung);
        // console.log("hatTeilnehmerAbgestimmt eh", tn_eh, " abs ",tn_abs);
        const abgestimmt = ((tn_abs.length >= tn_eh.length) && tn_eh.length > 0) || (tn_eh.length == 0);
        return abgestimmt;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  }
    
  public needReload(new_version: string = null): boolean
  {
    let needReload = false;
    if(localStorage.getItem("version_mobile_client") && new_version != null)
    {
      const old_version_storage = localStorage.getItem("version_mobile_client");
      // console.log("[needReload] old_version_storage ",old_version_storage, " new_version: ",new_version);
      if(old_version_storage != new_version)
      {
        needReload = true;
      }
    }
    return needReload;
  }
  public germanDecimal2(_number = 0): string{
    if(typeof  _number === "string")
    {
      _number = Number(_number);
    }
    return this.decimalPipe.transform(_number, "1.0-2", 'de');
  }
  public germanDecimal(_number: string)
  {
    try {
      _number+="";
      if(_number.includes("."))
      {
        _number = _number.replace(".",",");
      }
    } catch (error) {
      console.log(error)
    }
    return _number;
  }

 public stimmgeberIsPresent(qualifizierungskonfiguration: Qualifizierungskonfiguration = null, stimmgeber = "")
 {
  return qualifizierungskonfiguration.stimmgeber.s1.key === stimmgeber || qualifizierungskonfiguration.stimmgeber.s2.key === stimmgeber;
 }
    
}

export enum stimmgeber_key {
  stimmgeber_tn = "stimmgeber_tn",
  stimmgeber_mea = "stimmgeber_mea",
  stimmgeber_eh = "stimmgeber_eh"
}

export interface Stimmmgeber_row_for_modal
{
  key: string, value: string, checked: boolean, tooltip: string
}
export const stimmgeber_ : Stimmmgeber_row_for_modal[] = [
  {key : stimmgeber_key.stimmgeber_mea , value : "MEA", checked: false , tooltip : "Abstimmung nach MEA"},
  {key : stimmgeber_key.stimmgeber_tn , value : "Köpfe", checked: false , tooltip : "Abstimmung nach Köpfen"},
  {key : stimmgeber_key.stimmgeber_eh , value : "Einheiten", checked: false , tooltip : "Abstimmung nach Einheiten"},
]
export interface relation_model{  id: number, text: string , text2: string}
export const relations: relation_model[] = [
  {id:0, text: "Abgegebene Stimmen", text2: "abgegebene Stimmen"},
  {id:1, text: "Alle Stimmen", text2: "alle Stimmen"}
]

export enum qualifizierungsTypen_namen {
  einfacheMehrheit = "Einfache Mehrheit",
  qualifizierteMehrheit = "Qualifizierte Mehrheit",
  doppelteQualifizierung = "Doppelte Qualifizierung",
  einfacheMehrheitAT = "Einfache Mehrheit (AT)",
}

export interface qualifizierungs_typen_row {
  id: number,
  name: string,
  active: number
}
export const qualifizierungsTypen_: qualifizierungs_typen_row[] = [
  {id: 0, name: qualifizierungsTypen_namen.einfacheMehrheit, active: 1},
  {id: 1, name: qualifizierungsTypen_namen.qualifizierteMehrheit, active: 1},
  {id: 2, name: qualifizierungsTypen_namen.doppelteQualifizierung, active: 1},
  {id: 3, name: qualifizierungsTypen_namen.einfacheMehrheitAT, active: 1},
];

export const abstimmErgebnisse_vorlage : any = {
  abgestimmt_einheiten: 0,
  abgestimmt_einheiten2: 0,
  // abstimmungen : [],
  top: {versammlung_id: 0, tagesordnungspunkt_id: 0, abstimmungen: 0},
  anwesende_einheiten: 0,
  einheitenGesamt: 0,
  berechtigte_teilnehmer: 0,
  anwesende_teilnehmer: 0 ,
  meaAbgestimmt: 0,
  meaAbgestimmt2: 0,
  meaAbgestimmtUndAnwesend: 0,
  meaStimmenGesamt: 0,
  teilnehmer_haben_abgestimmt: 0,
  teilnehmer_haben_abgestimmt2:0,
  verkuendungsErgebnis: "kein Ergebnis abrufbar",
  ja: {
    mea: 0,
    einheiten: 0,
    kopf_stimmen: 0
  },
  nein: {
    mea: 0,
    einheiten: 0,
    kopf_stimmen: 0
  },
  enthaltung: {
    mea: 0,
    einheiten: 0,
    kopf_stimmen: 0
  },
  anwesend: {
    mea: 0,
    einheiten: 0,
    kopf_stimmen: 0
  },
  abwesend: {
    mea: 0,
    einheiten: 0,
    kopf_stimmen: 0
  }
}

export function convertAbstimmung(abs, cmd)
{
   let erg: any = '';
   if(cmd === e_command_enum.toIndex)
   {
    erg = -2;
    switch(abs)
    {
       case abstimmungsTexteEnum.ja || abstimmungsTexteEnum.Ja:
         erg = 1;
       break;
      
       case abstimmungsTexteEnum.nein || abstimmungsTexteEnum.Nein:
         erg = -1;
       break;

       case abstimmungsTexteEnum.enthaltung || abstimmungsTexteEnum.Enthaltung:
         erg	= 0;
       break;

       default:
         erg = -2;
       break;
    }
   }
   if(cmd === e_command_enum.toText)
   {
     erg = abstimmungsTexteEnum.keineAbstimmung;
     switch(abs)
     {
        case -1:
         erg = abstimmungsTexteEnum.Nein;
        break;
        case 0:
          erg = abstimmungsTexteEnum.Enthaltung;
        break;
        case 1:
          erg = abstimmungsTexteEnum.Ja;
        break;
        
        default:         
        break;

     }
   }

   return erg;
}



export const use_abstimmung_greater = -3;